<template>
      <v-row justify="center" no-gutters>
        <v-col lg=6>
          <v-container id="client_change_password">
    <v-card>
      <v-card-title class="headline" title--text align="center">
        Change Password
      </v-card-title>
      <v-card-text>
        <v-flex>
          <div>
            Please use a secure password with <br />
            <li>Minimum 8 characters</li>
            <li>Upper and lower case letters</li>
            <li>At least one number</li>
          </div>
          <v-layout raw wrap align-center justify-center>
            <v-flex xs12 sm12 md12>
              <v-text-field
                v-model="currentPassword"
                label="Current Password"
                persistent-hint
                type="password"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                v-model="newPassword"
                label="New Password"
                persistent-hint
                type="password"
                :rules="[rules.required, rules.passwordCheck]"
              ></v-text-field>
              <v-text-field
                v-model="confirmPassword"
                label="Confirm Password"
                persistent-hint
                type="password"
                :rules="[rules.required, rules.confirmPasswordCheck]"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3>
              <v-btn @click="submitPassword" color="success">Submit</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
      </v-col>
      </v-row>

</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      contractor: {},
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      rules: {
        required: (value) => !!value || "Required",
        checkDepotName: (id) => {
          return this.uniqId || "ID already in use";
        },
        passwordCheck: (value) => {
          if (
            this.confirmPassword != "" &&
            this.confirmPassword != this.newPassword
          )
            return "new password is not matching with confirm password";
          return true;
        },
        confirmPasswordCheck: (value) => {
          if (value != this.newPassword)
            return "confirm password is not matched.";
          return true;
        },
      },
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
    };
  },

  methods: {

    //submit password
    submitPassword() {
      var data = { cp: this.currentPassword, np: this.newPassword };
      var payload = { data: data };
      axios
        .post("/client_routes/users/password_set", payload)
        .then((response) => {
          if (response.status === 200) {
            this.snackbar.color = "success";
            this.snackbar.text = "Password Updated";
            this.snackbar.visible = true;
          }
        })
        .catch((e) => {
          console.log(e);
          this.snackbar.color = "error";
          this.snackbar.text = "An error has occured";
          this.snackbar.visible = true;
        });
    },
  },
};
</script>

<style></style>
