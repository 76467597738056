<template>
  <nav>
    <v-toolbar color="light-blue darken-4">
      <v-app-bar-nav-icon
        v-if="auth"
        color="white darken-4"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-img to="/" src="/vht.png" max-height="33" max-width="100"></v-img>
      <v-toolbar-title>
        <span class="white--text font-weight-thin headline">|</span>
        <span class="white--text">Courier</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        to="/contractor_portal/home"
        class="ma-2"
        label
        outlined
        color="white"
      >
        <v-icon left>account_circle</v-icon>
        {{ user }}
      </v-btn>
      <v-btn v-if="!auth" to="/login">
        <span class="black--text">Login</span>
        <v-icon class="black--text" right>exit_to_app</v-icon>
      </v-btn>
      <v-btn v-if="auth" @click="onLogout">
        <v-icon left>logout</v-icon>
        <span class="black--text">Logout</span>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer v-if="auth" temporary v-model="drawer" width="300" app>
      <v-list dense>
        <v-list-item to="/contractor_portal/dashboard">
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Home</v-list-item-content>
        </v-list-item>
        <v-list-item to="/contractor_portal/home">
          <v-list-item-icon>
            <v-icon>person</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Profile</v-list-item-content>
        </v-list-item>
        <v-list-item to="/contractor_portal/leave">
          <v-list-item-icon>
            <v-icon>beach_access</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Leave</v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item to="/contractor_portal/change_password">
          <v-list-item-icon>
            <v-icon>key</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Change Password</v-list-item-content>
        </v-list-item>
        <v-list-item @click="onLogout">
          <v-list-item-icon>
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Logout</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      darkMode: false,
    };
  },

  computed: {
    auth() {
      const now = Date.now() / 1000;
      return this.$store.getters.isAuthenticated(now);
    },
    user() {
      return !this.$store.getters.user
        ? false
        : this.$store.getters.user.username;
    },
    isAdmin() {
      return !this.$store.getters.user
        ? false
        : this.$store.getters.user.role == "admin";
    },
  },
  methods: {
    onLogout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
