<template>
  <div>
    <stat-card
      title="Total Expense"
      color="pink accent-1"
      :amount="total"
      icon="mdi-cart-outline"
      sub_title=""
    ></stat-card>

    <stat-card
      title="GST on Expense"
      color="pink accent-2"
      :amount="gst"
      icon="mdi-cart-outline"
      sub_title=""
    ></stat-card>
  </div>
</template>

<script>
import axios from "axios";
import StatCard from "./StatCard.vue";

export default {
  components: {
    StatCard,
  },
  props: ["startDate", "endDate"],
  watch: {
    startDate() {
      this.loadRates();
    },
    endDate() {
      this.loadRates();
    },
  },
  created() {
    this.loadRates();
  },

  computed: {
    numberOfDays: function () {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      return (
        Math.round(
          Math.abs((new Date(this.endDate) - new Date(this.startDate)) / oneDay)
        ) + 1
      );
    },
  },

  data: () => ({
    total: 0,
    gst: 0,
    errors: [],
  }),

  methods: {
    loadRates() {
      axios
        .get(
          `/summary/totalexpense?startDate=${this.startDate}&endDate=${this.endDate}`
        )
        .then((response) => {
          this.total = this.formatSum(response.data[0].Total, "$");
          this.gst = this.formatSum(response.data[0].GST, "$");
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    formatSum(sum, prefix = "", decimal = 2) {
      sum = sum.toFixed(decimal);
      return (
        prefix + " " + sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
  },
};
</script>

<style></style>
