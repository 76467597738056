import { render, staticRenderFns } from "./Job.vue?vue&type=template&id=af6af660&scoped=true"
import script from "./Job.vue?vue&type=script&lang=js"
export * from "./Job.vue?vue&type=script&lang=js"
import style0 from "./Job.vue?vue&type=style&index=0&id=af6af660&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af6af660",
  null
  
)

export default component.exports