<template>
  <nav>
    <v-toolbar color="light-blue darken-4">
      <v-app-bar-nav-icon
        v-if="auth"
        color="white darken-4"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-img to="/" src="/vht.png" max-height="33" max-width="100"></v-img>
      <v-toolbar-title>
        <span class="white--text font-weight-thin headline">|</span>
        <span class="white--text">Courier</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-chip v-if="user" class="ma-2" label outlined color="white">
        <v-icon left>account_circle</v-icon>
        {{ user }}
      </v-chip>
      <v-btn v-if="!auth" to="/login">
        <span class="black--text">Login</span>
        <v-icon class="black--text" right>exit_to_app</v-icon>
      </v-btn>
      <v-btn v-if="auth" @click="onLogout">
        <v-icon left>logout</v-icon>
        <span class="black--text">Logout</span>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer v-if="auth" temporary v-model="drawer" width="300" app>
      <v-list dense>
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Home</v-list-item-content>
        </v-list-item>

        <v-list-item v-if="auth && role === 'admin'" to="/admindashboard">
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Dashboard</v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="auth && role === 'user'"
          :disabled="!auth"
          to="/userdashboard"
        >
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Dashboard</v-list-item-content>
        </v-list-item>

        <v-list-group no-action prepend-icon="manage_accounts" value="true">
          <template v-slot:activator>
            <v-list-item-content :v-if="!auth">
              <v-list-item-content>User Management</v-list-item-content>
            </v-list-item-content>
          </template>
          <v-list-item
            v-if="
              auth && (role === 'admin' || role === 'user' || role === 'ruser')
            "
            to="/contractors"
          >
            <v-list-item-icon>
              <v-icon>directions_run</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Contractors</v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="role === 'user' || role === 'admin' || role === 'ruser'"
            :v-if="!auth"
            to="/contractorsLeave"
          >
            <v-list-item-icon>
              <v-icon>hiking</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Contractors Leave</v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="auth && (role === 'admin' || role === 'user')"
            to="/clients"
          >
            <v-list-item-icon>
              <v-icon>badge</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Clients</v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="auth && (role === 'admin' || role === 'user')"
          no-action
          prepend-icon="local_shipping"
          value="true"
        >
          <template v-slot:activator>
            <v-list-item-content :disabled="!auth">
              <v-list-item-content>Jobs</v-list-item-content>
            </v-list-item-content>
          </template>

          <v-list-item
            v-if="auth && (role === 'admin' || role === 'user')"
            to="/job"
            exact
          >
            <v-list-item-content>Add Job</v-list-item-content>
            <v-list-item-icon>
              <v-icon>add</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            v-if="auth && (role === 'admin' || role === 'user')"
            to="/planner"
          >
            <v-list-item-content>Planner</v-list-item-content>
            <v-list-item-icon>
              <v-icon>event_note</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="auth && (role === 'admin' || role === 'user')"
          no-action
          prepend-icon="attach_money"
          value="true"
        >
          <template v-slot:activator>
            <v-list-item-content
              v-if="auth && (role === 'admin' || role === 'user')"
            >
              <v-list-item-content>Payroll</v-list-item-content>
            </v-list-item-content>
          </template>
          <v-list-item v-if="auth && role === 'admin'" to="/aba">
            <v-list-item-icon>
              <v-icon>assignment</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Generate ABA</v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="auth && (role === 'admin' || role === 'user')"
            to="/payslip"
          >
            <v-list-item-icon>
              <v-icon>payment</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Generate Payslip</v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="auth && (role === 'admin' || role === 'user')"
            to="/payslipRetrieval"
          >
            <v-list-item-icon>
              <v-icon>search</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Retrieve Payslip</v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="auth && (role === 'admin' || role === 'user')"
            to="/invoice"
          >
            <v-list-item-icon>
              <v-icon>class</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Generate Invoice</v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="auth && (role === 'admin' || role === 'user')"
            to="/invoiceRetrieval"
          >
            <v-list-item-icon>
              <v-icon>search</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Retrieve Invoice</v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="auth && (role === 'admin' || role === 'user')"
            to="/misc"
          >
            <v-list-item-icon>
              <v-icon>edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Miscellaneous</v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="role === 'admin'"
          no-action
          prepend-icon="settings"
          value="true"
        >
          <template v-slot:activator>
            <v-list-item-content v-if="auth && role === 'admin'">
              <v-list-item-content>Settings</v-list-item-content>
            </v-list-item-content>
          </template>

          <v-list-item v-if="auth && role === 'admin'" to="/compDetail">
            <v-list-item-icon>
              <v-icon>business</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Company Details</v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- <v-list-group no-action prepend-icon="help_outline" value="true">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-content>Contact Us</v-list-item-content>
            </v-list-item-content>
          </template>

          <v-list-item to="/about">
            <v-list-item-content>About</v-list-item-content>
            <v-list-item-icon>
              <v-icon>info</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item to="/support">
            <v-list-item-content>Support</v-list-item-content>
            <v-list-item-icon>
              <v-icon>headset_mic</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group> -->
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      darkMode: false,
    };
  },

  computed: {
    auth() {
      const now = Date.now() / 1000;
      return this.$store.getters.isAuthenticated(now);
    },
    user() {
      return !this.$store.getters.user
        ? false
        : this.$store.getters.user.username;
    },
    role() {
      return !this.$store.getters.user
        ? "NOROLE"
        : this.$store.getters.user.role;
    },
  },
  methods: {
    onLogout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
