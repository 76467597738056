<template>
  <v-container id="client" grid-list-md fluid>
    <v-layout row wrap>
      <v-flex d-flex align-start sm12 md6>
        <!-- Contractor Form -->
        <client-form :depotName="depotName"></client-form>
      </v-flex>
      <v-flex d-flex align-start sm12 md6>
        <!-- vehicles table also dynamically loads rates table -->
        <rates :depotName="depotName"></rates>
        <!-- </v-layout> -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import cForm from "./ClientForm.vue";
import Rates from "./Rates.vue";

export default {
  components: {
    "client-form": cForm,
    rates: Rates,
  },

  //props: ["DepotName"],

  data() {
    return {
      depotName: "",
      errors: [],
    };
  },

  created() {
    this.depotName = this.$route.params.id;
  },
};
</script>
