<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Today
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>arrow_back_ios </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>arrow_forward_ios</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar ref="calendar" v-model="focus" color="primary" :events="events" :event-color="getEventColor"
          :type="type" :event-height="40" @click:event="showEvent" @change="loadEvents" :event-more="false">
          <template v-slot:event="{ event }">
            <v-container @click="eventClicked(event)" class="text-body-1 pa-2 " align-center>
              <v-layout row>
                <v-flex>
                  <v-icon v-if="event.type === 'job'" color="white" small>local_shipping</v-icon>
                  <v-icon v-if="event.type === 'leave'" color="white" small>beach_access</v-icon>
                  {{ event.name }}
                </v-flex>
                <v-spacer />
                <v-flex>
                  <v-icon dark v-if="event.status % 10 === 1" color="amber">check</v-icon>
                  <v-icon dark v-if="event.status != 0 && event.status % 10 === 0" color="amber">schedule</v-icon>
                  <v-icon dark v-if="event.status === 0" color="green lighten-1">done_all</v-icon>
                </v-flex>
              </v-layout>

          </v-container>
          </template>
        </v-calendar>
        <v-menu ref="popup" v-model="selectedOpen" :close-on-content-click="false" :open-on-click="false" :activator="selectedElement" offset-x
          max-width="500px">
          <v-card color="grey lighten-4" class="pa-2">
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title class="pr-3">
                {{ selectedEvent.name }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text outlined fab small @click="selectedOpen = false">
                <v-icon small>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="eventDescription(selectedEvent)"></span>
            </v-card-text>
            <v-card-actions v-show="selectedEvent.type == 'job'">
              <v-chip color="orange darken-4" outlined class="subheading" v-if="selectedEvent.status % 10 === 1">
                <v-icon left>check</v-icon>
                Job Request
              </v-chip>
              <v-chip color="orange darken-4" outlined class="subheading"
                v-if="selectedEvent.status != 0 && selectedEvent.status % 10 === 0">
                <v-icon left>schedule</v-icon>
                Approval Pending
              </v-chip>
              <v-chip color="green darken-4" outlined class="subheading" v-if="selectedEvent.status === 0">
                <v-icon left>done_all</v-icon>
                Approved
              </v-chip>
              <v-spacer />
              <div class="pl-5">
                <v-btn small color="green" @click="openTimeUpdateDialog(selectedEvent)"
                  v-show="selectedEvent.rate_type === 'hourly' && selectedEvent.status % 10 == 0 && selectedEvent.status !== 0">
                  <v-icon left>schedule</v-icon>
                  Update Time
                </v-btn>
                <v-btn small color="green" v-show="selectedEvent.status % 10 === 1" @click="acceptJob(selectedEvent)">
                  <v-icon left>check</v-icon>
                  Accept
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-dialog v-model="timeUpdateDialog" persistent max-width="30%">
          <v-form ref="timeUpdate">
          <v-card>
            <v-card-title class="headline">Update Times
              <v-spacer></v-spacer>
              <v-btn fab color="error" small @click="timeUpdateDialog = false">
                <v-icon small>close</v-icon>
              </v-btn>

            </v-card-title>
            <v-card-text>
              <v-layout class="pt-5" raw wrap align-center justify-center>
                <v-flex xs6 sm3>
                  <v-autocomplete dense class="text-xs-center" :items="hours" label="Start HH"
                    v-model="startHour"></v-autocomplete>
                </v-flex>
                <h1>:</h1>
                <v-flex xs6 sm3>
                  <v-autocomplete dense :items="mins" label="Start MM" v-model="startMin" ></v-autocomplete>
                </v-flex>
                <h1>-</h1>
                <v-flex xs6 sm3>
                  <v-autocomplete dense :items="hours" label="Finish HH" v-model="finishHour"></v-autocomplete>
                </v-flex>
                <h1>:</h1>
                <v-flex xs6 sm2>
                  <v-autocomplete dense :items="mins" label="Finish MM" v-model="finishMin"></v-autocomplete>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <span :style="{color: duration < 0 ? 'red' : 'inherit'}">
              Duration: {{  duration }} Hrs
            </span>
              <v-spacer />
              <v-btn :disabled="duration < 0" class="primary" text @click="updateJobTimes()">
                <v-icon left dark>save</v-icon>
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        </v-dialog>
      </v-sheet>
    </v-col>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false">Close</v-btn>
      </template>
    </v-snackbar>

  </v-row>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    timeUpdateDialog: false,
    editedJob: {},
    hours: [],
    mins: [],
    start_date: {},
    end_date: {},
    startHour: "00",
    startMin: "00",
    finishHour: "00",
    finishMin: "00",
    duration: 0,
    events: [],
    interval: 0,
    jobColors: [],
    colors: [
      "cyan darken-4",
      "blue darken-4",
      "purple darken-4",
      "cyan",
      "indigo",
      "orange darken-4",
      "grey darken-1",
      "green darken-4",
    ],

    rules: {
      duration: () =>
        (parseFloat(this.duration) >= 0) ||
        "Start can't be after finish"
      },

    snackbar: {
      visible: false,
      color: "success",
      timeout: 3000,
      text: "",
    },
    errors: []
  }),
  mounted() {
    this.$refs.calendar.checkChange();
    this.fillHours()
    this.fillMinutes()
    this.interval = setInterval(function () {
      this.loadEvents({ "start": this.start_date, "end": this.end_date });
    }.bind(this), 30000);
  },
  destroyed() {
    clearInterval(this.interval)
  },

  watch: {
    startHour() {
      this.calculateDuration();
    },
    startMin() {
      this.calculateDuration();
    },
    finishHour() {
      this.calculateDuration();
    },
    finishMin() {
      this.calculateDuration();
    }
  },

  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    eventClicked(event) {
      this.selectedEvent = event;
    },
    loadEvents({ start, end }) {
      this.$refs.calendar.$forceUpdate();
      this.start_date = start;
      this.end_date = end;
      // Load contractor jobs
      axios
        .get(
          "contractor_routes/job?startDate=" +
          start.date +
          "&endDate=" +
          end.date
        )
        .then((response) => {
          let jobEvents = [];
          response.data.forEach((job) => {
            const jobEvent = {
              type: "job",
              JobID: job.JobID,
              Start_Time: job.Start_Time,
              finish_time: job.finish_time,
              name: `${job.ClientName}`,
              start: new Date(`${job.Start_Date}T${job.Start_Time}`),
              end: new Date(`${job.finish_date}T${job.finish_time}`),
              color: this.getColor(job.DepotName),
              rate_type: job.rate_type,
              vehicle_type: job.contractor_vehicle_type,
              status: job.status,
              timed: true,
            }
            jobEvents.push(jobEvent);
            this.events.forEach((event, index) => {
              if (event.JobID && event.JobID == jobEvent.JobID) {
                this.events.splice(index, 1);
              }
            });
            this.events.push(jobEvent);
            this.events.forEach((event, ei) => {
              if (event.JobID && event.type === 'job') {
                let jobEventsIndex = jobEvents.findIndex((job => event.JobID === job.JobID));
                if (jobEventsIndex < 0) {
                  this.events.splice(ei, 1);
                }
              }
            });
          });
        })
        .catch((e) => {
          this.errors.push(e);
        });
      // Load contractor leave
      axios
        .get(
          "contractor_routes/leave?startDate=" +
          start.date +
          "&endDate=" +
          end.date +
          "&calendar=true"
        )
        .then((response) => {
          let leaveEvents = [];
          response.data.forEach((leave) => {
            const leaveEvent = {
              type: "leave",
              leaveID: leave.leaveID,
              name: leave.approved
                ? "Leave (approved)"
                : "Leave (pending approval)",
              start: new Date(`${leave.StartDate}T00:00:00`),
              end: new Date(`${leave.EndDate}T00:00:00`),
              color: leave.approved ? "green" : "yellow darken-4",
              timed: false,
            }
            leaveEvents.push(leaveEvent);
            this.events.forEach((event, index) => {
              if (event.leaveID && event.leaveID == leaveEvent.leaveID) {
                this.events.splice(index, 1);
              }
            });
            this.events.push(leaveEvent);
            this.events.forEach((event, ei) => {
              if (event.leaveID && event.type === 'leave') {
                let leaveEventsIndex = leaveEvents.findIndex((leave => event.leaveID === leave.leaveID));
                if (leaveEventsIndex < 0) {
                  this.events.splice(ei, 1);
                }
              }
            });
          });
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getColor(DepotName) {
      let jobColor = "";
      this.jobColors.forEach((color) => {
        if (color.DepotName === DepotName) {
          jobColor = color.color;
        }
      });
      if (jobColor === "") {
        if (this.jobColors.length < this.colors.length) {
          jobColor = this.colors[this.jobColors.length];
        } else {
          jobColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        }
        this.jobColors.push({ DepotName: DepotName, color: jobColor });
      }
      return jobColor;
    },
    eventDescription(event) {
      if (event.type === 'job') {
        let start_time = this.chopSeconds(event.Start_Time);
        let finish_time = this.chopSeconds(event.finish_time);
        if (event.rate_type === "flat") {
          let percentage_day =
            Math.round(((event.contractor_subtotal / event.contractor_rate) * 100) / 25) * 25;
          return "Flat: " + percentage_day + "%" + `<br>Vehicle: ${event.vehicle_type}T`;
        } else if (event.rate_type === "piece") {
          let pieces = Math.round(event.contractor_subtotal / event.contractor_rate);
          return "Pieces: " + pieces + `<br>Vehicle: ${event.vehicle_type}T`;
        } else if (event.rate_type === "stop") {
          let stops = Math.round(event.contractor_subtotal / event.contractor_rate);
          return "Stops: " + stops + `<br>Vehicle: ${event.vehicle_type}T`;
        } else {
          return "Hourly: " + start_time + " to " + finish_time + `<br>Vehicle: ${event.vehicle_type}T`;
        }
      }
      else {
        return `Start: ${event.StartDate}<br>Finish: ${event.EndDate}`;
      }
    },
    openTimeUpdateDialog(job) {
      this.editedJob = job;
      [this.startHour, this.startMin] = job.Start_Time.split(":", 2);
      [this.finishHour, this.finishMin] = job.finish_time.split(":", 2);
      this.timeUpdateDialog = true;
    },
    updateJobTimes() {
      this.$refs.timeUpdate.validate()
            this.editedJob.Start_Time = this.startHour + ":" + this.startMin;
        this.editedJob.finish_time = this.finishHour + ":" + this.finishMin;
    axios.put('/contractor_routes/job/times/' + this.editedJob.JobID, this.editedJob)
    .then((res) => {
      this.loadEvents({ "start": this.start_date, "end": this.end_date });
        this.timeUpdateDialog = false;
        if (res.request.status === 201) {
          this.snackbar.color = "success";
          this.snackbar.text = "Job updated";
          this.snackbar.visible = true;
        }

    })

      .catch ((e) => {
        if (axios.isAxiosError(e) && e.request.status === 401) {
          router.replace('/login')
        }
        else {
          this.errors.push(e);
          this.snackbar.color = "error";
          this.snackbar.text = this.errors.pop();
          this.snackbar.visible = true;
        }
      })
    },
    acceptJob(selectedJob) {
        axios.put('/contractor_routes/job/accept/' + selectedJob.JobID)
        .then((res) => {
            res.status === 201
            this.snackbar.color = "success";
            this.snackbar.text = "Job Accepted";
            this.snackbar.visible = true;
            this.loadEvents({ "start": this.start_date, "end": this.end_date });
            this.selectedOpen = false;
            this.$refs.popup.$forceUpdate()
        })
        .catch((e) => {
          if (axios.isAxiosError(e) && e.request.status === 401) {
            router.replace('/login')
          }
          else {
            console.log(e)
          }
         })
    },

    calculateDuration() {
      if (
        this.startHour != "" &&
        this.startMin != "" &&
        this.finishHour != "" &&
        this.finishMin != ""
      ) {
        const startTime = new Date(
          new Date().toISOString().split('T')[0] + "T" + this.startHour + ":" + this.startMin + ":" + "00"
        );
        const endTime = new Date(
          new Date().toISOString().split('T')[0] +
            "T" +
            this.finishHour +
            ":" +
            this.finishMin +
            ":" +
            "00"
        );
        this.duration = ((endTime - startTime) / 36e5).toFixed(2);
      }
    },

    chopSeconds(time) {
      return time.split(":", 2).join(":");
    },

    fillHours() {
      let i = 0;
      for (i = 0; i < 24; i++) {
        this.hours.push(i.toString().padStart(2, "0"));
      }
    },
    fillMinutes() {
      let i = 0;
      for (i = 0; i < 60; i++) {
        this.mins.push(i.toString().padStart(2, "0"));
      }
    },
  },
};
</script>

<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  width: 100%;
  padding-left: 5px;
  cursor: pointer;
}

.v-calendar-weekly {
  height: auto;
  min-height: 600px;
}
</style>
