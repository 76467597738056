<template>
  <v-container id="contractor_leave" grid-list-md fluid>
    <v-dialog v-model="addLeaveDialog" max-width="920">
      <add-leave-form @onCloseDialog="leaveAdded"></add-leave-form>
    </v-dialog>
    <v-layout row wrap>
      <v-flex flex-column xs12 sm12 md12>
        <v-card>
          <unapprove-leave
            @onUpdate="loadLeave"
            :reloadLeaves="reloadLeaves"
          ></unapprove-leave>
        </v-card>
      </v-flex>
      <v-flex flex-column xs12 sm12 md12>
        <!-- show applied leave -->
        <v-card>
          <v-card-title
            >Approved Leave
            <v-spacer></v-spacer>
            <v-btn @click="addLeaveDialog = true" fab dark small color="indigo">
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout raw wrap align-center justify-center>
              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="appliedstartDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="appliedstartDateFormatted"
                      label="From"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="appliedstartDate"
                    no-title
                    @input="appliedstartDateMenu = false"
                    :allowed-dates="fromAllowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="appliedendDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="appliedendDateFormatted"
                      label="To"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="appliedendDate"
                    no-title
                    @input="appliedendDateMenu = false"
                    :allowed-dates="toAllowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-data-table
            :items="leaveList"
            :headers="LeaveHeaders"
            :items-per-page="10"
          >
            <template v-slot:item="props">
              <tr :key="props.index">
                <td class="body-2">{{ props.item.ContractorID }}</td>
                <td class="body-2">{{ props.item.contractorName }}</td>
                <td class="body-2">{{ props.item.formattedStartDate }}</td>
                <td class="body-2">{{ props.item.formattedEndDate }}</td>
                <td>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="deleteLeave(props.item.leaveID)"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        v-on="on"
                      >
                        <v-icon dark>delete_forever</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Leave: {{ props.item.leaveID }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info"
                >No Leave Found</v-alert
              >
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-flex flex-column xs12 sm12 md12>
        <v-card>
          <leave-calendar
            @onUpdate="loadLeave"
            :reloadLeaves="reloadLeaves"
          ></leave-calendar>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import AddLeaveForm from "./AddLeaveForm.vue";
import LeaveCalendar from "./LeaveCalendar.vue";
import UnapproveLeave from "./UnapproveLeave.vue";

export default {
  data() {
    return {
      contractors: [],
      selectedContractor: "",
      addLeaveDialog: false,
      // //date picker
      // endDateDT: new Date(new Date().setDate(new Date().getDate() + 14)),
      // startDateDT: new Date(),
      // startDate: "",
      // endDate: "",
      // startDateFormatted: "",
      // endDateFormatted: "",
      // startDateMenu: false,
      // endDateMenu: false,
      //show applied leave
      appliedendDateDT: new Date(new Date().setDate(new Date().getDate() + 364)),
      appliedstartDateDT: new Date(
        new Date().setDate(new Date().getDate() - 60)
      ),
      appliedstartDate: "",
      appliedendDate: "",
      appliedstartDateFormatted: "",
      appliedendDateFormatted: "",
      appliedstartDateMenu: false,
      appliedendDateMenu: false,

      //contractor applied leave list
      leaveList: [],

      // Control reload of leaves
      reloadLeaves: 0,

      //list of applied leave
      LeaveHeaders: [
        {
          value: "ContractorID",
          align: "left",
          sortable: false,
          text: "Contractor ID",
        },
        {
          value: "contractorName",
          align: "left",
          sortable: false,
          text: "Name",
        },
        {
          value: "formattedStartDate",
          align: "left",
          sortable: false,
          text: "Start Date",
        },
        {
          value: "formattedEndDate",
          align: "left",
          sortable: false,
          text: "End Date",
        },
        {
          value: "action",
          align: "left",
          sortable: false,
          text: "Delete",
        },
      ],
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
    };
  },
  components: {
    LeaveCalendar: LeaveCalendar,
    AddLeaveForm: AddLeaveForm,
    UnapproveLeave: UnapproveLeave,
  },
  create() {
    this.startDate = this.parseDate(this.startDateDT);
    this.endDate = this.parseDate(this.endDateDT);
    this.startDateFormatted = this.formatDate(this.startDate);
    this.endDateFormatted = this.formatDate(this.endDate);
  },
  watch: {
    appliedstartDate(val) {
      this.appliedstartDateFormatted = this.formatDate(this.appliedstartDate);
      //load applied leave
      this.loadLeave();
    },
    appliedendDate(val) {
      this.appliedendDateFormatted = this.formatDate(this.appliedendDate);
      //load applied leave
      this.loadLeave();
    },
  },
  mounted() {
    //applied leave date
    this.appliedstartDate = this.parseDate(this.appliedstartDateDT);
    this.appliedendDate = this.parseDate(this.appliedendDateDT);
    this.appliedstartDateFormatted = this.formatDate(this.appliedstartDate);
    this.appliedendDateFormatted = this.formatDate(this.appliedendDate);
    this.loadLeave();
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      date = date.toLocaleDateString("en-AU");
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    // load all contractors ID to the list
    loadContractors() {
      axios
        .get("/leave/contractor_list")
        .then((response) => {
          if (response.status === 200) {
            response.data.forEach((response) => {
              let contractorListItem = {
                text:
                  response.ContractorID +
                  ": " +
                  (response.PreferedName || response.FirstName) +
                  " " +
                  response.LastName,
                value: response.ContractorID,
              };
              this.contractors.push(contractorListItem);
            });
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    //filter from the dropdown list to select contractor
    customFilter(item, queryText, itemText) {
      const textOne = item.text.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    //get selected contractor leave
    loadLeave() {
      this.reloadLeaves++;
      this.leaveList = [];
      axios
        .get(
          "/leave/?startDate=" +
            this.appliedstartDate +
            "&endDate=" +
            this.appliedendDate
        )
        .then((response) => {
          if (response.status === 200) {
            this.leaveList = response.data.filter((leave) => leave.approved === 1)
            this.leaveList.map((c) => {
              c.contractorName =
                (c.PreferedName || c.FirstName) + " " + c.LastName;
            });
          } else if (response.status === 416) {
            //no leave found, set list to empty
            this.leaveList = [];
          }
          //console.log(response.status);
        })
        .catch((e) => {
          //set leave list to empty
          this.leaveList = [];
        });
    },

    leaveAdded() {
      this.addLeaveDialog = false;
      this.loadLeave();
      this.reloadCalendar++;
      this.reloadUnapproved++;
      this.snackbar.color = "success";
      this.snackbar.text = "Leave added";
      this.snackbar.visible = true;
    },
    //delete selected leave from selected contractor
    deleteLeave(leaveID) {
      axios
        .post("/leave/deleteLeave?leaveID=" + leaveID)
        .then((response) => {
          if (response.status === 200) {
            this.loadLeave();
            this.reloadCalendar++;
            this.reloadUnapproved++;
            this.snackbar.color = "success";
            this.snackbar.text = "Leave deleted";
            this.snackbar.visible = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fromAllowedDates(date) {
      return date <= this.appliedendDate;
    },
    toAllowedDates(date) {
      return date >= this.appliedstartDate;
    },
  },
};
</script>

<style></style>
