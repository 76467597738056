<template>
  <v-container grid-list-md fluid>
    <v-card>
      <v-card-title class="headline" title--text>Generate ABA</v-card-title>
      <v-card-text>
        <v-form ref="aba">
          <v-flex xs12 md4>
            <v-text-field
              label="Description on your bank statement"
              maxlength="12"
              :rules="[rules.required]"
              v-model="adminBankDesc"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              label="Description contractor bank statement"
              maxlength="18"
              :rules="[rules.required]"
              v-model="contractorBankDesc"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-menu
              v-model="menupDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="Processing Date"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="pDate"
                no-title
                @input="menupDate = false"
                :rules="[rules.required]"
              ></v-date-picker>
            </v-menu> </v-flex
          ><br />
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="payslips"
            :single-select="singleSelect"
            item-key="payslipID"
            show-select
            class="elevation-1"
          >
            <template v-slot:top>
              <v-switch
                inset
                v-model="singleSelect"
                label="Single select"
                class="pa-3"
              ></v-switch>
            </template> </v-data-table
          ><br />
          <v-btn type="button" @click="downloadABA">Download ABA</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  components: {},

  created() {
    console.log("call created block");
    console.log(this.compDetail.length);
    if (this.compDetail.length === 0) {
      this.loadUnpaidPayslip();
      this.loadCompDetail();
    }
  },

  methods: {
    loadUnpaidPayslip: function () {
      var paid = "0";
      var total = "0";
      axios
        .get(`/aba/${0}/ABA`)
        .then((response) => {
          if (response.status === 200) {
            //console.log("other than 416");
            this.payslips = response.data;
          } else {
            //console.log("no payslip to show");
            Location.reload();
            this.payslips = [];
          }
        })
        .catch((e) => {
          this.errors.push(e);
          //console.log($e)
        });
    },
    loadCompDetail: function () {
      //get first company detail
      //console.log("load compDetail");
      axios
        .get(`/aba/${1}/compDetail`)
        .then((response) => {
          this.compDetail = response.data;
          //console.log(this.compDetail);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    downloadABA: function () {
      if (this.$refs.aba.validate()) {
        //clear selected record
        this.savingText = "";
        //this.checked=[];
        //compile text to make it ready for file saving.
        var type0 = "0";
        //fill 17 empty space
        type0 += this.postFilled("", 17, " ");
        type0 += "01";
        //'Name of User Financial() Institution() Alpha Must contain the bank mnemonic(that Is associated with the BSB of the funds account. e.g. ‘ANZ’. Length 3
        type0 += this.compDetail[0].bank_code;
        //'Reserved Alpha Blank filled. Length 7

        type0 = type0 + this.preFilled("", 7, " ");
        //'Name of User supplying(File) Alpha User Preferred Name as registered with ANZ’. length 26
        type0 =
          type0 + this.postFilled(this.compDetail[0].account_name, 26, " ");
        //'User Identification() number() Numeric Direct Entry User ID. Right-justified, zero-filled. length 6
        type0 = type0 + this.compDetail[0].direct_entry;
        //'Description of entries on File Alpha Description of payments in the file (e.g. Payroll, Creditors etc.).Length 12
        type0 = type0 + this.postFilled(this.adminBankDesc, 12, " ");
        //'Date to be processed() Alpha Date on which the payment is to be processed. DDMMYY (e.g. 311218).
        var strDate = this.dateFormat(this.pDate);

        type0 = type0 + strDate;
        //'Time Alpha Time on which the payment is to be processed. 24 hour format - HHmm.
        //'type0 = type0 + "2300"
        //'Reserved Alpha Blank filled. length 36.
        type0 = type0 + this.preFilled("", 40, " ");
        type0 += "\r\n";
        this.savingText += type0;

        //'-------------------------Add employee to ABA------------------------------
        //'clear selected record list
        //CommonDef.payslipID.Clear()

        //'get record from datagrid
        var recordCount = 0;
        var TotalDebit = 0;
        var TotalCredit = 0;

        //this.payslipID=[];
        //console.log("selected Record: "+this.selected.length);
        for (var i = 0; i < this.selected.length; i++) {
          recordCount += 1;
          //this.payslipIDs=[];

          this.payslipIDs.push(this.selected[i].payslipID.toString().trim());

          //Record type Numeric Must be ‘1’.
          var type1 = "";
          type1 += "1";
          //BSB of account to be CREDITED or DEBITED() Alpha Bank/State/Branch number with a hyphen in the 4th character position. e.g. 013-999.
          type1 += this.selected[i].BSB.trim();

          //Account() number to be CREDITED or DEBITED() Alpha Numeric, alpha, hyphens & blanks are valid. Right justified, blank filled. Leading zeros that are part of an Account Number must be included.
          type1 += this.preFilled(this.selected[i].AccountNo.trim(), 9, " ");

          //Withholding(Tax(Indicator))Alpha One of the following values, If applicable Then
          //        W – Dividend paid to a resident of a country where a double tax agreement is in force.
          //        X – Dividend paid to a resident of any other country.
          //        Y – Interest paid to all nonresidence.
          //        The amount of withholding tax is to appear in the Amount of Withholding Tax field.
          type1 += " ";

          //Transaction(Code()) Numeric Select from the following options as appropriate:
          //        50 General Credit.
          //        53 Payroll.
          //        54 Pension.
          //        56 Dividend.
          //        57 Debenture Interest.
          //        13 General Debit.
          type1 += "50";
          //Amount to be CREDITED or DEBITED() Numeric Right justified, zero filled, unsigned, two decimal places are implied
          //        (e.g. $10.21 is recorded as 0000001021).
          var dbtotal = this.selected[i].total.toFixed(2).toString();
          var total = parseFloat(dbtotal.replace(".", ""));
          TotalCredit += total;
          type1 += this.preFilled(total, 10, "0");
          //console.log('after prefilled: '+ type1);

          //'Title of account to be CREDITED or DEBITED().Alpha Preferred format is:
          //'        Surname followed by given names with one blank between each name.
          //'        e.g. SMITH John Alan.
          type1 += this.postFilled(this.selected[i].AccountName, 32, " ");
          //'Lodgement(Reference()) Produced on the(recipient)'s Account() Statement.Left justified, blank filled
          //'        Alpha Payment reference indicating details of the origin of the entry (e.g. payroll(number, policy) number).
          type1 += this.postFilled(this.contractorBankDesc, 18, " ");
          //'Trace(BSB) Number() Numeric Bank/State/Branch number of the trace account with a hyphen in the 4th character position.
          //'        e.g. 013-999
          type1 += this.compDetail[0].bsb;
          //'Trace(Account) Number() Alpha Numeric, alpha, hyphens & blanks are valid. Right justified, blank filled. Leading zeros that are part of an Account Number must be included.
          type1 += this.preFilled(this.compDetail[0].account_number, 9, " ");
          //'Name of Remitter() Produced on the(recipient)'s Account() Statement Alpha Name of originator of the entry. This may vary from Name of User. Left justified, blank filled.
          type1 += this.postFilled(
            this.compDetail[0].account_name_short,
            16,
            " "
          );

          //'Withholding(amount())Numeric Must be zero filled or contain a withholding tax amount. If it contains a withholding tax amount, two decimal placed are implied (e.g. $10.21 is recorded as 0000001021).
          type1 += this.preFilled("", 8, "0");

          //'newline for new record.
          type1 += "\r\n";
          this.savingText += type1;
        }
        //console.log(this.payslipIDs);
        //console.log("payslipID length after type 1 " + this.payslipIDs.length);

        var type7 = "";
        //'Compose type 7
        //Record type - must be 7, length 1.
        type7 = "7";
        //Reserve() - must be 999-999 length 7
        type7 += "999-999";
        //Reserve()- blank filled 12
        type7 += this.postFilled("", 12, " ");
        //Batch net total amount - length 10, zero filled
        type7 += this.preFilled(TotalCredit - TotalDebit, 10, "0");
        //batch neet total credit - length 10, zero filled
        type7 += this.preFilled(TotalCredit, 10, "0");
        //batch net total debit - length 10, zero filled
        type7 += this.preFilled(TotalDebit, 10, "0");
        //Reserve()-length 24 blank filled
        type7 += this.preFilled("", 24, " ");
        //Batch total record in type 1 - length 6, zero filled
        type7 += this.preFilled(recordCount, 6, "0");
        //reserve - length 40, blank filled
        type7 += this.preFilled("", 40, " ");

        var payload = { data: this.payslipIDs };
        axios
          .post(`/aba/ABASetPaid`, payload)
          .then((res) => {
            //console.log("response recieved: ",res.data);
            if (res.status === 200) {
              //save text to file.
              this.savingText += type7 + "\r\n";
              var blob = new Blob([this.savingText], { type: "text/plain" });
              this.forceFileDownload(blob, "ABA.aba");
              //console.log(this.payslipIDs);
              this.selected = [];
              this.payslipIDs = [];
              this.loadUnpaidPayslip();
            } else {
              alert(res.error);
              //console.log("ABASetPaid: return other than 200");
            }
          })
          .catch((e) => {
            // if (e.response){
            //     console.log(e.response.data);
            //     console.log(e.response.status);
            //     console.log(e.response.headers);
            // } else if (e.request){
            //     console.log(e.request);
            // } else {
            //     console.log(e.message);
            // }
            this.errors.push(e);
            alert(e);
            //console.log("Catch ABASetPaid post Axios error: ", e);
          });
        //console.log("after post ABASetPaid");
      }
    },

    forceFileDownload: function (textData, filename) {
      const url = window.URL.createObjectURL(new Blob([textData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      //this.payslipID = 0;
    },
    repeatChar: function (strSize, ch) {
      var tmp = "";
      for (var i = 0; i < strSize; i++) {
        tmp += ch;
      }
      return tmp;
    },
    preFilled: function (strValue1, strSize, ch) {
      var tmp = strValue1.toString();
      //strValue1+=tmp;

      if (tmp.lenght > strSize) {
        alert(
          "string '" +
            strValue1 +
            "' longer than expected size (" +
            strSize +
            "). Please correct your data and try again."
        );
        return false;
      }
      return tmp.padStart(strSize, ch);
    },
    postFilled: function (strValue2, strSize, ch) {
      var tmp = strValue2.toString();
      //strValue2+=tmp;

      if (strValue2.length > strSize) {
        alert(
          "string '" +
            strValue2 +
            "' longer than expected size (" +
            strSize +
            "). Please correct your data and try again."
        );
        return false;
      }
      return tmp.padEnd(strSize, ch);
    },

    dateFormat: function (strVal) {
      var tmp = "" + strVal;
      var splitstr = tmp.split("-");
      return splitstr[2] + splitstr[1] + splitstr[0].slice(2, 4);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    toggleAll() {
      //console.log("toggle All");
      if (this.selected.length) this.selected = [];
      else this.selected = this.payslips.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.pDate);
    },
    // unpaidPayslip(){
    //     return this.loadUnpaidPayslip();
    // }
  },
  data() {
    return {
      /* pagination: {
                sortBy: 'Payslip ID'
            },*/
      singleSelect: false,
      //forceUpdate:0,
      menupDate: false,
      selected: [],
      errors: [],
      savingText: "",
      pDate: "",
      adminBankDesc: "",
      contractorBankDesc: "",
      compDetail: [],
      payslipIDs: [],
      //declare array attributes
      headers: [
        { value: "payslipID", sortable: true, text: "Payslip ID" },
        { value: "ContractorID", sortable: true, text: "Contractor ID" },
        { value: "FirstName", sortable: true, text: "Contractor First Name" },
        { value: "LastName", sortable: true, text: "Contractor Last Name" },
        { value: "BSB", sortable: true, text: "BSB" },
        { value: "AccountNo", sortable: false, text: "Account Number" },
        { value: "ContractorName", sortable: false, text: "Account Name" },
        { value: "fuel_levy", sortable: true, text: "Fuel Levy" },
        { value: "toll_charge", sortable: false, text: "Toll Charge" },
        { value: "retain_gst", sortable: false, text: "Retain GST" },
        { value: "tax_income", sortable: false, text: "Taxable" },
        { value: "total", sortable: false, text: "Gross" },
      ],
      payslips: [],
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },
};
</script>
