<template>
  <v-card>
    <v-card-title> Unapproved Leave </v-card-title>
    <v-card>
      <v-data-table :headers="leaveHeaders" :items="unapprovedleaves">
        <template v-slot:item="props">
          <tr class="pa-0">
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.ContractorID }}
            </td>
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.contractorName }}
            </td>
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.formattedStartDate }}
            </td>
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.formattedEndDate }}
            </td>
            <td>
              <v-icon
                class="mr-2"
                @click="
                  approveLeave(
                    props.item.leaveID,
                    props.item.ContractorID,
                    props.item.formattedStartDate,
                    props.item.formattedEndDate
                  )
                "
                >thumb_up</v-icon
              >
              <v-icon
                @click="
                  declineLeave(
                    props.item.leaveID,
                    props.item.ContractorID,
                    props.item.formattedStartDate,
                    props.item.formattedEndDate
                  )
                "
                >thumb_down</v-icon
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: {
    reloadLeaves: Number,
  },

  data: () => ({
    unapprovedleaves: [],
    unapprovedleave: {},
    leaveHeaders: [
      {
        value: "contractorID",
        align: "left",
        sortable: true,
        text: "Contractor ID",
      },
      {
        value: "contractorName",
        align: "left",
        sortable: false,
        text: "Name",
      },
      {
        value: "start_date",
        align: "left",
        sortable: true,
        text: "Start Date",
      },
      { value: "end_date", align: "left", sortable: false, text: "End Date" },
      { align: "left", sortable: false, text: "Approve/Decline" },
    ],
    start: {},
    end: {},
    snackbar: {
      visible: false,
      color: "success",
      timeout: 3000,
      text: "",
    },
  }),
  mounted() {
    //this.$refs.leave_calendar.checkChange();
    this.loadUnapproved();
  },
  create() {
    this.loadUnapproved();
  },
  watch: {
    reloadLeaves() {
      this.loadUnapproved();
    },
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.leave_calendar.prev();
    },
    next() {
      this.$refs.leave_calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    loadUnapproved() {
      // Load contractor leave
      // console.log(start);
      // console.log(end);
      axios
        .get("/leave/unapproved")
        .then((response) => {
          if (response.status === 200) {
            this.unapprovedleaves = response.data;
            this.unapprovedleaves.map((c) => {
              c.contractorName =
                (c.PreferedName || c.FirstName) + " " + c.LastName;
            });
          } else if (response.status === 416) {
            //no leave found, set list to empty
            this.leaveList = [];
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    approveLeave(leaveID, contractorId, startDate, endDate) {
      let message = startDate + " - " + endDate;
      axios
        .post(
          "/leave/approveLeave?leaveID=" +
            leaveID +
            "&contractorId=" +
            contractorId,
          { message: message }
        )
        .then((response) => {
          if (response.status === 200) {
            this.loadUnapproved();
            this.selectedOpen = false;
            this.$emit("onUpdate");
            this.snackbar.color = "success";
            this.snackbar.text = "Leave has been approved";
            this.snackbar.visible = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    declineLeave(leaveID, contractorId, startDate, endDate) {
      let message = startDate + " - " + endDate;
      axios
        .post(
          "/leave/deleteLeave?leaveID=" +
            leaveID +
            "&contractorId=" +
            contractorId,
          { message: message }
        )
        .then((response) => {
          if (response.status === 200) {
            this.loadUnapproved();
            this.selectedOpen = false;
            this.$emit("onUpdate");
            this.snackbar.color = "success";
            this.snackbar.text = "Leave has been declined";
            this.snackbar.visible = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  width: 100%;
  padding-left: 5px;
  cursor: pointer;
}

.v-calendar-weekly {
  height: auto;
  min-height: 600px;
}
</style>
