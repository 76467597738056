<template>
    <v-container id="job" grid-list-md fluid>
      <v-form ref="job" v-model="isFormValid">
        <v-flex xs12 sm12 md12>
          <v-card>
            <v-card-title v-if="jobId === 0" class="headline pa-7" title--text
              >Job Request for {{ clientName }}
              </v-card-title>
            <v-card-title v-else-if="job" class="headline" title--text>
              Modify JobID: {{ jobId }}
              <!-- Not sure if we want clients to download invoice -->
              <!-- <v-layout raw wrap align-center justify-end>
                <v-chip
                  @click="downloadPdf('invoice', job.client_invoiceID)"
                  @click:close="invoiceDetachDialog = true"
                  color="teal darken-2"
                  class="subheading"
                  outlined
                  v-if="job.client_invoiceID"
                >
                  <v-icon left>receipt</v-icon>
                  Invoice {{ job.client_invoiceID }}
                </v-chip>
              </v-layout> -->

            </v-card-title>

            <v-dialog
              v-if="job"
              v-model="deleteJobDialog"
              persistent
              max-width="450"
            >
              <v-card>
                <v-card-title class="headline">Delete Job?</v-card-title>
                <v-card-text
                  >Are you sure you want to delete {{ job.DepotName }} Job:
                  {{ jobId }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="error" text @click="deleteJob">
                    Yes
                    <v-icon right dark>delete_forever</v-icon>
                  </v-btn>
                  <v-btn class="success" text @click="deleteJobDialog = false">
                    No
                    <v-icon right dark>cancel</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-card-text>
              <v-layout raw wrap align-center justify-center>
                <v-spacer></v-spacer>
                <v-flex xs10 sm3 md2>
                  <v-menu
                    v-model="jobDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        dense
                        v-model="dateFormatted"
                        label="Job Date (dd/mm/yyyy)"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="jobDate"
                      no-title
                      @input="jobDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs5 sm2 md1 v-if="rateType === 'hourly'">
                  <v-autocomplete
                    dense
                    class="text-xs-center"
                    :items="hours"
                    label="Start HH"
                    v-model="startHour"
                  ></v-autocomplete>
                </v-flex>
                <h1 v-if="rateType === 'hourly'">:</h1>
                <v-flex xs5 sm2 md1 v-if="rateType === 'hourly'">
                  <v-autocomplete
                    dense
                    :items="mins"
                    label="Start MM"
                    v-model="startMin"
                  ></v-autocomplete>
                </v-flex>
                <h1 v-if="rateType === 'hourly'">-</h1>
                <v-flex xs5 sm2 md1 v-if="rateType === 'hourly'">
                  <v-autocomplete
                    dense
                    :items="hours"
                    label="Finish HH"
                    v-model="finishHour"
                  ></v-autocomplete>
                </v-flex>
                <h1 v-if="rateType === 'hourly'">:</h1>
                <v-flex xs5 sm2 md1 v-if="rateType === 'hourly'">
                  <v-autocomplete
                    dense
                    :items="mins"
                    label="Finish MM"
                    v-model="finishMin"
                  ></v-autocomplete>
                </v-flex>
                <h1 v-if="rateType === 'hourly'">=</h1>
                <v-flex xs5 sm2 md1 v-if="rateType === 'hourly'">
                  <v-text-field
                    dense
                    label="Duration"
                    readonly
                    suffix="Hrs"
                    v-model="duration"
                    :rules="[rules.duration]"
                  ></v-text-field>
                </v-flex>
                <v-spacer
                  v-if="rateType !== 'piece' && rateType !== 'stop'"
                ></v-spacer>
                <v-spacer
                  v-if="rateType !== 'piece' && rateType !== 'stop'"
                ></v-spacer>
                <v-flex xs5 sm6 md6 v-if="rateType === 'flat'">
                  <v-subheader>Day</v-subheader>
                  <v-slider
                    dense
                    prepend-icon="access_time"
                    min="25"
                    step="25"
                    ticks="always"
                    :tick-labels="[
                      'Quarter Day',
                      'Half Day',
                      'Three Quarter Day',
                      'Full Day',
                    ]"
                    :max="100"
                    tick-size="1"
                    v-model="percentage_day"
                    thumb-label="always"
                    @click="beInProgress = false"
                  ></v-slider>
                </v-flex>
                <v-spacer v-if="rateType === 'flat'"></v-spacer>
                <v-flex xs5 sm2 md1 v-if="rateType === 'flat'">
                  <v-text-field
                    dense
                    label="% Day"
                    readonly
                    suffix="% Day"
                    v-model="percentage_day"
                  ></v-text-field>
                </v-flex>
                <v-spacer v-if="rateType === 'flat'"></v-spacer>
                <v-flex md3 v-if="rateType === 'piece'">
                  <v-text-field
                    dense
                    label="Number of pieces"
                    v-model="pieces"
                    type="number"
                    min="0"
                    step="1"
                    oninput="validity.valid||(value='');"
                    @click="beInProgress = false"
                  ></v-text-field>
                </v-flex>
                <v-flex md3 v-if="rateType === 'stop'">
                  <v-text-field
                    dense
                    label="Number of stops"
                    v-model="stops"
                    type="number"
                    min="0"
                    step="1"
                    oninput="validity.valid||(value='');"
                    @click="beInProgress = false"
                  ></v-text-field>
                </v-flex>

                <v-spacer></v-spacer>
                <v-flex grow xs12 sm5 md2>
                  <v-text-field
                    dense
                    label="Description"
                    v-model="description"
                  ></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout raw wrap align-start justify-space-around>
                <v-flex xs12 sm12 md5>
                  <H3>Client Rates</H3>

                  <v-flex xs12 sm12 md12>
                    <v-layout align-center justify-space-around>
                      <v-flex xs6 sm6 md5>
                        <v-select
                          prepend-icon="local_shipping"
                          clearable
                          v-model="vehicle"
                          label="Vehicle Type"
                          :items="vehicles"
                          :disabled="
                            job !== null && job.client_invoiceID !== null
                          "
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-flex xs12 sm12 md12>
                      <v-data-table
                        dense
                        :items="filteredClientRates"
                        :headers="clientRateHeaders"
                        :items-per-page="10"
                      >
                        <template v-slot:item="props">
                          <tr
                            :key="props.index"
                            :class="{
                              active:
                                !!selectedClientRate &&
                                selectedClientRate.rate_id == props.item.rate_id,
                            }"
                            @click="clientRateSelected(props.item)"
                          >
                            <td class="body-2 text-xs-center">
                              {{ props.item.vehicle_type }} TV
                            </td>
                            <td class="body-2">
                              {{ props.item.rate_description }}
                            </td>
                            <td align="right">
                              {{
                                rateTypes.find(
                                  (el) => el.value === props.item.rate_type
                                ).text
                              }}
                            </td>
                            <!-- <td class="body-2 text-xs-right" align="right">
                              $ {{ parseFloat(props.item.rate).toFixed(2) }}
                            </td> -->
                          </tr>
                        </template>
                        <template v-slot:no-data>
                          <v-alert :value="true" color="info" icon="info"
                            >Select Depot Name</v-alert
                          >
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-flex>
                </v-flex>
                <v-flex xs12 sm12 md6>
                  <H3>Contractor (optional)</H3>
                  <v-layout align-center justify-space-around>
                    <v-flex xs12 sm12 md6>
                      <v-text-field
                        v-model="searchContractor"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        :disabled="
                          job !== null && job.contractor_payslipID !== null
                        "
                      ></v-text-field>
                    </v-flex>

                  </v-layout>
                  <v-divider></v-divider>

                  <v-flex xs12 sm12 md12>
                    <v-data-table
                      dense
                      :items="filteredContractorRates"
                      :search="searchContractor"
                      :headers="contractorRateHeaders"
                      :items-per-page="10"
                    >
                      <template v-slot:item="props">
                        <tr
                          :key="props.index"
                          :class="{
                            active:
                              !!selectedContractorRate.rego &&
                              selectedContractorRate.rego == props.item.rego &&
                              selectedContractorRate.vehicle_type ==
                                props.item.vehicle_type,
                          }"
                          @click="contractorRateSelected(props.item)"
                        >
                          <td class="body-2">{{ props.item.ContractorID }}</td>
                          <td class="body-2">{{ props.item.ContractorName }}</td>
                          <td class="body-2">{{ props.item.rego }}</td>
                          <td class="body-2" align="center">
                            {{ props.item.vehicle_type }} TV
                          </td>
                          <!-- <td class="body-2" align="right">
                            % {{ parseFloat(props.item.fuel_levy).toFixed(2) }}
                          </td> -->
                          <td align="right">
                            {{
                              rateTypes.find(
                                (el) => el.value === props.item.rate_type
                              ).text
                            }}
                          </td>
                          <!-- <td class="body-2" align="right">
                            $ {{ parseFloat(props.item.rate).toFixed(2) }}
                          </td> -->
                        </tr>
                      </template>
                      <template v-slot:no-data>
                        <v-alert :value="true" color="info" icon="info"
                          >No Contractor Rate</v-alert
                        >
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-flex>
              </v-layout>

            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="
                  job !== null &&
                  (job.client_invoiceID === null ||
                    job.contractor_payslipID === null)
                "
                @click="confirmDeleteJob"
                color="error"
                dark
              >
                Delete
                <v-icon right dark>delete_forever</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <div class="text-left">
                <v-icon class="deep-orange--text">schedule</v-icon>
                <span>
                  Times are optional & indicative only. Leave unchanged if not confirmed </span>
                  <v-spacer/>
                  <v-icon class="deep-orange--text">local_shipping</v-icon>
                  <span>
                    Contractor selection is optional</span>
              </div>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="beInProgress || !isFormValid"
                :dark="!beInProgress && isFormValid"
                v-if="jobId === 0"
                @click="addJob"
                color="success"
              >
                Add
                <v-icon right dark>save</v-icon>
              </v-btn>
              <v-btn
                :disabled="beInProgress || !isFormValid"
                v-else
                @click="updateJob"
                color="success"
                :dark="!beInProgress && isFormValid"
              >
                Update
                <v-icon right dark>save</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-form>

      <v-snackbar
        v-model="snackbar.visible"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </template>

  <script>
  import axios from "axios";

  export default {
    data() {
      return {
        invoiceDetachDialog: 0,
        payslipDetachDialog: 0,
        deleteJobDialog: 0,
        jobId: 0,
        job: null,
        hideTimes: false,
        jobDate: this.parseDate(new Date().toLocaleDateString("en-AU")),
        dateFormatted: new Date().toLocaleDateString("en-AU"),
        jobDateMenu: false,
        hours: [],
        mins: [],
        startHour: "00",
        startMin: "00",
        finishHour: "00",
        finishMin: "00",
        duration: 0,
        percentage_day: 100,
        pieces: 0,
        stops: 0,
        description: "",
        Clients: [],
        clientName: "",
        vehicles: [],
        vehicle: null,
        clientRates: [],
        selectedClientRate: {},
        showAllContractorRates: false,
        toll: "0.00",
        clientRateHeaders: [
          {
            value: "vehicle_type",
            align: "left",
            sortable: true,
            text: "Vehicle Type",
          },
          {
            value: "rate_description",
            align: "left",
            sortable: false,
            text: "Description",
          },
          {
            value: "rate_type",
            align: "end",
            sortable: true,
            text: "Rate Type",
          },
          // {
          //   value: "rate",
          //   align: "end",
          //   sortable: false,
          //   text: "Rate",
          // },
        ],
        contractorRates: [],
        selectedContractorRate: {},
        searchContractor: "",
        contractorRateHeaders: [
          {
            value: "ContractorID",
            align: "left",
            sortable: true,
            text: "ContractorID",
          },
          {
            value: "ContractorName",
            align: "left",
            sortable: false,
            text: "Name",
          },
          {
            value: "rego",
            align: "left",
            sortable: false,
            text: "Rego",
          },
          {
            value: "vehicle_type",
            align: "center",
            sortable: true,
            text: "Vehicle Type",
          },

          // {
          //   value: "fuel_levy",
          //   align: "end",
          //   sortable: false,
          //   text: "Fuel Levy",
          // },
          {
            value: "rate_type",
            align: "end",
            sortable: true,
            text: "Rate Type",
          },
          // {
          //   value: "rate",
          //   align: "end",
          //   sortable: false,
          //   text: "Rate",
          // },
        ],
        rateTypes: [
          { text: "Hourly", value: "hourly" },
          { text: "Flat Rate", value: "flat" },
          { text: "Piece Rate", value: "piece" },
          { text: "Stop Rate", value: "stop" },
        ],

        gstLabel: "GST",
        beInProgress: false,
        isFormValid: false,

        rules: {
          required: (value) => !!value || "Required",
          counter: (value) => value.length <= 20 || "Max 20 characters",
          email: (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          duration: (value) =>
            (value >= 0 &&
              this.selectedClientRate.rate_type === "hourly") ||
            "Should be >= 0",
        },
        snackbar: {
          visible: false,
          color: "success",
          timeout: 3000,
          text: "",
        },
        errors: [],
      };
    },

    created() {
      if (this.$route.params.id) {
        this.jobId = this.$route.params.id;
      }

      if (this.jobId !== 0) {
        this.loadJob();
      }
      this.loadClient();
      this.fillHours();
      this.fillMinutes();
      this.loadClientRates();
      this.loadContractorRates();
    },
    computed: {
      filteredClientRates() {
        return this.clientRates.filter((rate) => {
          return !this.vehicle || rate.vehicle_type == this.vehicle;
        });
      },
      filteredContractorRates() {
        return this.contractorRates.filter((rate) => {
          return (
            !this.selectedClientRate.vehicle_type ||
            ((this.showAllContractorRates ||
              this.selectedClientRate.vehicle_type <= rate.vehicle_type) &&
              this.selectedClientRate.rate_type === rate.rate_type)
          );
        });
      },
      selectedFuelLevy() {
        return (
          this.Clients.filter((client) => {
            return this.DepotName == client.DepotName;
          })[0] || { fuel_levy: 0 }
        );
      },
      clientVehicle() {
        if (!this.job.client_vehicle_type) {
          return "0";
        } else {
          return this.job.client_vehicle_type.split(" ", 1)[0];
        }
      },
      clientRate() {
        if (!this.selectedClientRate.rate) {
          return "0.00";
        } else {
          return parseFloat(this.selectedClientRate.rate).toFixed(2);
        }
      },
      clientSubTotal() {
        if (!this.selectedClientRate.rate) {
          return "0.00";
        } else {
          let factor = 0;

          if (this.selectedClientRate.rate_type === "flat") {
            factor = this.percentage_day / 100;
          }
          if (this.selectedClientRate.rate_type === "hourly") {
            factor = this.duration;
          }
          if (this.selectedClientRate.rate_type === "piece") {
            factor = this.pieces;
          }
          if (this.selectedClientRate.rate_type === "stop") {
            factor = this.stops;
          }
          return parseFloat(factor * this.selectedClientRate.rate).toFixed(2);
        }
      },
      clientFuelLevy() {
        return parseFloat(
          this.clientSubTotal * (this.selectedFuelLevy.fuel_levy / 100)
        ).toFixed(2);
      },
      clientToll() {
        if (this.toll != "0.00") {
          return parseFloat(this.toll / 1.1).toFixed(2);
        } else {
          return "0.00";
        }
      },
      clientGST() {
        return parseFloat(
          (parseFloat(this.clientSubTotal) +
            parseFloat(this.clientFuelLevy) +
            parseFloat(this.clientToll)) *
            0.1
        ).toFixed(2);
      },
      clientTotal() {
        return parseFloat(
          parseFloat(this.clientSubTotal) +
            parseFloat(this.clientFuelLevy) +
            parseFloat(this.toll) +
            parseFloat(this.clientGST)
        ).toFixed(2);
      },
      contractorRate() {
        if (this.selectedContractorRate.rate) {
          return parseFloat(this.selectedContractorRate.rate).toFixed(2);
        } else {
          return "0.00";
        }
      },

      contractorFuelLevyPer() {
        if (this.selectedContractorRate.fuel_levy) {
          return parseFloat(this.selectedContractorRate.fuel_levy).toFixed(2);
        } else {
          return "0.00";
        }
      },
      contractorFuelLevy() {
        if (this.selectedContractorRate.fuel_levy) {
          return parseFloat(
            this.contractorSubTotal *
              (this.selectedContractorRate.fuel_levy / 100)
          ).toFixed(2);
        } else {
          return "0.00";
        }
      },

      contractorGST() {
        return parseFloat(
          (parseFloat(this.contractorSubTotal) +
            parseFloat(this.contractorFuelLevy)) *
            0.1
        ).toFixed(2);
      },

      contractorSubTotal() {
        if (this.selectedContractorRate.rate) {
          let factor = 0.0;
          if (this.selectedContractorRate.rate_type === "flat") {
            factor = this.percentage_day / 100;
          }
          if (this.selectedContractorRate.rate_type === "hourly") {
            factor = this.duration;
          }
          if (this.selectedContractorRate.rate_type === "piece") {
            factor = this.pieces;
          }
          if (this.selectedContractorRate.rate_type === "stop") {
            factor = this.stops;
          }
          return parseFloat(factor * this.selectedContractorRate.rate).toFixed(2);
        } else {
          return "0.00";
        }
      },
      contractorTotal() {
        if (this.selectedContractorRate.retain_gst === 1) {
          return parseFloat(
            parseFloat(this.contractorSubTotal) +
              parseFloat(this.contractorFuelLevy) +
              parseFloat(this.toll)
          ).toFixed(2);
        } else {
          return parseFloat(
            parseFloat(this.contractorSubTotal) +
              parseFloat(this.contractorFuelLevy) +
              parseFloat(this.contractorGST) +
              parseFloat(this.toll)
          ).toFixed(2);
        }
      },
      rateType() {
        return this.selectedClientRate.rate_type || "hourly";
      },
    },

    watch: {
      jobDate() {
        this.dateFormatted = this.formatDate(this.jobDate);
        this.loadContractorRates();
      },
      startHour() {
        this.calculateDuration();
      },
      startMin() {
        this.calculateDuration();
      },
      finishHour() {
        this.calculateDuration();
      },
      finishMin() {
        this.calculateDuration();
      },
      $route(to, from) {
        if (this.$route.params.id) {
          this.jobId = to.params.id;
        } else {
          this.jobId = 0;
        }

        if (this.jobId !== 0) {
          this.loadJob();
        } else {
          this.$refs.job.resetValidation();
          this.job = null;
          this.hideTimes = false;
          this.jobDate = this.parseDate(new Date().toLocaleDateString("en-AU"));
          this.dateFormatted = new Date().toLocaleDateString();
          this.jobDateMenu = false;
          this.startHour = "00";
          this.startMin = "00";
          this.finishHour = "00";
          this.finishMin = "00";
          this.duration = 0;
          this.description = "";
          this.DepotNamesLoading = false;
          this.DepotName = "";
          this.vehicles = [];
          this.vehicle = null;
          this.clientRates = [];
          this.selectedClientRate = {};
          this.toll = "0.00";
          this.contractorRates = [];
          this.selectedContractorRate = {};
          this.searchContractor = "";
          this.percentage_day = 100;
          this.checkTimes();
        }

      },
    },

    methods: {
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },
      parseDate(date) {
        if (!date) return null;
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      calculateDuration() {
        if (
          this.startHour != "" &&
          this.startMin != "" &&
          this.finishHour != "" &&
          this.finishMin != ""
        ) {
          const startTime = new Date(
            this.jobDate + "T" + this.startHour + ":" + this.startMin + ":" + "00"
          );
          const endTime = new Date(
            this.jobDate +
              "T" +
              this.finishHour +
              ":" +
              this.finishMin +
              ":" +
              "00"
          );
          this.duration = ((endTime - startTime) / 36e5).toFixed(2);
        }
        this.beInProgress = false;
      },
      loadClient() {
        axios
          .get("/client_routes/client")
          .then((response) => {
            this.clientName = response.data[0].Name;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      },
      loadClientRates() {
        this.ratesLoading = true;
        this.beInProgress = true;
        axios
          .get("/client_routes/client/rates")
          .then((response) => {
            response.data.forEach((rate) => {
              this.clientRates.push(rate);
              this.vehicles.push(rate.vehicle_type);
            });
            this.vehicles.sort();
            this.descriptions.sort();
            this.ratesLoading = false;
            if (this.jobId !== 0) {
              this.selectClientRate();
            }
            this.beInProgress = false;
          })
          .catch((e) => {
            this.errors.push(e);
            this.ratesLoading = false;
            this.beInProgress = false;
          });
      },
      clientRateSelected(selectedClientRate) {
        if (this.job !== null && this.job.client_invoiceID !== null) {
          this.snackbar.color = "warning";
          this.snackbar.text = "Cannot change client after job is invoiced!";
          this.snackbar.visible = true;
        } else {
          this.selectedClientRate = selectedClientRate;
          this.checkTimes();
          if (this.job === null || this.job.contractor_ContractorID === null) {
            this.selectedContractorRate = {};
            this.searchContractor = "";
          }
        }
        this.beInProgress = false;
        this.$refs.job.validate();
      },

      loadContractorRates() {
        this.beInProgress = true;
        axios
          .get("/client_routes/contractor/rates/" + this.jobDate)
          .then((response) => {
            this.contractorRates = [];
            response.data.forEach((rate) => {
              rate.ContractorName = rate.PreferedName || rate.FirstName;
              this.contractorRates.push(rate);
            });
            this.selectedContractorRate = {};
            this.ratesLoading = false;
            if (this.jobId !== 0) {
              this.selectContractorRate();
            }
            this.beInProgress = false;
          })
          .catch((e) => {
            this.contractorRates = [];
            this.errors.push(e);
            this.ratesLoading = false;
            this.beInProgress = false;
          });
      },

      contractorRateSelected(selectedContractorRate) {
        if (this.job !== null && this.job.contractor_payslipID !== null) {
          this.snackbar.color = "warning";
          this.snackbar.text = "Cannot change contractor after job is paid!";
          this.snackbar.visible = true;
        } else {
          this.selectedContractorRate = selectedContractorRate;
          if (this.selectedContractorRate.retain_gst === 1) {
            this.gstLabel = "GST (retained)";
          } else {
            this.gstLabel = "GST";
          }
          this.checkTimes();
        }
        this.$refs.job.validate();
        this.beInProgress = false;
      },
      addJob() {
        let timeFlag = 0;
        if (this.hideTimes) {
          timeFlag = 1;
        }
        if (this.$refs.job.validate()) {
          let job = {
            Start_Date: this.jobDate,
            Start_Time: this.startHour + ":" + this.startMin,
            finish_date: this.jobDate,
            finish_time: this.finishHour + ":" + this.finishMin,
            DepotName: this.selectedClientRate.DepotName,
            client_vehicle_type:
              this.selectedClientRate.vehicle_type +
              " TV " +
              this.selectedClientRate.rate_description,
            client_rate: this.clientRate,
            client_subtotal: this.clientSubTotal,
            client_gst: this.clientGST,
            client_total: this.clientTotal,
            client_fuel_levy: this.clientFuelLevy,
            contractor_ContractorID: this.selectedContractorRate.ContractorID || '',
            contractor_name: this.selectedContractorRate.ContractorName || '',
            contractor_rego: this.selectedContractorRate.rego || '',
            contractor_vehicle_type: this.selectedContractorRate.vehicle_type || 0,
            contractor_rate: this.contractorRate,
            contractor_subtotal: this.contractorSubTotal,
            contractor_gst: this.contractorGST,
            contractor_total: this.contractorTotal,
            contractor_fuel_levy: this.contractorFuelLevy,
            job_desc: this.description,
            toll_charge: this.toll,
            hide_times: timeFlag,
            rate_type: this.rateType,
            // status <overall><times><contractor>
            // When contractor allocated status is 111
            // When contractor is not allocated status is 112
            // Client set times are not final times hence middle status is always 1
            status: this.selectedContractorRate.ContractorID ? 111 : 112
          };
          this.beInProgress = true;
          axios
            .post("/job", job)
            .then((res) => {
              if (res.status === 201) {
                this.snackbar.color = "success";
                this.snackbar.text = "Job ID: " + res.data.jobId + " added";
                this.snackbar.visible = true;
              }
            })
            .catch((e) => {
              this.errors.push(e);
              this.snackbar.color = "error";
              this.snackbar.text = this.errors.pop();
              this.snackbar.visible = true;
            });
        }
      },
      updateJob() {
        let timeFlag = 0;
        if (this.hideTimes) {
          timeFlag = 1;
        }
        if (this.$refs.job.validate()) {
          let job = {
            Start_Date: this.jobDate,
            Start_Time: this.startHour + ":" + this.startMin,
            finish_date: this.jobDate,
            finish_time: this.finishHour + ":" + this.finishMin,
            DepotName: this.DepotName,
            client_vehicle_type:
              this.selectedClientRate.vehicle_type +
              " TV " +
              this.selectedClientRate.rate_description,
            client_rate: this.clientRate,
            client_subtotal: this.clientSubTotal,
            client_gst: this.clientGST,
            client_total: this.clientTotal,
            client_fuel_levy: this.clientFuelLevy,
            client_invoiceID: this.job.client_invoiceID,
            contractor_ContractorID: this.selectedContractorRate.ContractorID,
            contractor_name: this.selectedContractorRate.ContractorName,
            contractor_rego: this.selectedContractorRate.rego,
            contractor_vehicle_type: this.selectedContractorRate.vehicle_type,
            contractor_rate: this.contractorRate,
            contractor_subtotal: this.contractorSubTotal,
            contractor_gst: this.contractorGST,
            contractor_total: this.contractorTotal,
            contractor_fuel_levy: this.contractorFuelLevy,
            contractor_payslipID: this.job.contractor_payslipID,
            job_desc: this.description,
            toll_charge: this.toll,
            hide_times: timeFlag,
            rate_type: this.rateType,
          };
          axios
            .put("/job/" + this.jobId, job)
            .then((res) => {
              if (res.status === 200) {
                this.snackbar.color = "success";
                this.snackbar.text = "Job ID: " + this.jobId + " updated";
                this.snackbar.visible = true;
                this.invoiceDetachDialog = false;
                this.payslipDetachDialog = false;
                this.loadJob();
              }
            })
            .catch((e) => {
              this.errors.push(e);
              this.snackbar.color = "error";
              this.snackbar.text = this.errors.pop();
              this.snackbar.visible = true;
              this.invoiceDetachDialog = false;
              this.payslipDetachDialog = false;
              this.loadJob();
            });
        }
      },

      confirmDeleteJob() {
        this.deleteJobDialog = true;
      },
      deleteJob() {
        axios
          .delete("/job/" + this.jobId)
          .then((res) => {
            if (res.status === 201) {
              this.snackbar.color = "success";
              this.snackbar.text = "Job ID: " + this.jobId + " deleted";
              this.snackbar.visible = true;
              this.invoiceDetachDialog = false;
              this.payslipDetachDialog = false;
              this.$router.push({ path: "/client_portal/dashboard" });
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.snackbar.color = "error";
            this.snackbar.text = this.errors.pop();
            this.snackbar.visible = true;
            this.invoiceDetachDialog = false;
            this.payslipDetachDialog = false;
            this.loadJob();
          });
      },
      formatToll() {
        const toll_str = parseFloat(this.toll).toFixed(2);
        if (toll_str != "NaN") {
          this.toll = toll_str;
        } else {
          this.toll = "0.00";
        }
      },
      loadJob() {
        axios
          .get("/job/" + this.jobId)
          .then((response) => {
            this.job = response.data[0];
            this.setJobData();
          })
          .catch((e) => {
            this.errors.push(e);
            this.$router.push;
            this.$router.push({ path: "/client_portal/dashboard" });
          });
      },
      setJobData() {
        this.jobDate = this.job.Start_Date;
        [this.startHour, this.startMin] = this.job.Start_Time.split(":", 2);
        [this.finishHour, this.finishMin] = this.job.finish_time.split(":", 2);
        this.hideTimes = this.job.hide_times;
        this.description = this.job.job_desc;
        this.DepotName = this.job.DepotName;
        this.vehicle = this.clientVehicle;
        this.toll = this.job.toll_charge;
        this.formatToll();
        if (this.job.rate_type === "flat") {
          this.percentage_day =
            Math.round(
              ((this.job.client_subtotal / this.job.client_rate) * 100) / 25
            ) * 25;
        } else if (this.job.rate_type === "piece") {
          this.pieces = Math.round(
            this.job.client_subtotal / this.job.client_rate
          );
        } else if (this.job.rate_type === "stop") {
          this.stops = Math.round(
            this.job.client_subtotal / this.job.client_rate
          );
        }
      },
      selectClientRate() {
        let BreakException = {};
        this.filteredClientRates.forEach((clientRate) => {
          if (
            clientRate.rate === this.job.client_rate &&
            clientRate.vehicle_type === this.clientVehicle.toString()
          ) {
            this.selectedClientRate = clientRate;
            throw BreakException;
          }
        });
      },
      selectContractorRate() {
        let contractorRateFound = false;
        this.filteredContractorRates.forEach((contractorRate) => {
          if (
            contractorRate.ContractorID === this.job.contractor_ContractorID &&
            contractorRate.vehicle_type === this.job.contractor_vehicle_type &&
            contractorRate.rego === this.job.contractor_rego
          ) {
            contractorRateFound = true;
            this.selectedContractorRate = contractorRate;
            this.searchContractor = this.job.contractor_ContractorID;
          }
        });
        if (!contractorRateFound) {
          this.showAllContractorRates = true;
          this.filteredContractorRates.forEach((contractorRate) => {
            if (
              contractorRate.ContractorID === this.job.contractor_ContractorID &&
              contractorRate.vehicle_type === this.job.contractor_vehicle_type &&
              contractorRate.rego === this.job.contractor_rego
            ) {
              this.selectedContractorRate = contractorRate;
              this.searchContractor = this.job.contractor_ContractorID;
            }
          });
        }
        this.checkTimes();
      },
      detachInvoice() {
        this.job.client_invoiceID = null;
        this.updateJob();
      },
      detachPayslip() {
        this.job.contractor_payslipID = null;
        this.updateJob();
      },
      fillHours() {
        let i = 0;
        for (i = 0; i < 24; i++) {
          this.hours.push(i.toString().padStart(2, "0"));
        }
      },
      fillMinutes() {
        let i = 0;
        for (i = 0; i < 60; i++) {
          this.mins.push(i.toString().padStart(2, "0"));
        }
      },
      downloadPdf(type, id) {
        this.loadingPdf = true;
        axios({
          method: "get",
          url: "pdf/" + type + "/" + id,
          responseType: "blob",
        })
          .then((response) => {
            this.forceFileDownload(response, type, id);
            this.loadingPdf = false;
          })
          .catch((err) => {
            console.log(err + " occured");
            this.loadingPdf = false;
          });
      },

      forceFileDownload(response, type, id) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let prefix = type === "payslip" ? "Payslip_" : "Invoice_";
        link.setAttribute("download", prefix + id + ".pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      },
      checkTimes() {
        if (this.selectedClientRate.rate_type === "flat") {
          this.startHour = "00";
          this.startMin = "00";
          this.finishHour = "00";
          this.finishMin = "00";
        }
      },
    },
  };
  </script>

  <style scoped>
  .theme--dark.v-data-table tbody tr.active {
    background: #a1caff;
  }

  .theme--light.v-data-table tbody tr.active {
    background: #a1caff;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.active {
    background: #a1caff;
  }
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.active {
    background: #a1caff;
  }

  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
    background: #a1caff;
  }
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
    background: #a1caff;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(
      .v-data-table__empty-wrapper
    ) {
    background: #a1caff;
  }

  .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(
      .v-data-table__empty-wrapper
    ) {
    background: #a1caff;
  }
  </style>
