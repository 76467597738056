<template>
  <div>
    <v-layout raw wrap align-center justify-center>
      <v-flex xs12 sm6 md3>
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          :nudge-right="0"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="startDateFormatted"
              label="From"
              persistent-hint
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            @input="startDateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 sm6 md3>
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="0"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="endDateFormatted"
              label="To"
              persistent-hint
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            no-title
            @input="endDateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 sm6 md3>
        <v-text-field
          v-model="searchPayslip"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>

    <v-data-table
      :items="PayslipList"
      :search="searchPayslip"
      :headers="PayslipListHeaders"
      :items-per-page="10"
    >
      <template v-slot:item="props">
        <tr :key="props.index" @click="payslipIDSelected(props.item.payslipID)">
          <td class="body-2">{{ props.item.payslipID }}</td>
          <td class="body-2">{{ props.item.Pay_Period }}</td>
          <td class="body-2">{{ props.item.PaymentDateFormatted }}</td>
          <td class="body-2">
            $ {{ parseFloat(props.item.total).toFixed(2) }}
          </td>
          <td class="body-2" v-if="props.item.paid">
            <v-icon color="green darken-3">done</v-icon>
          </td>
          <td class="body-2" v-if="!props.item.paid">
            <v-icon color="red darken-3">close</v-icon>
          </td>
          <td>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="downloadPdf(props.item.payslipID)"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="purple darken-4"
                  v-on="on"
                >
                  <v-icon dark>cloud_download</v-icon>
                </v-btn>
              </template>
              <span>Download: {{ props.item.payslipID }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="info" icon="info">No Payslips</v-alert>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      PayslipList: [],
      searchPayslip: "",

      endDateDT: new Date(),
      startDateDT: new Date(new Date().setDate(new Date().getDate() - 60)),
      startDate: "",
      endDate: "",
      startDateFormatted: "",
      endDateFormatted: "",
      startDateMenu: false,
      endDateMenu: false,

      errors: [],
      PayslipListHeaders: [
        {
          value: "payslipID",
          align: "left",
          sortable: true,
          text: "Payslip ID",
        },
        {
          value: "Pay_Period",
          align: "left",
          sortable: false,
          text: "Period",
        },

        {
          value: "PaymentDateFormatted",
          align: "left",
          sortable: false,
          text: "Pay Date",
        },
        {
          value: "total",
          align: "left",
          sortable: false,
          text: "Total",
        },

        {
          value: "paid",
          align: "left",
          sortable: false,
          text: "Paid",
        },
        {
          value: "download",
          align: "left",
          sortable: false,
          text: "Download",
        },
      ],
    };
  },
  created() {
    this.startDate = this.parseDate(this.startDateDT);
    this.endDate = this.parseDate(this.endDateDT);
    this.startDateFormatted = this.formatDate(this.startDate);
    this.endDateFormatted = this.formatDate(this.endDate);
  },
  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
      this.loadPayslip();
    },
    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
      this.loadPayslip();
    },
  },
  mounted() {
    this.loadContractor();
  },
  methods: {
    loadContractor() {
      axios
        .get("/contractor_routes/contractor")
        .then((response) => {
          this.contractor = response.data[0];
          // SQL name is different to variable name :(
          this.contractor.Licence = response.data[0].License;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      date = date.toLocaleDateString("en-AU");
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    loadPayslip() {
      axios
        .get(
          "/contractor_routes/payslip?startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate
        )
        .then((response) => {
          this.PayslipList = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    payslipIDSelected(selectedPayslipID) {
      this.payslipID = selectedPayslipID;
    },

    downloadPdf(currentPayslipID) {
      this.loadingPdf = true;
      axios({
        method: "get",
        url: "contractor_routes/payslip/" + currentPayslipID,
        responseType: "blob",
      })
        .then((response) => {
          this.forceFileDownload(response, currentPayslipID);
          this.loadingPdf = false;
        })
        .catch((err) => {
          console.log(err + " occured");
          this.loadingPdf = false;
        });
    },

    forceFileDownload(response, currentPayslipID) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Payslip_" + currentPayslipID + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
      //this.payslipID = 0;
    },
  },
};
</script>
