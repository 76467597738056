<template>
  <v-container id="companydetails" grid-list-md fluid>
    <v-layout row wrap>
      <v-flex d-flex align-start sm12 md12>
        <v-layout>
          <v-flex sm12 md12>
            <v-card>
              <v-card-title class="headline">Company Detail</v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Company Name"
                    id="CompName"
                    required:true
                    type="text"
                    v-model="compDetail.Company_Name"
                    placeholder="Required"
                  ></v-text-field>
                  <v-text-field
                    label="Address"
                    id="Address"
                    required:true
                    type="text"
                    v-model="compDetail.Address1"
                    placeholder="Required"
                  ></v-text-field>

                  <v-layout align-center justify-space-between>
                    <v-flex xs12 md4>
                      <v-text-field
                        label="Suburb"
                        id="Suburb"
                        required:true
                        type="text"
                        v-model="compDetail.Suburb"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field
                        label="State"
                        id="State"
                        required:true
                        type="text"
                        v-model="compDetail.State"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field
                        label="Postcode"
                        id="Postcode"
                        required:true
                        type="text"
                        v-model="compDetail.Postcode"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12 md4>
                      <v-text-field
                        label="Phone"
                        id="Phone"
                        required:true
                        type="text"
                        v-model="compDetail.phone"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field
                        label="Mobile"
                        id="Mobile"
                        required:true
                        type="text"
                        v-model="compDetail.mobile1"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field
                        label="Fax"
                        id="Fax"
                        required:true
                        type="text"
                        v-model="compDetail.Fax"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="email"
                        id="Email"
                        required:true
                        type="text"
                        v-model="compDetail.email1"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Website"
                        id="Website"
                        required:true
                        type="text"
                        v-model="compDetail.email2"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="ACN"
                        id="ACN"
                        required:true
                        type="text"
                        v-model="compDetail.ACN"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="ABN"
                        id="ABN"
                        required:true
                        type="text"
                        v-model="compDetail.ABN"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <!--v-flex>
                        <v-text-field label="logo" required:true type="text" v-model="compDetail.logo" placeholder="Required"></v-text-field>
                  </v-flex-->
                </v-form>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title class="headline">Banking Detail</v-card-title>
              <v-card-text>
                <v-form>
                  <!--label class="er">Banking Detail</label -->
                  <v-flex>
                    <v-text-field
                      label="Bank Name"
                      id="BankName"
                      required:true
                      type="text"
                      v-model="compDetail.bank_name"
                      placeholder="Required"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      label="Account Name"
                      id="AccountName"
                      required:true
                      type="text"
                      v-model="compDetail.account_name"
                      placeholder="Required"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      label="Short Account Name"
                      id="ShortAcctName"
                      required:true
                      type="text"
                      v-model="compDetail.account_name_short"
                      placeholder="Required"
                    ></v-text-field>
                  </v-flex>
                  <v-layout>
                    <v-flex>
                      <v-text-field
                        label="BSB"
                        id="BSB"
                        required:true
                        type="text"
                        v-model="compDetail.bsb"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Account Number"
                        id="AccountNumber"
                        required:true
                        type="text"
                        v-model="compDetail.account_number"
                        placeholder="Required"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" v-on:click="updateCompDetail">
                  Update
                  <v-icon right dark>save</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex d-flex align-start sm12 md6>
        <user-management />
      </v-flex> -->
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import Users from "./Users.vue";

export default {
  // components: {
  //   "user-management": Users,
  // },

  created() {
    //get first company detail
    this.loadCompDetail();
  },
  methods: {
    loadCompDetail: function () {
      //get first company detail
      axios
        .get(`/client/compDetail`)
        .then((response) => {
          this.compDetail = response.data[0];
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    updateCompDetail: function () {
      //update company detail
      axios
        .post(`/client/updateCompDetail`, this.compDetail)
        .then((response) => {
          if (response.status === 200) {
            alert("Company Detail is updated");
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
  },
  data() {
    return {
      //declare attributes
      errors: [],

      //declare array attributes
      compDetail: {},
      users: [],
      bankDetail: {
        bankName: "",
        accountName: "",
        shortAcctName: "",
        bsb: "",
        accountNumber: "",
      },
    };
  },
};
</script>
<style>
label.er {
  font-size: 150%;
  color: gray;
}
</style>
