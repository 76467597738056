<template>
  <v-layout row wrap>
    <v-flex flex-column xs12 sm12 md12>
      <v-card>
        <v-card-title class="headline" title--text>
          {{ $route.params.id }} Vehicles
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-if="vehicles[0]"
            :headers="vechicleHeaders"
            :items="vehicles"
          >
            <template v-slot:item="props">
              <tr
                @click="vehicleSelected(props.item)"
                :class="{
                  active:
                    !!selectedVehicle &&
                    selectedVehicle.rego == props.item.rego,
                }"
              >
                <td align="right">{{ props.item.rego }}</td>
                <td>{{ props.item.vehicle_type }} Tons</td>
              </tr>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning"
                >No vehicles found for contractor:
                {{ $route.params.id }}</v-alert
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
    <rates :contractorID="contractorID" :rego="selectedVehicle.rego"></rates>
  </v-layout>
</template>

<script>
import axios from "axios";
import Rates from "./Rates.vue";

export default {
  components: {
    rates: Rates,
  },
  props: ["contractorID"],

  data() {
    return {
      vehicles: [],
      vehiclePagination: {
        rowsPerPage: 5,
        sortBy: "Rego",
      },
      selectedVehicle: {},
      vechicleHeaders: [
        {
          value: "rego",
          align: "end",
          sortable: true,
          text: "Rego",
        },
        {
          value: "vehicle_type",
          align: "left",
          sortable: true,
          text: "Type",
        },
      ],
      errors: [],
    };
  },

  computed: {
    formTitle() {
      return this.editedVechileIndex === -1 ? "Add Vehicle" : "Edit Vehicle";
    },
    computedDateFormatted() {
      return this.formatDate(this.editedVehicle.rego_date);
    },
  },

  created() {
    this.loadVehicles();
  },
  methods: {
    loadVehicles() {
      axios
        .get(`/contractor/${this.contractorID}/vehicles`)
        .then((response) => {
          this.vehicles = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    vehicleSelected(selctedVehicle) {
      this.selectedVehicle = selctedVehicle;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
.theme--dark.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.active {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.active {
  background: #a1caff;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #a1caff;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}
</style>
