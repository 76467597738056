<template>
  <v-container>
    <v-responsive class="d-flex align-center fill-height">
      <v-flex xs12 sm12 md12>
        <v-layout raw wrap align-center justify-start>
          <h1>Welcome to Courier Management System</h1>
        </v-layout>
        <blockquote class="blockquote">
          Please contact your administrator for an account
        </blockquote>
      </v-flex>
      <div class="pa-10" />
        <v-layout raw wrap align-start justify-center>
          <h2> Contractor Apps</h2>
        </v-layout>
        <v-layout raw wrap align-start justify-center>
          <v-flex md3>
            <a href="https://apps.apple.com/us/app/vht-courier/id6478838829">
              <v-img width="40%" src="appstore.webp"></v-img>
            </a>
          </v-flex>
          <v-flex md3>
            <a href="https://play.google.com/store/apps/details?id=au.net.vht.courier">
              <v-img width="40%" src="playstore.png"></v-img>
            </a>
            <p class="text-caption"><b>Please note:</b> Android app is still undergoing google review.
              However, it is available for users on trial list
            We'd love you to join the trial. Send us the email id you
              use on Google Play Store to <a href="mailto:support@vht.net.au">support@vht.net.au</a> and we will
              be able to add you to trial list
            Once you have been added to trial list, you can download the app from Play Store</p>
          </v-flex>
        </v-layout>

    </v-responsive>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style></style>
