import { render, staticRenderFns } from "./UnapproveLeave.vue?vue&type=template&id=7db46815&scoped=true"
import script from "./UnapproveLeave.vue?vue&type=script&lang=js"
export * from "./UnapproveLeave.vue?vue&type=script&lang=js"
import style0 from "./UnapproveLeave.vue?vue&type=style&index=0&id=7db46815&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7db46815",
  null
  
)

export default component.exports