<template>
  <v-container id="contractor_home" grid-list-md fluid>
    <v-layout raw wrap align-center>
      <v-flex xs12 sm12 md12>
        <v-card>
          <v-card-title class="headline" title--text>
            Calendar
            <v-spacer />
            Welcome {{ contractor.FirstName }} {{ contractor.LastName }}
          </v-card-title>
          <v-card-text>
            <contractor-calendar></contractor-calendar>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md12>
        <v-card>
          <v-card-title class="headline" title--text> Payslips </v-card-title>
          <v-card-text>
            <contractor-payslips></contractor-payslips>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import ContractorPayslips from "./ContractorPayslips.vue";
import ContractorCalendar from "./ContractorCalendar.vue";

export default {
  data() {
    return {
      contractor: {},
      contractorDialog: false,
      errors: []
    };
  },
  components: {
    "contractor-payslips": ContractorPayslips,
    ContractorCalendar: ContractorCalendar,
  },

  mounted() {
    this.loadContractor();
  },
  methods: {
    loadContractor() {
      axios
        .get("/contractor_routes/contractor")
        .then((response) => {
          this.contractor = response.data[0];
          // SQL name is different to variable name :(
          this.contractor.Licence = response.data[0].License;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    contractorUpdated(contractor) {
      if (contractor) {
        this.contractorDialog = false;
        this.snackbar.color = "success";
        this.snackbar.text = "Details Updated";
        this.snackbar.visible = true;
      }
    },
  },
};
</script>

<style></style>
