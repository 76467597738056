var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(" Unapproved Leave ")]),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.leaveHeaders,"items":_vm.unapprovedleaves},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{staticClass:"pa-0"},[_c('td',{staticClass:"body-2",class:{ 'grey--text': props.item.Inactive }},[_vm._v(" "+_vm._s(props.item.ContractorID)+" ")]),_c('td',{staticClass:"body-2",class:{ 'grey--text': props.item.Inactive }},[_vm._v(" "+_vm._s(props.item.contractorName)+" ")]),_c('td',{staticClass:"body-2",class:{ 'grey--text': props.item.Inactive }},[_vm._v(" "+_vm._s(props.item.formattedStartDate)+" ")]),_c('td',{staticClass:"body-2",class:{ 'grey--text': props.item.Inactive }},[_vm._v(" "+_vm._s(props.item.formattedEndDate)+" ")]),_c('td',[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.approveLeave(
                  props.item.leaveID,
                  props.item.ContractorID,
                  props.item.formattedStartDate,
                  props.item.formattedEndDate
                )}}},[_vm._v("thumb_up")]),_c('v-icon',{on:{"click":function($event){return _vm.declineLeave(
                  props.item.leaveID,
                  props.item.ContractorID,
                  props.item.formattedStartDate,
                  props.item.formattedEndDate
                )}}},[_vm._v("thumb_down")])],1)])]}}])})],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"timeout":_vm.snackbar.timeout},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }