<template>
  <v-flex xs12 sm6 md4 lg3>
    <v-toolbar color="blue accent-1">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-progress-linear
        :active="loadJobsInProgress"
        indeterminate
        absolute
        bottom
        color="blue accent-4"
      ></v-progress-linear>
    </v-toolbar>

    <v-list four-line dense>
      <template v-for="job in jobs">
        <v-list-item :key="job.title" @click="modifyJob(job.JobID)" ripple :class="jobColor(job)" class="my-1">
          <v-list-item-content>
            <v-list-item-title>{{ job.contractor_ContractorID || "UNASSIGNED" }}
              {{ job.contractor_name || '' }}</v-list-item-title>
            <v-list-item-subtitle class="body-2 text--primary">{{ times(job) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="body-2 text--primary">
              {{ job.client_vehicle_type }} /
              {{ job.contractor_vehicle_type }} TV
            </v-list-item-subtitle>
            <v-list-item-subtitle>
            <v-chip small outlined color="teal darken-2" v-if="job.client_invoiceID"><v-icon small left>receipt</v-icon>
              {{ job.client_invoiceID }}</v-chip>
            <v-chip small outlined color="purple darken-4" v-if="job.contractor_payslipID"><v-icon small
                left>payment</v-icon>{{ job.contractor_payslipID }}</v-chip>
              </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>

            <v-icon v-if="job.status % 10 === 2" color="red darken-3">local_shipping</v-icon>
            <v-icon v-if="job.status % 10 === 1" color="orange darken-3">local_shipping</v-icon>
            <v-icon v-if="job.status % 10 === 0" color="green darken-3">local_shipping</v-icon>

            <v-icon v-if="job.status === 0" color="green darken-3">done_all</v-icon>
            <v-icon v-if="job.status != 0" color="orange darken-3">schedule</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false">Close</v-btn>
      </template>
    </v-snackbar>
</v-flex>
</template>

<script>
import axios from "axios";
export default {
  props: {
    client: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      jobs: [],
      errors: [],
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
      interval: 0,
      loadJobsInProgress: false,
      loadJobsRequest: 0,
      loadAvailableContractorsRequest: 0
    };
  },

  watch: {
    title: function () {
      this.loadJobs();
    },
    client: function () {
      this.loadJobs();
    },
  },

  created() {
    this.loadJobs();
  },

  mounted() {
    this.loadJobs();
    this.interval = setInterval(function () {
      this.loadJobs();
    }.bind(this), 30000);
  },
  destroyed() {
    clearInterval(this.interval)
  },

  methods: {
    loadJobs() {
      this.cancelLoadJobs();
      const startDate = this.sqlDate(this.title)
      const endDate = this.sqlDate(this.title)

      let axiosSource = axios.CancelToken.source();
      this.loadJobsRequest = { cancel: axiosSource.cancel };
      this.loadJobsInProgress = true;
      axios
        .get(
          "/job?client=" +
          this.client +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate, { cancelToken: axiosSource.token, }
        )
        .then((response) => {
          this.jobs = response.data;
          this.loadJobsInProgress = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loadJobsInProgress = false;
        });
    },

    cancelLoadJobs() {
      if (this.loadJobsRequest) {
        this.loadJobsRequest.cancel();
      }
    },

    times(job) {
      let start_time = this.chopSeconds(job.Start_Time);
      let finish_time = this.chopSeconds(job.finish_time);
      if (job.rate_type === "flat") {
        let percentage_day =
          Math.round(((job.client_subtotal / job.client_rate) * 100) / 25) * 25;
        return "Flat: " + percentage_day + "%";
      } else if (job.rate_type === "piece") {
        let pieces = Math.round(job.client_subtotal / job.client_rate);
        return "Pieces: " + pieces;
      } else if (job.rate_type === "stop") {
        let stops = Math.round(job.client_subtotal / job.client_rate);
        return "Stops: " + stops;
      } else {
        return "Hourly: " + start_time + " to " + finish_time;
      }
    },
    chopSeconds(time) {
      return time.split(":", 2).join(":");
    },
    modifyJob(JobID) {
      this.$router.push({ path: `/job/${JobID}` });
    },
    jobColor(job) {
      if (job.rate_type === "flat") {
        return "flat_rate";
      } else if (job.rate_type === "piece") {
        return "piece_rate";
      } else if (job.rate_type === "stop") {
        return "stop_rate";
      } else {
        return "hourly_rate";
      }
    },
    sqlDate(dateString) {
      let date = new Date(dateString);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      return year + "-" + month + "-" + day;
    },

    finaliseJob(JobID) {
      axios.put("/job/finalise/" + JobID)
        .then((res) => {
          if (res.status === 201) {
            this.snackbar.color = "success";
            this.snackbar.text = `Job ${JobID} finalised`;
            this.snackbar.visible = true;
            this.invoiceDetachDialog = false;
            this.payslipDetachDialog = false;
            this.loadJobs();
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.snackbar.color = "error";
          this.snackbar.text = this.errors.pop();
          this.snackbar.visible = true;
          this.invoiceDetachDialog = false;
          this.payslipDetachDialog = false;
        })

    }
  },
};
</script>

<style scoped>
.flat_rate {
  background-color: #e8f5e9;
}

.piece_rate {
  background-color: #ede7f6;
}

.stop_rate {
  background-color: #f6f0bb;
}

.hourly_rate {
  background-color: #e3f2fd;
}
</style>
