<template>
  <v-container id="contractors" grid-list-md fluid>
    <v-dialog v-model="contractorDialog" persistent max-width="920">
      <contractor-form
        :dialog="contractorDialog"
        :newContractor="true"
        @onCloseDialog="contractorAdded"
      ></contractor-form>
    </v-dialog>

    <v-card>
      <v-card-title class="headline" title--text>
        Contractors
        <v-spacer></v-spacer>
        <v-switch
          inset
          v-model="showInactive"
          @change="loadContractors"
          label="Show Inactive"
        ></v-switch>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn @click="contractorDialog = true" fab dark small color="indigo">
          <v-icon dark>add</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="contractors"
        :search="search"
        sortBy="ContractorID"
        :loading="loadingContractor"
        :footer-props="{
          'items-per-page-options': [15, 30, 60, 100, -1],
        }"
      >
        <template v-slot:item="props">
          <tr
            class="pa-0"
            :active="
              !!selected && selected.ContractorID == props.item.ContractorID
            "
            @click="contractorSelected(props.item)"
          >
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.ContractorID }}
            </td>
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.FirstName }}
            </td>
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.PreferedName }}
            </td>
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.LastName }}
            </td>
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.Address1 }} {{ props.item.Address2 }},
              {{ props.item.Suburb }}, {{ props.item.State }}
              {{ props.item.Postcode }}
            </td>
          </tr>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import Form from "./Form.vue";

export default {
  components: {
    "contractor-form": Form,
  },
  data() {
    return {
      pagination: {
        rowsPerPage: 15,
        sortBy: "ContractorID",
      },
      selected: {},
      search: "",
      showInactive: false,
      contractorDialog: false,
      headers: [
        {
          value: "ContractorID",
          align: "left",
          sortable: true,
          text: "Contractor ID",
        },
        {
          value: "FirstName",
          align: "left",
          sortable: true,
          text: "First Name",
        },
        {
          value: "PreferedName",
          align: "left",
          sortable: true,
          text: "Prefered Name",
        },
        { value: "LastName", align: "left", sortable: true, text: "Last Name" },
        { value: "Address1", align: "left", sortable: false, text: "Address" },
      ],
      contractors: [],
      loadingContractor: false,
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
    };
  },

  created() {
    this.loadContractors(false);
  },

  computed: {
    role() {
      return !this.$store.getters.user
        ? "NOROLE"
        : this.$store.getters.user.role;
    },
  },

  methods: {
    loadContractors(showInactive) {
      this.loadingContractor = true;
      axios
        .get(`/contractor`)
        .then((response) => {
          if (showInactive == false) {
            this.contractors = response.data.filter(
              (contractor) => contractor.Inactive == 0
            );
          } else {
            this.contractors = response.data;
          }
          this.totalRows = this.contractors.length;
          this.loadingContractor = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loadingContractor = false;
        });
    },
    contractorSelected(selctedContractor) {
      this.selected = selctedContractor;
      if (this.role === "admin" || this.role === "user") {
        this.$router.push({
          path: `/contractors/${this.selected.ContractorID}`,
        });
      } else if (this.role === "ruser") {
        this.$router.push({
          path: `/contractors/ro/${this.selected.ContractorID}`,
        });
      }
    },
    contractorAdded(contractor) {
      if (contractor) {
        this.contractorDialog = false;
        this.snackbar.color = "success";
        this.snackbar.text = "Contractor: " + contractor + " added";
        this.snackbar.visible = true;
        this.loadContractors(this.showInactive);
      }
      else {
        // Form is dismissed without adding a new contractor
        this.contractorDialog = false
      }
    },
  },
};
</script>

<style></style>
