import "chart.js/auto";
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["data", "options"],
  data() {
    return {
      defaultOptions: {
        responsive: true, // my new default options
        maintainAspectRatio: false, // my new default options
      },
    };
  },
};
