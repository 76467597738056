<template>
  <v-card color="cyan accent-4" class="pa-1" elevation="10">
    <v-sheet class="ma-3" color="cyan accent-4">
      <p class="title text-center white--text">Top 10 Contractors</p>
      <pie-chart
        :height="180"
        v-if="dataReady"
        :data="chartData"
        :options="chartOptions"
      ></pie-chart>
    </v-sheet>
  </v-card>
</template>

<script>
import axios from "axios";

import PieChart from "./PieChart.js";
export default {
  name: "App",
  components: {
    PieChart,
  },

  props: ["startDate", "endDate"],
  watch: {
    startDate() {
      this.fillData();
    },
    endDate() {
      this.fillData();
    },
  },

  data() {
    return {
      dataReady: false,
      chartOptions: {
        plugins: {
          hoverBorderWidth: 20,
          legend: {
            labels: {
              color: "#FFFFFF",
              boxWidth: 20,
            },

            position: "left",
          },
          tooltips: {
            bodyFontSize: 18,
            callbacks: {
              label: function (tooltipItems, data) {
                let total = data.datasets[0].data[tooltipItems.index];
                total = total.toFixed(2);
                total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return " " + data.labels[tooltipItems.index] + ": $" + total;
              },
            },
          },
        },
      },
      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [],
        datasets: [
          {
            label: "Top Contractors",
            borderWidth: 1,
            backgroundColor: [],
            data: [],
          },
        ],
      },
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    getRandomColor() {
      var letters = "0123456789ABCDEF".split("");
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    fillData() {
      this.resetData();
      this.dataReady = false;
      axios
        .get(
          `/summary/topcontractors?startDate=${this.startDate}&endDate=${this.endDate}`
        )
        .then((response) => {
          response.data.forEach((contractor) => {
            this.chartData.labels.push(contractor.ContractorId);
            this.chartData.datasets[0].backgroundColor.push(
              this.getRandomColor()
            );
            this.chartData.datasets[0].data.push(contractor.total);
          });
          this.dataReady = true;
        });
    },
    resetData() {
      this.chartData.labels = [];
      this.chartData.datasets[0].backgroundColor = [];
      this.chartData.datasets[0].data = [];
    },
  },
};
</script>

<style></style>
