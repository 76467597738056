<template>
  <v-container id="InvoiceRetrieval" grid-list-md fluid>
    <v-flex xs12 sm12 md12>
      <v-card>
        <v-card-title class="headline" title--text
          >Retrieve Invoice</v-card-title
        >
        <v-card-text>
          <v-form>
            <v-layout raw wrap align-center justify-center>
              <v-spacer />
              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedStartDateFormatted"
                      label="From"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    @input="startDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedEndDateFormatted"
                      label="To"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    no-title
                    @input="endDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md3>
                <v-text-field
                  v-model="searchInvoice"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-spacer />
              <v-flex xs6 sm3 md2>
                <v-switch
                  v-model="showUnpaidOnly"
                  label="Unpaid Only"
                  @change="loadInvoice"
                  inset
                ></v-switch>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-form>

          <v-flex xs12 sm12 md12>
            <v-data-table
              :items="InvoiceList"
              :search="searchInvoice"
              :headers="InvoiceListHeaders"
              :items-per-page="10"
            >
              <template v-slot:item="props">
                <tr :key="props.index" :class="checkPaid(props.item)">
                  <td class="body-2">{{ props.item.InvoiceID }}</td>
                  <td class="body-2">{{ props.item.CreateDateFormatted }}</td>
                  <td class="body-2">
                    {{ props.item.Client_Name }} | {{ props.item.Name }}
                  </td>
                  <td class="body-2" align="right">
                    $ {{ parseFloat(props.item.Subtotal).toFixed(2) }}
                  </td>
                  <td class="body-2" align="right">
                    $ {{ parseFloat(props.item.GST).toFixed(2) }}
                  </td>
                  <td class="body-2" align="right">
                    $ {{ parseFloat(props.item.Total).toFixed(2) }}
                  </td>
                  <td class="body-2" align="right">
                    $ {{ parseFloat(props.item.Amount_Paid).toFixed(2) }}
                  </td>
                  <td align="center">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="downloadPdf(props.item.InvoiceID)"
                          class="mx-2"
                          fab
                          dark
                          small
                          color="teal darken-2"
                          v-on="on"
                          depressed
                        >
                          <v-icon dark>cloud_download</v-icon>
                        </v-btn>
                      </template>
                      <span>Download: {{ props.item.InvoiceID }}</span>
                    </v-tooltip>
                  </td>
                  <td align="center">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="downloadtscsv(props.item.InvoiceID)"
                          class="mx-2"
                          fab
                          dark
                          small
                          color="blue darken-3"
                          v-on="on"
                          :disabled="
                            !tradeshiftClients.includes(props.item.Client_Name)
                          "
                          depressed
                        >
                          <v-icon dark>cloud_download</v-icon>
                        </v-btn>
                      </template>
                      <span>Download: {{ props.item.InvoiceID }}</span>
                    </v-tooltip>
                  </td>
                  <td align="center">
                    <v-switch
                      :input-value="props.item.Total == props.item.Amount_Paid"
                      :label="
                        props.item.Total == props.item.Amount_Paid
                          ? 'Paid'
                          : 'Unpaid'
                      "
                      readonly
                      @click="updatePaidFlag(props.item)"
                      inset
                    ></v-switch>
                  </td>
                  <td
                    align="center"
                    :class="
                      calculateDue(
                        props.item.CreateDateFormatted,
                        props.item.Terms
                      ) < 0
                        ? 'overdue'
                        : ''
                    "
                  >
                    <div v-if="props.item.Total != props.item.Amount_Paid">
                      {{
                        calculateDue(
                          props.item.CreateDateFormatted,
                          props.item.Terms
                        )
                      }}
                      Day(s)
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" color="info" icon="info"
                  >No Invoices</v-alert
                >
              </template>
            </v-data-table>
          </v-flex>

          <v-card-actions>
            <v-spacer></v-spacer>
            <!--v-btn :disabled="invoiceID === 0" :dark="invoiceID !== 0" @click="downloadPdf" right color="green">Download PDF</v-btn-->
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import router from "../../router";

export default {
  data() {
    return {
      settings: require("../../../settings.json"),
      error: [],
      /*startDate: new Date().toISOString().substr(0, 10),
      startDateFormatted: this.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      endDate: new Date().toISOString().substr(0, 10),
      endDateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),*/
      startDate: this.parseDate(new Date().toLocaleDateString("en-AU")),
      startDateFormatted: new Date().toLocaleDateString("en-AU"),
      endDate: this.parseDate(new Date().toLocaleDateString("en-AU")),
      endDateFormatted: new Date().toLocaleDateString("en-AU"),

      startDateMenu: false,
      endDateMenu: false,

      invoiceID: 0,
      InvoiceList: [],

      searchInvoice: "",
      showUnpaidOnly: false,
      InvoiceListHeaders: [
        {
          value: "InvoiceID",
          align: "left",
          sortable: true,
          text: "Invoice ID",
        },
        {
          value: "CreateDateFormatted",
          align: "left",
          sortable: true,
          text: "Date",
        },
        {
          value: "Client_Name",
          align: "left",
          sortable: false,
          text: "Client",
        },
        {
          value: "Subtotal",
          align: "left",
          sortable: false,
          text: "Subtotal",
        },

        {
          value: "GST",
          align: "left",
          sortable: true,
          text: "GST",
        },

        {
          value: "Total",
          align: "left",
          sortable: false,
          text: "Total",
        },

        {
          value: "Amount_Paid",
          align: "start",
          sortable: false,
          text: "Amount Paid",
        },

        {
          value: "Download",
          align: "center",
          sortable: false,
          text: "PDF",
        },
        {
          value: "Tradeshift",
          align: "left",
          sortable: false,
          text: "Tradeshift",
        },
        {
          value: "Payment",
          align: "center",
          sortable: false,
          text: "Payment",
        },
        {
          value: "due",
          align: "center",
          sortable: false,
          text: "Due",
        },
      ],

      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },

      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      errors: [],
    };
  },

  created() {
    this.loadInvoice(false);
  },

  computed: {
    computedStartDateFormatted() {
      return this.formatDate(this.startDate);
    },
    computedEndDateFormatted() {
      return this.formatDate(this.endDate);
    },
    tradeshiftClients() {
      return Object.keys(this.settings.tradeshift.clients);
    },
  },

  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
      this.loadInvoice();
    },
    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
      this.loadInvoice();
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    loadInvoice(showUnpaidOnly) {
      axios
        .get(
          "/invoice/retrieveInvoice?startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate
        )
        .then((response) => {
          if (showUnpaidOnly) {
            this.InvoiceList = response.data.filter(
              (invoice) => invoice.Total != invoice.Amount_Paid
            );
          } else {
            this.InvoiceList = response.data;
          }
        })
        .catch((e) => {
          this.error.push(e);
        });
    },

    invoiceIDSelected(selectedInvoiceID) {
      this.invoiceID = selectedInvoiceID;
    },

    downloadPdf(currentInvoiceID) {
      //this.invoiceID = currentInvoiceID;

      this.loadingPdf = true;
      axios({
        method: "get",
        url: "pdf/invoice/" + currentInvoiceID,
        responseType: "blob",
      })
        .then((response) => {
          this.forceFileDownload(response, currentInvoiceID, "pdf");
          this.loadingPdf = false;
        })
        .catch((err) => {
          console.log(err + " occured");
          this.loadingPdf = false;
        });
    },

    downloadtscsv(currentInvoiceID) {
      //this.invoiceID = currentInvoiceID;
      console.log(this.tradeshiftClients);

      axios({
        method: "get",
        url: "tradeshiftcsv/" + currentInvoiceID,
        responseType: "blob",
      })
        .then((response) => {
          this.forceFileDownload(response, currentInvoiceID, "csv");
        })
        .catch((err) => {
          console.log(err + " occured");
        });
    },

    forceFileDownload(response, currentInvoiceID, type = "pdf") {
      if (type === "csv") {
        const url = window.URL.createObjectURL(
          new Blob(["\ufeff", response.data])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Tradeshift_invoice_" + currentInvoiceID + ".csv"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else if (type === "pdf") {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Invoice_" + currentInvoiceID + ".pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    },

    checkPaid(item) {
      return item.Total == item.Amount_Paid ? "" : "unpaid";
    },
    updatePaidFlag(invoice) {
      let amount_paid = {};
      if (invoice.Total == invoice.Amount_Paid) {
        amount_paid = { Amount_Paid: 0 };
      } else {
        amount_paid = { Amount_Paid: invoice.Total };
      }

      axios
        .put("/invoice/amountpaid/" + invoice.InvoiceID, amount_paid)
        .then((res) => {
          if (res.status === 201) {
            this.snackbar.color = "success";
            this.snackbar.text = "Invoice: " + res.data.InvoiceID + " updated";
            this.snackbar.visible = true;
            this.loadInvoice(this.showUnpaidOnly);
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.snackbar.color = "error";
          this.snackbar.text = this.errors.pop();
          this.snackbar.visible = true;
        });
    },

    calculateDue(date, terms) {
      let invoiceDate = new Date(date);
      let today = new Date();
      let diff = Math.round(
        terms - (today - invoiceDate) / (24 * 60 * 60 * 1000)
      );
      return diff;
    },
  },
};
</script>

<style scoped>
.theme--dark.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.active {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.active {
  background: #a1caff;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #a1caff;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}

.unpaid {
  background-color: #fce4ec;
}

.overdue {
  color: red;
  font-weight: bold;
}
</style>
