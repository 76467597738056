<template>
  <v-layout>
    <v-flex sm12 md12>
      <v-card>
        <v-card-title v-if="!newContractor" class="headline" title--text
          >Contractor: {{ contractorID }}</v-card-title
        >
        <v-card-title v-if="newContractor" class="headline" title--text>
          Add Contractor
          <v-spacer></v-spacer>
          <v-btn fab dark small color="error" @click="dismissForm">
            <v-icon dark>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="contractor" :key="forceUpdate">
            <v-layout>
              <v-flex xs12 md4>
                <v-text-field
                  label="First Name"
                  v-model="contractor.FirstName"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  label="Prefered Name"
                  v-model="contractor.PreferedName"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  label="Last Name"
                  v-model="contractor.LastName"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-text-field
              label="Address 1"
              v-model="contractor.Address1"
            ></v-text-field>
            <v-text-field
              label="Address 2"
              v-model="contractor.Address2"
            ></v-text-field>
            <v-layout>
              <v-flex xs12 md4>
                <v-text-field
                  label="Suburb"
                  v-model="contractor.Suburb"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-select
                  v-model="contractor.State"
                  :items="states"
                  label="State"
                ></v-select>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  label="Postcode"
                  v-model="contractor.Postcode"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center justify-space-between>
              <v-flex xs12 md3>
                <v-text-field
                  label="Phone"
                  v-model="contractor.HomePhone"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  label="Mobile"
                  v-model="contractor.Mobile"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Email"
                  v-model="contractor.Email"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center justify-space-between>
              <v-flex xs12 md6>
                <v-text-field
                  label="Licence"
                  v-model="contractor.Licence"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Raca"
                  v-model="contractor.Raca"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center>
              <v-flex xs12 md6>
                <v-text-field
                  label="ABN"
                  v-model="contractor.ABN"
                  read-only
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Company Name"
                  v-model="contractor.CompanyName"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center raw fill-height>
              <v-flex xs12 md6>
                <v-text-field
                  label="Bank Name"
                  v-model="contractor.BankName"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Account Name"
                  v-model="contractor.AccountName"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout align-center raw fill-height>
              <v-flex xs12 md6>
                <v-text-field
                  label="BSB"
                  v-model="contractor.BSB"
                  :rules="[rules.bsb]"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Account No"
                  v-model="contractor.AccountNo"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center justify-space-between>
              <v-flex xs12 md4>
                <v-menu
                  v-model="DOBDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="DOBFormatted"
                      label="Date of Birth"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="contractor.DOB"
                    no-title
                    @input="DOBDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 md4>
                <v-menu
                  v-model="DOCDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="DOCFormatted"
                      label="Date of Commencement"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="contractor.DOC"
                    no-title
                    @input="DOCDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-flex xs12 md4>
            <v-btn
              @click="resetPassword(contractorID)"
              v-if="!newContractor"
              color="deep-orange"
              dark
            >
              Password Reset
              <v-icon right dark>lock_reset</v-icon>
            </v-btn>
          </v-flex>
          <v-spacer></v-spacer>
          <v-layout align-center>
            <v-flex xs12 md4>
              <v-switch
                inset
                v-model="contractor.retain_gst"
                label="Retain GST"
              ></v-switch>
            </v-flex>
            <v-flex xs12 md4>
              <v-switch
                inset
                v-model="contractor.Inactive"
                label="Inactive"
              ></v-switch>
            </v-flex>
          </v-layout>
          <v-btn
            @click="addContractor"
            v-if="newContractor && !signup"
            color="success"
            dark
          >
            Add
            <v-icon right dark>save</v-icon>
          </v-btn>
          <v-btn
            @click="updateContractor"
            v-if="!newContractor"
            color="success"
            dark
          >
            Update
            <v-icon right dark>save</v-icon>
          </v-btn>
          <v-btn
            @click="signup"
            v-if="newContractor && signup"
            color="success"
            dark
          >
            Update
            <v-icon right dark>save</v-icon>
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-flex>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-layout>
</template>

<script>
import axios from "axios";

export default {
  props: {
    dialog: Boolean,
    contractorID: String,
    newContractor: Boolean,
    signup: Boolean
  },

  data() {
    return {
      settings: require("../../../settings.json"),
      contractor: {},
      states: ["VIC", "NSW", "ACT", "TAS", "QLD", "SA", "WA", "NT"],
      DOBDateMenu: false,
      DOCDateMenu: false,
      Password: "",
      ConfirmPassword: "",
      forceUpdate: 0,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        password: (value) => value === this.Password || "Password not matching",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        bsb: () => {
          let patt = new RegExp("^\\d{3}-\\d{3}$");
          if (this.contractor.BSB) {
            this.contractor.BSB = this.contractor.BSB.replace(/[^0-9-]/g, "");
            this.contractor.BSB = this.contractor.BSB.replace(
              /(\d{3})(\d{3})/,
              "$1-$2"
            );
          }
          return (
            !this.contractor.BSB ||
            patt.test(this.contractor.BSB) ||
            "Invalid BSB"
          );
        },
      },
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },

      errors: [],
    };
  },

  watch: {
    dialog() {
      this.resetContractor();
      this.$refs.contractor.resetValidation();
      this.autoGenerateId();
    },
  },
  mounted() {
    if (this.contractorID) {
      this.loadContractor();
    } else {
      this.resetContractor();
      this.autoGenerateId();
    }
  },
  computed: {
    DOBFormatted() {
      return this.formatDate(this.contractor.DOB);
    },
    DOCFormatted() {
      return this.formatDate(this.contractor.DOC);
    },
  },

  methods: {
    loadContractor() {
      axios
        .get("/contractor/" + this.contractorID)
        .then((response) => {
          this.contractor = response.data[0];
          // SQL name is different to variable name :(
          this.contractor.Licence = response.data[0].License;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    addContractor() {
      if (this.$refs.contractor.validate()) {
        let contractor = {
          FirstName: this.contractor.FirstName,
          PreferedName: this.contractor.PreferedName || "",
          LastName: this.contractor.LastName,
          Password: this.Password,
          Address1: this.contractor.Address1 || "",
          Address2: this.contractor.Address2 || "",
          Suburb: this.contractor.Suburb || "",
          State: this.contractor.State || "",
          Postcode: this.contractor.Postcode || "",
          HomePhone: this.contractor.HomePhone || "",
          Mobile: this.contractor.Mobile || "",
          Email: this.contractor.Email || "",
          Licence: this.contractor.Licence || "",
          Raca: this.contractor.Raca || "",
          ABN: this.contractor.ABN || "",
          CompanyName: this.contractor.CompanyName || "",
          BankName: this.contractor.BankName || "",
          AccountName: this.contractor.AccountName || "",
          BSB: this.contractor.BSB || "",
          AccountNo: this.contractor.AccountNo || "",
          DOB: this.contractor.DOB || "1970-01-01",
          DOC: this.contractor.DOC || "1970-01-01",
          retain_gst: this.contractor.retain_gst,
          Inactive: this.contractor.Inactive,
        };

        axios
          .post("/contractor", contractor)
          .then((res) => {
            if (res.status === 201) {
              this.resetPassword(res.data.ContractorID);
              this.resetContractor();
              this.$emit("onCloseDialog", res.data.ContractorID);
            } else {
              console.log(res.data.sqlMessage);
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.snackbar.color = "error";
            this.snackbar.text = this.errors.pop();
            this.snackbar.visible = true;
          });
      }
    },

    updateContractor() {
      if (this.$refs.contractor.validate()) {
        let contractor = {
          ContractorID: this.contractor.ContractorID,
          FirstName: this.contractor.FirstName,
          PreferedName: this.contractor.PreferedName || "",
          LastName: this.contractor.LastName,
          Address1: this.contractor.Address1 || "",
          Address2: this.contractor.Address2 || "",
          Suburb: this.contractor.Suburb || "",
          State: this.contractor.State || "",
          Postcode: this.contractor.Postcode || "",
          HomePhone: this.contractor.HomePhone || "",
          Mobile: this.contractor.Mobile || "",
          Email: this.contractor.Email || "",
          Licence: this.contractor.Licence || "",
          Raca: this.contractor.Raca || "",
          ABN: this.contractor.ABN || "",
          CompanyName: this.contractor.CompanyName || "",
          BankName: this.contractor.BankName || "",
          AccountName: this.contractor.AccountName || "",
          BSB: this.contractor.BSB || "",
          AccountNo: this.contractor.AccountNo || "",
          DOB: this.contractor.DOB || "1970-01-01",
          DOC: this.contractor.DOC || "1970-01-01",
          retain_gst: this.contractor.retain_gst,
          Inactive: this.contractor.Inactive,
        };

        axios
          .put("/contractor/" + this.contractor.ContractorID, contractor)
          .then((res) => {
            if (res.status === 201) {
              this.snackbar.color = "success";
              this.snackbar.text =
                "Contractor: " + res.data.contractorID + " updated";
              this.snackbar.visible = true;
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.snackbar.color = "error";
            this.snackbar.text = this.errors.pop();
            this.snackbar.visible = true;
          });
      }
    },
    resetPassword(ContractorID) {
      let user = {
        username: ContractorID,
      };

      axios
        .put("/user/reset_password", user)
        .then((res) => {
          if (res.status === 200) {
            this.snackbar.color = "success";
            this.snackbar.text = "Password reset completed";
            this.snackbar.visible = true;
            this.Password = "";
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.snackbar.color = "error";
          this.snackbar.text = this.errors.pop();
          this.snackbar.visible = true;
        });
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    resetContractor() {
      this.contractor = {};
      this.contractor.retain_gst = false;
      this.contractor.Inactive = false;
    },
    zeroPad(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    },
    dismissForm() {
      this.resetContractor();
      this.$emit("onCloseDialog");
    },
  },
};
</script>
