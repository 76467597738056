<template>
  <v-container id="Invoice" grid-list-md fluid>
    <v-flex xs12 sm12 md12>
      <v-card>
        <v-card-title class="headline" title--text
          >Generate Invoice</v-card-title
        >
        <v-card-text>
          <v-form>
            <v-layout raw wrap align-center justify-center>
              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="upToDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedUpToDateFormatted"
                      label="Unbilled Invoice Up To"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="upToDate"
                    no-title
                    @input="upToDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-select
                  :loading="companyNamesLoading"
                  prepend-icon="home"
                  v-model="companyName"
                  label="Company Name"
                  :items="companyNames"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="sendDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedSendDateFormatted"
                      label="To Be Sent On"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="sendDate"
                    no-title
                    @input="sendDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-form>

          <!--This is for Jobs Entries -->
          <v-subheader>Job(s)</v-subheader>
          <v-data-table
            :headers="jobEntriesHeader"
            :items="JobEntries"
            sortBy="Service Date"
          >
            <template v-slot:item="props">
              <tr>
                <td class="body-2">{{ props.item.Date }}</td>
                <!--td class="body-2">{{ props.item.Unit }} - {{ props.item.contractor_name }}</td-->
                <td class="body-2">{{ props.item.Unit }}</td>
                <td class="body-2">
                  {{ props.item.VT }} {{ startAndEndTimes(props.item) }}
                </td>
                <td class="body-2">{{ props.item.Duration }}</td>
                <td class="body-2">
                  $ {{ formatCurrency(parseFloat(props.item.Rate).toFixed(2)) }}
                </td>
                <td class="body-2">
                  $ {{ formatCurrency(parseFloat(props.item.Toll).toFixed(2)) }}
                </td>
                <td class="body-2">
                  $
                  {{
                    formatCurrency(parseFloat(props.item.Fuel_Levy).toFixed(2))
                  }}
                </td>
                <td class="body-2">
                  $
                  {{
                    formatCurrency(parseFloat(props.item.Subtotal).toFixed(2))
                  }}
                </td>
                <td class="body-2">
                  $ {{ formatCurrency(parseFloat(props.item.GST).toFixed(2)) }}
                </td>
                <td class="body-2">
                  $
                  {{ formatCurrency(parseFloat(props.item.Total).toFixed(2)) }}
                </td>
              </tr>
            </template>
          </v-data-table>

          <!--This is for Misc Entries -->
          <v-subheader>Miscellaneous Entries</v-subheader>
          <v-data-table
            :headers="miscEntriesHeader"
            :items-per-page="5"
            :items="MiscEntries"
            sortBy="MiscEntryDate"
          >
            <template v-slot:item="props">
              <tr>
                <td class="body-2">{{ props.item.Date }}</td>
                <td class="body-2">{{ props.item.Client }}</td>
                <td class="body-2">{{ props.item.Description }}</td>
                <td class="body-2">
                  $
                  {{
                    formatCurrency(parseFloat(props.item.Subtotal).toFixed(2))
                  }}
                </td>
                <td class="body-2">
                  $ {{ formatCurrency(parseFloat(props.item.GST).toFixed(2)) }}
                </td>
                <td class="body-2">
                  $
                  {{ formatCurrency(parseFloat(props.item.Total).toFixed(2)) }}
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-divider></v-divider>

          <v-layout raw wrap align-center justify-center>
            <v-subheader>Invoice Summary</v-subheader>
          </v-layout>

          <v-layout raw wrap align-end justify-space-around>
            <v-flex md2>
              <v-layout column align-space-around justify-end>
                <v-text-field
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(parseFloat(computeFuelLevy).toFixed(2))
                  "
                  v-bind:label="this.getFuelLevyLabel()"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex md2>
              <v-layout column align-space-around justify-end>
                <v-text-field
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(parseFloat(computeTollWithoutGST).toFixed(2))
                  "
                  label="Toll Charge (Ex. GST)"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex md2>
              <v-layout column align-space-around justify-end>
                <v-text-field
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(
                      (
                        parseFloat(computeSubTotal) +
                        parseFloat(computeFuelLevy) +
                        parseFloat(computeTollWithoutGST)
                      ).toFixed(2)
                    )
                  "
                  label="Subtotal"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex md2>
              <v-layout column align-space-around justify-end>
                <v-text-field
                  readonly
                  prefix="$"
                  :value="formatCurrency(parseFloat(computeGST).toFixed(2))"
                  label="GST"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex md2>
              <v-layout column align-space-around justify-end>
                <v-text-field
                  readonly
                  prefix="$"
                  :value="formatCurrency(parseFloat(computeTotal).toFixed(2))"
                  label="Total"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="companyName === ''"
              :dark="companyName !== ''"
              @click="commitInvoice(true)"
              right
              color="green"
              >Preview Invoice</v-btn
            >
            <v-btn
              :disabled="companyName === '' || loadingCSV"
              right
              :loading="loadingCSV"
              color="primary"
              @click="downloadCSV()"
            >
              Download CSV
              <template v-slot:loader>
                <span>Loading...</span>
              </template>
            </v-btn>
            <v-btn
              :disabled="invoiceId === 0"
              :dark="invoiceId !== 0"
              @click="downloadPdf()"
              right
              color="green"
              >Download PDF</v-btn
            >
            <v-btn
              :disabled="companyName === ''"
              :dark="companyName !== ''"
              @click="commitInvoice()"
              right
              color="blue"
              >Generate Invoice</v-btn
            >

            <!-- Needs some work to fix a couple of things
            1. It is not copying Miscellaneous entries
            2. After copy to clipboard the table gets messed up with undefined values -->
            <!-- <v-btn
              :disabled="companyName === ''"
              :dark="companyName !== ''"
              @click="CSVExport(invoiceCSV)"
              right
              color="blue"
            >Export to CSV</v-btn> -->
          </v-card-actions>
        </v-card-text>
        <v-snackbar
          v-model="snackbar.visible"
          :color="snackbar.color"
          :timeout="snackbar.timeout"
        >
          {{ snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
import axios from "axios";
import router from "../../router";

export default {
  data() {
    return {
      /*upToDate: new Date().toISOString().substr(0, 10),
      upToDateFormatted: this.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      sendDate: new Date().toISOString().substr(0, 10),
      sendDateFormatted: this.formatDate(
        new Date().toISOString().substr(0, 10)
      ),*/
      upToDate: this.parseDate(new Date().toLocaleDateString("en-AU")),
      upToDateFormatted: new Date().toLocaleDateString("en-AU"),
      sendDate: this.parseDate(new Date().toLocaleDateString("en-AU")),
      sendDateFormatted: new Date().toLocaleDateString("en-AU"),
      upToDateMenu: false,
      sendDateMenu: false,
      companyNamesLoading: false,
      companyNames: [],
      Companies: [],
      companyName: "",
      companyDetails: {},
      selectedCompanyID: "",
      invoiceId: 0,
      previewInvoiceId: 0,

      JobEntries: [],
      jobEntriesHeader: [
        {
          value: "Service Date",
          align: "start",
          sortable: true,
          text: "Service Date",
        },
        { value: "Unit", align: "left", sortable: true, text: "Unit" },
        {
          value: "Description",
          align: "start",
          sortable: true,
          text: "Description (Time)",
        },
        { value: "TotalHours", align: "left", sortable: true, text: "Qty/Hrs" },
        { value: "Rate", align: "left", sortable: true, text: "Rate" },
        { value: "Toll", align: "left", sortable: true, text: "Toll" },
        {
          value: "Fuel_Levy",
          align: "start",
          sortable: true,
          text: "Fuel Levy",
        },
        { value: "Subtotal", align: "start", sortable: true, text: "Subtotal" },
        { value: "GST", align: "start", sortable: true, text: "GST" },
        {
          value: "TotalAmount",
          align: "start",
          sortable: true,
          text: "Amount",
        },
      ],

      MiscEntries: [],
      miscEntriesHeader: [
        {
          value: "MiscEntryDate",
          align: "left",
          sortable: true,
          text: "Date",
        },
        { value: "CompanyID", align: "left", sortable: true, text: "Client" },
        {
          value: "MiscDesc",
          align: "left",
          sortable: true,
          text: "Description",
        },
        {
          value: "MiscSubtotal",
          align: "left",
          sortable: true,
          text: "Subtotal",
        },
        { value: "MiscGST", align: "left", sortable: true, text: "GST" },
        { value: "MiscTotal", align: "left", sortable: true, text: "Total" },
      ],
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
      loadingPdf: false,
      loadingCSV: false,
      errors: [],
    };
  },

  created() {
    this.loadCompanies();
    this.companyName = "";
  },

  computed: {
    invoiceCSV() {
      //console.log(this.contractorMiscs);
      var newArr = this.JobEntries.map((x) => x);
      console.log(newArr, " ", newArr.length);
      for (var i = 0; i < newArr.length; i++) {
        delete newArr[i].contractor_fcs;
        delete newArr[i].hide_times;
        delete newArr[i].Client_InvoiceID;
        delete newArr[i].job_desc;
        delete newArr[i].contractor_name;
        delete newArr[i].JobID;
      }
      return newArr.map(function (obj) {
        //obj['Service Date']=obj['Date'];
        //delete obj['Date'];

        obj["Description"] = obj["VT"] + " " + obj["Start"] + "-" + obj["End"];
        delete obj["VT"];
        delete obj["Start"];
        delete obj["End"];
        obj["Qty/Hrs"] = obj["Duration"];
        delete obj["Duration"];

        var sub = obj["Subtotal"];
        delete obj["Subtotal"];
        obj["Subtotal"] = sub;
        var gst = obj["GST"];
        delete obj["GST"];
        obj["GST"] = gst;
        obj["Amount"] = obj["Total"];
        delete obj["Total"];

        return obj;
      });
    },
    computedUpToDateFormatted() {
      return this.formatDate(this.upToDate);
    },
    computedSendDateFormatted() {
      return this.formatDate(this.sendDate);
    },
    computeSubTotal() {
      return (
        this.JobEntries.reduce((acc, item) => acc + item.Subtotal, 0) +
        this.MiscEntries.reduce((acc, item) => acc + item.Subtotal, 0)
      );
    },
    computeJobSubTotal() {
      return this.JobEntries.reduce((acc, item) => acc + item.Subtotal, 0);
    },
    computeTollWithoutGST() {
      return (
        (this.JobEntries.reduce((acc, item) => acc + item.Toll, 0) * 10) / 11
      );
    },
    getFuelLevy() {
      if (this.companyDetails.fuel_levy == undefined) return 0.0;
      return this.companyDetails.fuel_levy;
    },
    computeFuelLevy() {
      return ((this.computeJobSubTotal * this.getFuelLevy) / 100).toFixed(2);
    },
    computeGST() {
      return (
        (parseFloat(this.computeSubTotal) +
          parseFloat(this.computeFuelLevy) +
          parseFloat(this.computeTollWithoutGST)) *
        0.1
      ).toFixed(2);
    },
    computeTotal() {
      return (
        parseFloat(this.computeSubTotal) +
        parseFloat(this.computeFuelLevy) +
        parseFloat(this.computeTollWithoutGST) +
        parseFloat(this.computeGST)
      ).toFixed(2);
    },
  },

  watch: {
    upToDate(val) {
      this.upToDateFormatted = this.formatDate(this.upToDate);
      this.companyName = "";
      this.loadCompanies();
    },

    sendDate(val) {
      this.sendDateFormatted = this.formatDate(this.sendDate);
    },

    companyName() {
      this.companySelected();
    },
  },

  methods: {
    CSVExport(arrData) {
      let csvContent = "data:tex/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(","),
        ...arrData.map((item) => Object.values(item).join(",")),
      ].join("\n");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "invoice.csv");
      link.click();
    },
    startAndEndTimes(job) {
      // Change times based on job rate_type
      if (job.rate_type === "flat") {
        job.Duration = (job.Subtotal / job.Rate).toFixed(2);
        return "";
      } else if (job.rate_type === "piece") {
        job.Duration = Math.round(job.Subtotal / job.Rate);
        return "Piece Rate";
      } else if (job.rate_type === "stop") {
        job.Duration = Math.round(job.Subtotal / job.Rate);
        return "Stop Rate";
      } else {
        return job.Start + " - " + job.End;
      }
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    formatCurrency(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    loadCompanies() {
      this.companyNamesLoading = true;
      this.companyNames.splice(0, this.companyNames.length);
      axios
        .get("/invoice/" + this.upToDate + "/retrieveUnbilledCompanies")
        .then((response) => {
          response.data.forEach((company) => {
            this.companyNames.push(
              company.Name + " (" + company.DepotName + ")"
            );

            this.companyNames.sort();
            this.Companies.push({
              companyName: company.DepotName,
              //companyFuelLevy: company.fuel_levy
            });
          });
          this.companyNamesLoading = false;
        })

        .catch((e) => {
          this.errors.push(e);
          console.log(e);
          this.companyNamesLoading = false;
        });
    },

    companySelected() {
      var tempID1 = this.companyName;
      var tempID2 = tempID1.split("(");
      var compID = tempID2[1].split(")");
      this.selectedCompanyID = compID[0];
      //this.loadContractor();
      this.loadCompanyDetails();
      this.loadJobsEntries();
      this.loadMiscEntries();
      //this.loadYTDAmount();
    },

    loadCompanyDetails() {
      axios
        .get("/invoice/" + this.selectedCompanyID + "/loadCompanyDetails")
        .then((response) => {
          this.companyDetails = response.data[0];
        })
        .catch((e) => {
          this.error.push(e);
        });
    },

    //Methods for Job Entries
    loadJobsEntries() {
      this.jobsLoading = true;
      //console.log("Load Job Entries");
      this.JobEntries = [];

      axios
        .get(
          "/invoice/" +
            this.selectedCompanyID +
            "/" +
            this.upToDate +
            "/retrieveUnbilledJobsEntries"
        )
        .then((response) => {
          this.JobEntries = response.data;
          this.jobsLoading = false;
        })
        .catch((e) => {
          this.error.push(e);
          this.jobsLoading = false;
        });
    },

    //Methods for Misc Entries
    loadMiscEntries() {
      this.miscLoading = true;
      //console.log("Load Invoice Misc Entries");
      this.MiscEntries = [];
      this.error = [];

      axios
        .get(
          "/invoice/" +
            this.selectedCompanyID +
            "/" +
            this.upToDate +
            "/retrieveUnbilledMiscEntries"
        )
        .then((response) => {
          this.MiscEntries = response.data;
          this.miscLoading = false;
        })
        .catch((e) => {
          this.error.push(e);
          this.miscLoading = false;
        });
    },

    getFuelLevyLabel() {
      return "Fuel Levy - " + this.getFuelLevy + "%";
    },

    downloadPdf(isPreview = false) {
      var invoiceNum = this.invoiceId;

      if (isPreview) {
        invoiceNum = "Preview_" + this.previewInvoiceId;
        //invoiceNum = "Preview_" + this.invoiceId;
      }

      //this.invoiceId = 12716;
      this.loadingPdf = true;
      axios({
        method: "get",
        url: "pdf/invoice/" + invoiceNum,
        responseType: "blob",
      })
        .then((response) => {
          this.forceFileDownload(response, isPreview);
          this.loadingPdf = false;
          this.invoiceId = 0; // disable download pdf button
        })
        .catch((err) => {
          console.log(err + " occured");
          this.loadingPdf = false;
        });
    },

    forceFileDownload(response, isPreview = false) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      if (!isPreview) {
        link.setAttribute("download", "Invoice_" + this.invoiceId + ".pdf"); //or any other extension
      } else {
        link.setAttribute(
          "download",
          "Preview_Invoice_" + this.previewInvoiceId + ".pdf"
        ); //or any other extension
      }

      document.body.appendChild(link);
      link.click();
      if (!isPreview) {
        this.JobEntries = [];
        this.MiscEntries = [];
        this.InvoiceId = 0;
      }
    },

    commitInvoice(isPreview = false) {
      let invoicePreview = isPreview;

      let invoice = {
        Create_Date: this.upToDate,
        Client_Name: this.selectedCompanyID,
        Subtotal:
          parseFloat(this.computeSubTotal) +
          parseFloat(this.computeFuelLevy) +
          parseFloat(this.computeTollWithoutGST),
        GST: this.computeGST,
        Total: this.computeTotal,
        jobCount: this.JobEntries.length,
        miscCount: this.MiscEntries.length,
        send_date: this.sendDate,
        preview: invoicePreview,
      };

      axios
        .post("/invoice", invoice)
        .then((res) => {
          //console.log(res);
          if (res.status === 201) {
            //console.log(res.status);
            if (!isPreview) {
              this.invoiceId = res.data.invoiceId;
              this.snackbar.color = "success";
              this.snackbar.text =
                "Invoice ID: " + this.invoiceId + " Generated";
              this.snackbar.visible = true;
              this.loadCompanies(); // reload company list
              this.companyName = ""; // Disable Generate Invoice Button
            } else {
              //Reserve for Preview function
              this.previewInvoiceId = res.data.invoiceId;
              this.snackbar.color = "success";
              this.downloadPdf(isPreview);
              this.snackbar.text = "Retriving Invoice for Preview...";
              this.snackbar.visible = true;
            }
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.snackbar.color = "red";
          this.snackbar.text = this.errors.pop();
          this.snackbar.visible = true;
        });
    },
    downloadCSV() {
      this.loadingCSV = true;
      axios({
        method: "get",
        url:
          "invoice/invoicecsv/" +
          this.selectedCompanyID +
          "?uptodate=" +
          this.upToDate,
        responseType: "blob",
      })
        .then((response) => {
          this.forceCSVDownload(response, this.selectedCompanyID);
          this.loadingCSV = false;
        })
        .catch((err) => {
          console.log(err + " occured");
          this.loadingCSV = false;
        });
    },
    forceCSVDownload(response, companyID) {
      const url = window.URL.createObjectURL(
        new Blob(["\ufeff", response.data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Invoice_" + companyID + "_" + new Date().getTime() + ".csv"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
