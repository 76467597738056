<template>
  <v-card>
    <v-card-title> Leave Calendar </v-card-title>
    <v-card-text>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>arrow_back_ios </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>arrow_forward_ios </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.leave_calendar">
                {{ $refs.leave_calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet>
            <v-calendar
              ref="leave_calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              :event-height="30"
              @click:event="showEvent"
              @change="loadEvents"
              :event-more="false"
            >
              <template v-slot:event="{ event }">
                <div class="my-event subtitle-1">
                  <v-icon v-if="event.type === 'job'" color="white" small
                    >local_shipping</v-icon
                  >
                  <v-icon v-if="event.type === 'leave'" color="white" small
                    >beach_access</v-icon
                  >
                  {{ event.name }}
                </div>
              </template>
            </v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
              max-width="500px"
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="deleteLeave(selectedEvent.id)"
                    v-if="selectedEvent.type === 'leave'"
                    icon
                  >
                    <v-icon>delete_forever</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.description"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: {
    reloadLeaves: Number,
  },

  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    jobColors: [],
    colors: [
      "cyan darken-4",
      "blue darken-4",
      "purple darken-4",
      "cyan",
      "indigo",
      "orange darken-4",
      "grey darken-1",
      "green darken-4",
    ],
    start: {},
    end: {},
    snackbar: {
      visible: false,
      color: "success",
      timeout: 3000,
      text: "",
    },
    errors: []
  }),
  mounted() {
    this.$refs.leave_calendar.checkChange();
  },
  watch: {
    reloadLeaves() {
      this.loadEvents({ start: this.start, end: this.end });
    },
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.leave_calendar.prev();
    },
    next() {
      this.$refs.leave_calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    loadEvents({ start, end }) {
      this.start = start;
      this.end = end;
      this.jobColors = [];
      // Load contractor leave
      axios
        .get("/leave/?startDate=" + start.date + "&endDate=" + end.date)
        .then((response) => {
          this.events = [];
          response.data.forEach((leave) => {
            this.events.push({
              type: "leave",
              id: leave.leaveID,
              name: leave.ContractorID + ": " +
                (leave.PreferedName || leave.FirstName) + " " + leave.LastName
               + (leave.approved ? " (Approved)" : " (Pending)"),
              description: `Start: ${leave.StartDate}<br>Finish: ${leave.EndDate}`,
              start: new Date(`${leave.StartDate}T00:00:00`),
              end: new Date(`${leave.EndDate}T00:00:00`),
              color: this.getColor(leave.ContractorID),
              timed: false,
            });
          });
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getColor(ContractorID) {
      let jobColor = "";
      this.jobColors.forEach((color) => {
        if (color.ContractorID === ContractorID) {
          jobColor = color.color;
        }
      });
      if (jobColor === "") {
        if (this.jobColors.length < this.colors.length) {
          jobColor = this.colors[this.jobColors.length];
        } else {
          jobColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        }
        this.jobColors.push({ ContractorID: ContractorID, color: jobColor });
      }
      return jobColor;
    },
    deleteLeave(leaveID) {
      axios
        .post("/leave/deleteLeave?leaveID=" + leaveID)
        .then((response) => {
          if (response.status === 200) {
            this.loadEvents({ start: this.start, end: this.end });
            this.selectedOpen = false;
            this.$emit("onUpdate");
            this.snackbar.color = "success";
            this.snackbar.text = "Leave deleted";
            this.snackbar.visible = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  width: 100%;
  padding-left: 5px;
  cursor: pointer;
}

.v-calendar-weekly {
  height: auto;
  min-height: 600px;
}
</style>
