<template>
  <v-container id="PayslipRetrieval" grid-list-md fluid>
    <v-flex xs12 sm12 md12>
      <v-card>
        <v-card-title class="headline" title--text
          >Retrieve Payslip</v-card-title
        >
        <v-card-text>
          <v-form>
            <v-layout raw wrap align-center justify-center>
              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startDateFormatted"
                      label="From"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    @input="startDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDateFormatted"
                      label="To"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    no-title
                    @input="endDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md3>
                <v-text-field
                  v-model="searchPayslip"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-form>

          <!--This is for Payslip List -->
          <v-subheader>Payslips</v-subheader>

          <v-flex xs12 sm12 md12>
            <v-data-table
              :items="PayslipList"
              :search="searchPayslip"
              :headers="PayslipListHeaders"
              :items-per-page="10"
            >
              <template v-slot:item="props">
                <tr
                  :key="props.index"
                  @click="payslipIDSelected(props.item.payslipID)"
                >
                  <td class="body-2">{{ props.item.payslipID }}</td>
                  <td class="body-2">{{ props.item.formattedDate }}</td>
                  <td class="body-2">{{ props.item.Contractor_ID }}</td>
                  <td class="body-2 text-xs-center">
                    $ {{ parseFloat(props.item.fuel_levy).toFixed(2) }}
                  </td>
                  <td class="body-2 text-xs-right">
                    $ {{ parseFloat(props.item.toll_charge).toFixed(2) }}
                  </td>
                  <td class="body-2 text-xs-right">
                    $ {{ parseFloat(props.item.gst).toFixed(2) }}
                  </td>
                  <td class="body-2">
                    $ {{ parseFloat(props.item.tax_income).toFixed(2) }}
                  </td>
                  <td class="body-2">
                    $ {{ parseFloat(props.item.total).toFixed(2) }}
                  </td>
                  <td class="body-2">{{ props.item.Pay_Period }}</td>
                  <td class="body-2">{{ props.item.PaymentDateFormatted }}</td>
                  <td class="body-2" v-if="props.item.paid">YES</td>
                  <td class="body-2" v-if="!props.item.paid">NO</td>
                  <td>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="downloadPdf(props.item.payslipID)"
                          class="mx-2"
                          fab
                          dark
                          small
                          color="purple darken-4"
                          v-on="on"
                        >
                          <v-icon dark>cloud_download</v-icon>
                        </v-btn>
                      </template>
                      <span>Download: {{ props.item.payslipID }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" color="info" icon="info"
                  >No Payslips</v-alert
                >
              </template>
            </v-data-table>
          </v-flex>

          <v-card-actions>
            <v-spacer></v-spacer>
            <!--v-btn :disabled="payslipID === 0" :dark="payslipID !== 0" @click="downloadPdf" right color="green">Download PDF</v-btn-->
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      error: [],
      Pay: {},
      /*startDate: new Date().toISOString().substr(0, 10),
      startDateFormatted: this.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      endDate: new Date().toISOString().substr(0, 10),
      endDateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),*/
      startDate: this.parseDate(new Date().toLocaleDateString("en-AU")),
      startDateFormatted: new Date().toLocaleDateString("en-AU"),
      endDate: this.parseDate(new Date().toLocaleDateString("en-AU")),
      endDateFormatted: new Date().toLocaleDateString("en-AU"),

      startDateMenu: false,
      endDateMenu: false,

      payslipID: 0,
      PayslipList: [],
      searchPayslip: "",

      PayslipListHeaders: [
        {
          value: "payslipID",
          align: "left",
          sortable: true,
          text: "Payslip ID",
        },
        {
          value: "formattedDate",
          align: "left",
          sortable: true,
          text: "Date",
        },
        {
          value: "Contractor_ID",
          align: "left",
          sortable: false,
          text: "ID",
        },
        {
          value: "fuel_levy",
          align: "left",
          sortable: false,
          text: "Fuel",
        },

        {
          value: "toll_charge",
          align: "left",
          sortable: true,
          text: "Toll",
        },

        {
          value: "GST",
          align: "left",
          sortable: false,
          text: "GST",
        },

        {
          value: "tax_income",
          align: "left",
          sortable: false,
          text: "Services",
        },

        {
          value: "total",
          align: "left",
          sortable: false,
          text: "Total",
        },

        {
          value: "Pay_Period",
          align: "left",
          sortable: false,
          text: "Period",
        },

        {
          value: "PaymentDateFormatted",
          align: "left",
          sortable: false,
          text: "Pay Date",
        },

        {
          value: "paid",
          align: "left",
          sortable: false,
          text: "Paid",
        },
        {
          value: "download",
          align: "left",
          sortable: false,
          text: " ",
        },
      ],

      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },

      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      errors: [],
    };
  },

  created() {
    this.loadPayslip();
  },

  computed: {
    computedStartDateFormatted() {
      return this.formatDate(this.startDate);
    },
    computedEndDateFormatted() {
      return this.formatDate(this.endDate);
    },
  },

  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
      this.loadPayslip();
    },
    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
      this.loadPayslip();
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    loadPayslip() {
      axios
        .get(
          "/payslip/retrievePayslip?startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate
        )
        .then((response) => {
          this.PayslipList = response.data;
        })
        .catch((e) => {
          this.error.push(e);
          //this.contractorNamesLoading = false;
        });
    },

    payslipIDSelected(selectedPayslipID) {
      this.payslipID = selectedPayslipID;
    },

    downloadPdf(currentPayslipID) {
      this.loadingPdf = true;
      axios({
        method: "get",
        url: "pdf/payslip/" + currentPayslipID,
        responseType: "blob",
      })
        .then((response) => {
          this.forceFileDownload(response, currentPayslipID);
          this.loadingPdf = false;
        })
        .catch((err) => {
          console.log(err + " occured");
          this.loadingPdf = false;
        });
    },

    forceFileDownload(response, currentPayslipID) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Payslip_" + currentPayslipID + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
      //this.payslipID = 0;
    },
  },
};
</script>

<style scoped>
.theme--dark.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.active {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.active {
  background: #a1caff;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #80b5fc;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}
</style>
