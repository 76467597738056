<template>
  <v-container grid-list-md fluid>
    <v-tabs centered color="white" background-color="blue">
      <v-tabs-slider color="indigo"></v-tabs-slider>
      <v-tab ripple> <v-icon>directions_run</v-icon>Contractor </v-tab>
      <v-tab ripple> <v-icon>group</v-icon>Client </v-tab>
      <v-tab-item :key="contractorTable">
        <v-dialog v-model="deleteCMiscDialog" persistent max-width="400">
          <v-card>
            <v-card-title class="headline">Confirm Delete</v-card-title>
            <v-card-text
              >Are you sure you want to delete miscellaneous from
              {{ contractorMiscToBeDelete.contractor_id }}</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="success" @click="deleteCMiscDialog = false">
                Cancel
                <v-icon right dark>cancel</v-icon>
              </v-btn>
              <v-btn class="error" @click="deleteContractorMisc">
                Delete
                <v-icon right dark>delete_forever</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card>
          <v-layout>
            <v-flex xs md8>
              <v-card>
                <v-data-table
                  :headers="contractorFields"
                  :items="contractorMiscs"
                >
                  <template v-slot:item="props">
                    <tr class="pa-0">
                      <td
                        class="body-2"
                        v-bind:class="{ 'grey--text': props.item.Inactive }"
                      >
                        {{ props.item.contractor_id }}
                      </td>
                      <td
                        class="body-2"
                        v-bind:class="{ 'grey--text': props.item.Inactive }"
                      >
                        {{ props.item.entrydate }}
                      </td>
                      <td
                        class="body-2"
                        v-bind:class="{ 'grey--text': props.item.Inactive }"
                      >
                        {{ props.item.description }}
                      </td>
                      <td
                        class="body-2"
                        v-bind:class="{ 'grey--text': props.item.Inactive }"
                      >
                        {{ props.item.sub_total }}
                      </td>
                      <td
                        class="body-2"
                        v-bind:class="{ 'grey--text': props.item.Inactive }"
                      >
                        {{ props.item.total }}
                      </td>
                      <td>
                        <v-icon
                          class="mr-2"
                          @click="contractorSelected(props.item)"
                          >edit</v-icon
                        >
                        <v-icon @click="confirmDeleteCMisc(props.item)"
                          >delete</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-flex>
            <v-flex xs md4>
              <v-card>
                <v-flex>
                  <v-autocomplete
                    v-model="selectedContractorID"
                    :disabled="cmodifies"
                    :items="contractorIDs"
                    :filter="customFilter"
                    item-text="contractorid"
                    label="Contractor ID"
                  ></v-autocomplete>
                </v-flex>

                <v-flex>
                  <v-combobox
                    v-model="selectedContractorDescription"
                    :items="contractorDescriptions"
                    :search-input.sync="search"
                    hide-selected
                    hint="Press Enter to add new description"
                    label="Add some tags"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ search }}</strong
                            >". Press <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-flex>

                <v-flex>
                  <v-text-field
                    label="Amount exc. GST"
                    v-model="selectedContractorMisc.sub_total"
                    :rules="[rules.subtotal]"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedDateFormatted"
                        label="Entry Date"
                        hint="DD/MM/YYYY format"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selectedContractorEntryDate"
                      no-title
                      @input="menuDate = false"
                      :rules="[rules.required]"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex>
                  <v-switch
                    inset
                    label="Retain GST"
                    disabled
                    v-model="selectedContractorMisc.retain_gst"
                  ></v-switch>
                </v-flex>

                <v-flex>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="!cmodifies"
                      class="error"
                      v-on:click="resetContractorMiscFields"
                    >
                      <v-icon right dark>cancel</v-icon>Reset
                    </v-btn>
                    <v-btn
                      v-if="!cmodifies"
                      class="success"
                      v-on:click="addContractorMisc"
                    >
                      <v-icon right dark>save</v-icon>Add
                    </v-btn>
                    <v-btn
                      v-if="cmodifies"
                      class="error"
                      v-on:click="resetContractorMiscFields"
                    >
                      <v-icon right dark>cancel</v-icon>Cancel
                    </v-btn>
                    <v-btn
                      v-if="cmodifies"
                      class="success"
                      v-on:click="modContractorMisc"
                    >
                      <v-icon right dark>save</v-icon>Update
                    </v-btn>
                    <v-btn @click="csvExport(csvDataCont)">Export to CSV</v-btn>
                  </v-card-actions>
                </v-flex>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-dialog v-model="deleteDMiscDialog" persistent max-width="400">
          <v-card>
            <v-card-title class="headline">Confirm Delete</v-card-title>
            <v-card-text
              >Are you sure you want to delete miscellaneous from
              {{ clientMiscToBeDelete.depotname }}</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="success" @click="deleteDMiscDialog = false">
                Cancel
                <v-icon right dark>cancel</v-icon>
              </v-btn>
              <v-btn class="error" @click="deleteClientMisc">
                Delete
                <v-icon right dark>delete_forever</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card>
          <v-layout row wrap>
            <v-flex md8>
              <v-card>
                <v-data-table
                  :headers="clientFields"
                  :items="clientMiscs"
                  :key="clientTable"
                >
                  <template v-slot:item="props">
                    <tr class="pa-0">
                      <td
                        class="body-2"
                        v-bind:class="{ 'grey--text': props.item.Inactive }"
                      >
                        {{ props.item.depotname }}
                      </td>
                      <td
                        class="body-2"
                        v-bind:class="{ 'grey--text': props.item.Inactive }"
                      >
                        {{ props.item.entrydate }}
                      </td>
                      <td
                        class="body-2"
                        v-bind:class="{ 'grey--text': props.item.Inactive }"
                      >
                        {{ props.item.description }}
                      </td>
                      <td
                        class="body-2"
                        v-bind:class="{ 'grey--text': props.item.Inactive }"
                      >
                        {{ props.item.sub_total }}
                      </td>
                      <td
                        class="body-2"
                        v-bind:class="{ 'grey--text': props.item.Inactive }"
                      >
                        {{ props.item.total }}
                      </td>
                      <td>
                        <v-icon class="mr-2" @click="clientSelected(props.item)"
                          >edit</v-icon
                        >
                        <v-icon @click="confirmDeleteDMisc(props.item)"
                          >delete</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-flex>
            <v-flex md4>
              <v-card>
                <v-flex>
                  <v-autocomplete
                    v-model="selectedDepot"
                    :disabled="dmodifies"
                    :items="clientDepots"
                    :filter="depotCustomFilter"
                    item-text="depotName"
                    label="Depot"
                  ></v-autocomplete>
                </v-flex>

                <v-flex>
                  <v-combobox
                    v-model="selectedClientDescription"
                    :items="clientDescriptions"
                    :search-input.sync="search"
                    hide-selected
                    hint="Press Enter to add new description"
                    label="Description"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ search }}</strong
                            >". Press <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-flex>

                <v-flex>
                  <v-text-field
                    label="Amount exc. GST"
                    v-model="selectedClientMisc.sub_total"
                    :rules="[rules.subtotal]"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <v-menu
                    v-model="clientMenuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="clientComputedDateFormatted"
                        label="Entry Date"
                        hint="DD/MM/YYYY format"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selectedClientEntryDate"
                      no-title
                      @input="clientMenuDate = false"
                      :rules="[rules.required]"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      v-if="!dmodifies"
                      class="error"
                      v-on:click="resetClientMiscFields"
                      ><v-icon right dark>cancel</v-icon>Reset</v-btn
                    >

                    <v-btn
                      v-if="!dmodifies"
                      class="success"
                      v-on:click="addClientMisc"
                      ><v-icon right dark>save</v-icon>Add</v-btn
                    >
                    <v-btn
                      v-if="dmodifies"
                      class="error"
                      v-on:click="resetClientMiscFields"
                      >Cancel</v-btn
                    >

                    <v-btn
                      v-if="dmodifies"
                      class="success"
                      v-on:click="modClientMisc"
                      ><v-icon right dark>save</v-icon>Update</v-btn
                    >
                    <v-btn @click="csvExport(csvDataCli)">Export to CSV</v-btn>
                  </v-card-actions>
                </v-flex>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  components: {
    //ListSelect
  },
  created() {
    //load contractor misc
    this.loadContractorMisc();
    //load client misc
    this.loadClientMisc();
    //load clientID
    this.loadMiscClientID();
    //load contractorID
    this.loadMiscContractorID();
    //load Client Descriptions
    this.loadClientDescription();
    //load Contractor Description
    this.loadContractorDescription();
  },
  computed: {
    csvDataCont() {
      //console.log(this.contractorMiscs);
      var newArr = this.contractorMiscs.map((x) => x);
      //console.log(newArr," ",newArr.length);
      for (var i = 0; i < newArr.length; i++) {
        delete newArr[i].entry_date;
        delete newArr[i].payslip_adj_id;
        delete newArr[i].payslip_payslipID;
        delete newArr[i].payslip_payslipID_preview;
        delete newArr[i].desc_date;
      }
      return newArr.map(function (obj) {
        obj["Contractor"] = obj["contractor_id"];
        delete obj["contractor_id"];
        obj["Description"] = obj["description"];
        delete obj["description"];
        obj["Subtotal"] = obj["sub_total"];
        delete obj["sub_total"];
        obj["GST"] = obj["gst"];
        delete obj["gst"];
        obj["Total"] = obj["total"];
        delete obj["total"];
        obj["Retain GST"] = obj["retain_gst"];
        delete obj["retain_gst"];
        obj["Entry Date"] = obj["entrydate"];
        delete obj["entrydate"];
        return obj;
      });
    },
    csvDataCli() {
      var newArr = this.clientMiscs.map((x) => x);
      //console.log(newArr," ",newArr.length);
      for (var i = 0; i < newArr.length; i++) {
        delete newArr[i].entry_date;
        delete newArr[i].invoice_adj_id;
        delete newArr[i].invoice_invoiceID;
        delete newArr[i].invoice_invoiceID_preview;
        delete newArr[i].desc_date;
      }
      return newArr.map(function (obj) {
        obj["Depot"] = obj["depotname"];
        delete obj["depotname"];
        obj["Description"] = obj["description"];
        delete obj["description"];
        obj["Subtotal"] = obj["sub_total"];
        delete obj["sub_total"];
        obj["GST"] = obj["gst"];
        delete obj["gst"];
        obj["Total"] = obj["total"];
        delete obj["total"];
        obj["Entry Date"] = obj["entrydate"];
        delete obj["entrydate"];

        return obj;
      });
    },
    computedDateFormatted() {
      return this.formatDate(this.selectedContractorEntryDate);
    },
    clientComputedDateFormatted() {
      return this.formatDate(this.selectedClientEntryDate);
    },
  },

  watch: {
    selectedContractorID(val, oldval) {
      for (var i = 0; i < this.contractorIDs.length; i++) {
        if (this.contractorIDs[i].contractorid == this.selectedContractorID) {
          this.selectedContractorMisc.retain_gst =
            this.contractorIDs[i].retain_gst;
          break;
        }
      }
    },
  },
  methods: {
    csvExport(arrData) {
      console.log("export csv is click");
      let csvContent = "data:tex/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(","),
        ...arrData.map((item) => Object.values(item).join(",")),
      ].join("\n");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.contractorid.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    depotCustomFilter(item, queryText, itemText) {
      const textOne = item.depotName.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },

    loadContractorMisc: function () {
      axios
        .get(`/misc/loadContractorMisc`)
        .then((response) => {
          this.contractorMiscs = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    loadClientMisc: function () {
      axios
        .get(`/misc/loadClient`)
        .then((response) => {
          this.clientMiscs = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    loadClientDescription: function () {
      axios
        .get(`/misc/loadClientDescription`)
        .then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            this.clientDescriptions.push(response.data[i].description);
          }
          //console.log(this.clientDescriptions);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    loadContractorDescription: function () {
      axios
        .get(`/misc/loadContractorDescription`)
        .then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            this.contractorDescriptions.push(response.data[i].description);
          }
          //console.log(this.contractorDescriptions);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    loadMiscClientID: function () {
      axios
        .get(`/misc/loadClientID`)
        .then((response) => {
          this.clientDepots = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
          //console.log($e);
        });
    },
    loadMiscContractorID: function () {
      var temp;
      axios
        .get(`/misc/loadContractorID`)
        .then((response) => {
          this.contractorIDs = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    contractorSelected(selectedItem) {
      this.selectedContractorMisc = selectedItem;
      this.selectedContractorID = this.selectedContractorMisc.contractor_id;
      this.updateIfNotExist(
        this.contractorDescriptions,
        this.selectedContractorMisc.description
      );
      this.selectedContractorDescription =
        this.selectedContractorMisc.description;
      this.selectedContractorEntryDate = this.ymdHyphen(
        this.selectedContractorMisc.entrydate
      );
      //console.log(this.selectedContractorDescription);
      this.cmodifies = true;
    },
    updateIfNotExist(sArray, str) {
      if (sArray.indexOf(str) === -1) {
        sArray.push(str);
      }
      //console.log(sArray);
    },

    clientSelected(selectedItem) {
      this.selectedClientMisc = selectedItem;
      this.selectedDepot = this.selectedClientMisc.depotname;
      this.selectedClientEntryDate = this.ymdHyphen(
        this.selectedClientMisc.entrydate
      );
      this.updateIfNotExist(
        this.clientDescriptions,
        this.selectedClientMisc.description
      );
      this.selectedClientDescription = this.selectedClientMisc.description;
      //console.log(this.selectedClientMisc);
      this.dmodifies = true;
    },
    confirmDeleteCMisc(selectedItem) {
      this.deleteCMiscDialog = true;
      this.contractorMiscToBeDelete = selectedItem;
    },
    confirmDeleteDMisc(selectedItem) {
      this.deleteDMiscDialog = true;
      this.clientMiscToBeDelete = selectedItem;
    },
    modContractorMisc() {
      if (this.selectedContractorMisc.retain_gst) {
        //calculate total
        this.selectedContractorMisc.total =
          this.selectedContractorMisc.sub_total;
        this.selectedContractorMisc.gst = 0.0;
      } else {
        //total
        this.selectedContractorMisc.total =
          parseInt(this.selectedContractorMisc.sub_total * 1.1 * 100) / 100;
        this.selectedContractorMisc.gst =
          this.selectedContractorMisc.sub_total * 0.1;
      }
      this.selectedContractorMisc.entry_date = this.selectedContractorEntryDate;
      this.selectedContractorMisc.description =
        this.selectedContractorDescription
          .trim()
          .replace(/[\t|\r\n|\r|\n]/gm, "");
      var payload = { data: this.selectedContractorMisc };
      axios
        .post(`/misc/modContractorMisc`, payload)
        .then((response) => {
          if (response.status === 200) {
            this.resetContractorMiscFields();
            //console.log(this.selectedContractorMisc);
          } else {
            alert("some error while adding miscellaneous.");
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
      this.resetContractorMiscFields();
    },
    modClientMisc() {
      //set subtotal
      this.selectedClientMisc.total =
        parseInt(this.selectedClientMisc.sub_total * 1.1 * 100) / 100;
      this.selectedClientMisc.gst = this.selectedClientMisc.sub_total * 0.1;
      this.selectedClientMisc.entry_date = this.selectedClientEntryDate;
      this.selectedClientMisc.description = this.selectedClientDescription
        .trim()
        .replace(/[\t|\r\n|\r|\n]/gm, "");
      var payload = { data: this.selectedClientMisc };
      axios
        .post(`/misc/modClientMisc`, payload)
        .then((response) => {
          if (response.status === 200) {
            this.resetClientMiscFields();
            //console.log(this.selectedClientMisc);
          } else {
            alert("some error while modifying miscellaneous.");
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
      this.resetClientMiscFields();
    },
    resetContractorMiscFields() {
      this.selectedContractorID = {};
      this.cmodifies = false;
      this.selectedContractorMisc = {};
      this.selectedContractorEntryDate = "";
      this.selectedContractorDescription = [];
      this.loadContractorDescription();
    },
    resetClientMiscFields() {
      this.selectedDepot = {};
      this.dmodifies = false;
      this.selectedClientMisc = {};
      this.selectedClientEntryDate = "";
      this.selectedClientDescription = [];
      this.loadClientDescription();
    },
    addClientMisc: function () {
      //add misc into table and database
      this.selectedClientMisc.depotname = this.selectedDepot;
      this.selectedClientMisc.total =
        parseInt(this.selectedClientMisc.sub_total * 1.1 * 100) / 100;
      this.selectedClientMisc.gst = this.selectedClientMisc.sub_total * 0.1;
      this.selectedClientMisc.entry_date = this.selectedClientEntryDate;
      this.selectedClientMisc.description = this.selectedClientDescription
        .trim()
        .replace(/[\t|\r\n|\r|\n]/gm, "");

      var payload = { data: this.selectedClientMisc };
      axios
        .post(`/misc/addClientMisc`, payload)
        .then((response) => {
          if (response.status === 200) {
            this.loadClientMisc();
            this.selectedClientMisc = {};
            this.selectedDepot = {};
            this.resetClientMiscFields();
          } else {
            alert("some error while adding miscellaneous.");
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    deleteClientMisc: function () {
      //remove row in table
      //update database
      var payload = { data: this.clientMiscToBeDelete.invoice_adj_id };
      axios
        .post(`/misc/deleteClientMisc`, payload)
        .then((response) => {
          //remove from table
          this.clientMiscs.splice(
            this.clientMiscs.indexOf(this.clientMiscToBeDelete),
            1
          );
          this.deleteDMiscDialog = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    addContractorMisc: function () {
      this.selectedContractorMisc.contractor_id = this.selectedContractorID;
      if (this.selectedContractorMisc.retain_gst) {
        this.selectedContractorMisc.total =
          this.selectedContractorMisc.sub_total;
        this.selectedContractorMisc.gst = 0.0;
      } else {
        this.selectedContractorMisc.total =
          parseInt(this.selectedContractorMisc.sub_total * 1.1 * 100) / 100;
        this.selectedContractorMisc.gst =
          this.selectedContractorMisc.sub_total * 0.1;
      }
      this.selectedContractorMisc.entry_date = this.selectedContractorEntryDate;
      this.selectedContractorMisc.description =
        this.selectedContractorDescription
          .trim()
          .replace(/[\t|\r\n|\r|\n]/gm, "");
      var payload = { data: this.selectedContractorMisc };
      axios
        .post(`/misc/addContractorMisc`, payload)
        .then((response) => {
          if (response.status === 200) {
            //add new misc to table
            this.loadContractorMisc();
            this.resetContractorMiscFields();
          } else {
            alert("some error while adding miscellaneous.");
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    deleteContractorMisc: function () {
      var payload = { data: this.contractorMiscToBeDelete.payslip_adj_id };
      axios
        .post(`/misc/deleteContractorMisc`, payload)
        .then((response) => {
          //remove from table
          this.contractorMiscs.splice(
            this.contractorMiscs.indexOf(this.contractorMiscToBeDelete),
            1
          );
          this.deleteCMiscDialog = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    ymdHyphen(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatDate(tdate) {
      if (!tdate) return null;

      const [year, month, day] = tdate.split("-");
      return `${day}/${month}/${year}`;
    },
  },

  data() {
    return {
      //declare attributes
      clientTable: 0,
      contractorTable: 0,
      menuDate: false,
      clientMenuDate: false,
      selectedContractorEntryDate: "",
      selectedClientEntryDate: "",
      contractorMiscs: [],
      contractorIDs: [],
      selectedContractorID: {},
      selectedContractorMisc: {},
      selectedContractorDescription: [],
      search: null,
      selectedClientDescription: [],
      contractorMiscToBeDelete: {},
      deleteCMiscDialog: false,
      contractorFields: [
        { value: "contractor_id", sortable: true, text: "Contractor" },
        {
          value: "entrydate",
          align: "left",
          sortable: true,
          text: "Entry Date",
        },
        { value: "description", sortable: false, text: "Description" },
        { value: "sub_amount", sortable: false, text: "Subtotal" },
        { value: "total", text: "Total" },
      ],
      clientMiscs: [],
      clientDepots: [],
      clientDescriptions: [],
      contractorDescriptions: [],
      selectedDepot: {},
      selectedClientMisc: {},
      clientMiscToBeDelete: {},
      deleteDMiscDialog: false,
      clientFields: [
        { value: "clientID", sortable: true, text: "Client" },
        { value: "date", sortable: true, text: "Entry Date" },
        { value: "description", sortable: false, text: "Description" },
        { value: "subamount", sortable: false, text: "Sub-total" },
        { value: "amount", sortable: false, text: "Total Amount" },
        { value: "delete", text: "" },
      ],
      errors: [],
      dmodifies: false,
      cmodifies: false,
      rules: {
        required: (value) => !!value || "Required",
        subtotal: (value) => {
          const pattern = /^[-+]?([0-9]*\.?[0-9]*)$/;
          return pattern.test(value) || "Amount need to be in number";
        },
      },
    };
  },
};
</script>
