<template>
  <v-container id="userdashboard" grid-list-md fluid>
    <v-card>
      <v-card-title class="headline" title--text>
        Dashboard
        <v-spacer></v-spacer>
        <v-layout raw wrap align-center justify-center>
          <v-flex xs10 sm3 md4>
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="startDateFormatted"
                  label="Start Date"
                  persistent-hint
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                @input="startDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs10 sm3 md4>
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="endDateFormatted"
                  label="End Date"
                  persistent-hint
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                @input="endDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-spacer></v-spacer>
        <v-btn-toggle borderless dense v-model="historySelection">
          <v-btn text @click="changeInterval(6)">Week</v-btn>
          <v-btn text @click="changeInterval(29)">30 Days</v-btn>
          <v-btn text @click="changeInterval(89)">90 Days</v-btn>
          <v-btn text @click="changeInterval(daysSinceFY())">EoFY</v-btn>
        </v-btn-toggle>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <contractor-table :startDate="startDate" :endDate="endDate">
            </contractor-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ContractorTable from "./ContractorTable.vue";
export default {
  components: {
    "contractor-table": ContractorTable,
  },

  data() {
    return {
      historySelection: 0,
      //days: { 0: 30, 1: 90, 2: this.daysSinceFY() },
      startDateMenu: false,
      endDateMenu: false,
      endDateDT: new Date(),
      startDateDT: new Date(new Date().setDate(new Date().getDate() - 6)),
      startDate: "",
      endDate: "",
      startDateFormatted: "",
      endDateFormatted: "",
    };
  },
  created() {
    this.startDate = this.parseDate(this.startDateDT);
    this.endDate = this.parseDate(this.endDateDT);
    this.startDateFormatted = this.formatDate(this.startDate);
    this.endDateFormatted = this.formatDate(this.endDate);
  },
  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },
    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
    startDateDT(val) {
      this.startDate = this.parseDate(this.startDateDT);
    },
    endDateDT(val) {
      this.endDate = this.parseDate(this.endDateDT);
    },
  },
  computed: {
    history() {
      return this.days[this.historySelection || 0];
    },
  },

  methods: {
    daysSinceFY() {
      let today = new Date();
      let startDate;
      if (today.getMonth() > 5) {
        startDate = new Date(today.getFullYear(), 6, 1);
      } else {
        startDate = new Date(today.getFullYear() - 1, 6, 1);
      }
      return Math.floor(
        (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
      );
    },

    changeInterval(interval) {
      (this.endDateDT = new Date()),
        (this.startDateDT = new Date(
          new Date().setDate(new Date().getDate() - interval)
        )),
        (this.startDate = this.parseDate(this.startDateDT));
      this.endDate = this.parseDate(this.endDateDT);
      this.startDateFormatted = this.formatDate(this.startDate);
      this.endDateFormatted = this.formatDate(this.endDate);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      date = date.toLocaleDateString("en-AU");
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
