<template>
  <v-container id="contractor" grid-list-md fluid>
    <v-layout justify-center>
      <v-flex sm12 md6>
        <!-- Contractor Form -->
        <contractor-form width="60%" :contractorID="contractorID"></contractor-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Form from "./Form.vue";

export default {
  components: {
    "contractor-form": Form,
  },

  computed: {
    contractorID() {
      return !this.$store.getters.user
        ? false
        : this.$store.getters.user.username;
    },
  },
};
</script>

<style></style>
