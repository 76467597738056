<template>
  <v-layout row wrap>
    <v-dialog v-model="vehicleDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="vehicle">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    v-model="editedVehicle.rego"
                    :readonly="this.editedVechileIndex > -1"
                    label="Rego"
                    :rules="[
                      rules.required,
                      rules.notBlank,
                      rules.notDuplicateRego,
                    ]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    type="number"
                    class="vehicleType"
                    v-model="editedVehicle.vehicle_type"
                    :rules="[rules.required, rules.validInt]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-menu
                    v-model="regoDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedDateFormatted"
                        label="Rego Date"
                        hint="DD/MM/YYYY format"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedVehicle.rego_date"
                      no-title
                      @input="regoDateMenu = false"
                      :rules="[rules.required]"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeVehicle">
            Cancel
            <v-icon right dark>cancel</v-icon>
          </v-btn>
          <v-btn class="success" text @click="saveVehicle">
            Save
            <v-icon right dark>save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="vehicleDeleteDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text
          >Confirm deletion of {{ vehicleToBeDeleted.ContractorID }}'s' vehicle
          {{ vehicleToBeDeleted.rego }} and it's rates</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="success" text @click="vehicleDeleteDialog = false">
            Cancel
            <v-icon right dark>cancel</v-icon>
          </v-btn>
          <v-btn class="error" text @click="deleteVehicle">
            Delete
            <v-icon right dark>delete_forever</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-flex flex-column xs12 sm12 md12>
      <v-card>
        <v-card-title class="headline" title--text>
          {{ $route.params.id }} Vehicles
          <v-spacer></v-spacer>
          <v-btn fab dark small color="indigo" @click="addVehicle">
            <v-icon dark>add</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-if="vehicles[0]"
            :headers="vechicleHeaders"
            :items="vehicles"
          >
            <template v-slot:item="props">
              <tr
                @click="vehicleSelected(props.item)"
                :class="{
                  active:
                    !!selectedVehicle &&
                    selectedVehicle.rego == props.item.rego,
                }"
              >
                <td align="right">{{ props.item.rego }}</td>
                <td>{{ props.item.vehicle_type }} Tons</td>
                <td>
                  <v-icon class="mr-2" @click="editVehicle(props.item)"
                    >edit</v-icon
                  >
                  <v-icon @click="prepareVehicleDelete(props.item)"
                    >delete</v-icon
                  >
                </td>
              </tr>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning"
                >No vehicles found for contractor:
                {{ $route.params.id }}</v-alert
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <rates :contractorID="contractorID" :rego="selectedVehicle.rego"></rates>
  </v-layout>
</template>

<script>
import axios from "axios";
import Rates from "./Rates.vue";

export default {
  components: {
    rates: Rates,
  },
  props: ["contractorID"],

  data() {
    return {
      vehicles: [],
      vehiclePagination: {
        rowsPerPage: 5,
        sortBy: "Rego",
      },
      selectedVehicle: {},
      vechicleHeaders: [
        {
          value: "rego",
          align: "end",
          sortable: true,
          text: "Rego",
        },
        {
          value: "vehicle_type",
          align: "left",
          sortable: true,
          text: "Type",
        },
        {
          //value: "name",
          align: "left",
          sortable: false,
          text: "Action",
        },
      ],
      vehicleDialog: false,
      vehicleDeleteDialog: false,
      editedVechileIndex: -1,
      vehicleToBeDeleted: {},
      editedVehicle: {
        rego: "",
        ContractorID: this.contractorID,
        vehicle_type: 1,
        rego_date: this.parseDate(new Date().toLocaleDateString("en-AU")),
      },

      defaultVehicle: {
        rego: "",
        ContractorID: this.contractorID,
        vehicle_type: 1,
        rego_date: this.parseDate(new Date().toLocaleDateString("en-AU")),
      },
      regoDateMenu: false,
      rules: {
        required: (value) => !!value || "Required.",
        notBlank: (value) => value !== "" || "Can't be blank",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        notDuplicateRego: (rego) => {
          return (
            this.editedVechileIndex !== -1 ||
            this.duplicateRego(rego) !== true ||
            "Already exists"
          );
        },
        validInt: (int) => {
          return parseInt(int) > 0 || "Not valid";
        },
      },
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
      errors: [],
    };
  },

  watch: {
    rego() {
      this.editedVehicle.rego = this.rego.toUpperCase();
    },
    vehicleDialog(newVal, oldVal) {
      if (this.editedVechileIndex === -1 && newVal === true) {
        this.editedVehicle = Object.assign({}, this.defaultVehicle);
        this.editedVechileIndex = -1;
        if (this.$refs.vehicle) {
          this.$refs.vehicle.resetValidation();
        }
      }
    },
  },

  computed: {
    formTitle() {
      return this.editedVechileIndex === -1 ? "Add Vehicle" : "Edit Vehicle";
    },
    computedDateFormatted() {
      return this.formatDate(this.editedVehicle.rego_date);
    },
    rego() {
      return this.editedVehicle.rego;
    },
  },

  created() {
    this.loadVehicles();
  },
  methods: {
    loadVehicles() {
      axios
        .get(`/contractor/${this.contractorID}/vehicles`)
        .then((response) => {
          this.vehicles = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    vehicleSelected(selctedVehicle) {
      this.selectedVehicle = selctedVehicle;
    },
    addVehicle() {
      this.editedVechileIndex = -1;
      this.vehicleDialog = true;
    },
    editVehicle(vehicle) {
      this.editedVechileIndex = this.vehicles.indexOf(vehicle);
      this.editedVehicle = Object.assign({}, vehicle);
      this.vehicleDialog = true;
    },

    closeVehicle() {
      this.vehicleDialog = false;
    },

    duplicateRego(rego) {
      let foundDuplicate = false;
      this.vehicles.forEach((vehicle) => {
        if (vehicle.rego == rego) {
          foundDuplicate = true;
        }
      });
      return foundDuplicate;
    },

    saveVehicle() {
      if (this.$refs.vehicle.validate()) {
        if (this.editedVechileIndex > -1) {
          axios
            .put(
              "/contractor/" +
                this.contractorID +
                "/vehicle/" +
                this.editedVehicle.rego,
              this.editedVehicle
            )
            .then((res) => {
              if (res.status === 201) {
                this.snackbar.color = "success";
                this.snackbar.text =
                  "Vehicle " + this.editedVehicle.rego + " updated";
                this.snackbar.visible = true;
                this.loadVehicles();
                this.selectedVehicle = {};
                this.closeVehicle();
              }
            })
            .catch((e) => {
              this.errors.push(e);
              this.snackbar.color = "error";
              this.snackbar.text = this.errors.pop();
              this.snackbar.visible = true;
            });
        } else {
          axios
            .post(
              "/contractor/" +
                this.contractorID +
                "/vehicle/" +
                this.editedVehicle.rego,
              this.editedVehicle
            )
            .then((res) => {
              if (res.status === 201) {
                this.snackbar.color = "success";
                this.snackbar.text =
                  "Vehicle " + this.editedVehicle.rego + " added";
                this.snackbar.visible = true;
                this.loadVehicles();
                this.closeVehicle();
              }
            })
            .catch((e) => {
              this.errors.push(e);
              this.snackbar.color = "error";
              this.snackbar.text = this.errors.pop();
              this.snackbar.visible = true;
            });
        }
      }
    },
    prepareVehicleDelete(vehicle) {
      this.vehicleToBeDeleted = vehicle;
      this.vehicleDeleteDialog = true;
    },
    deleteVehicle() {
      this.vehicleDeleteDialog = false;
      console.log(this.vehicleToBeDeleted);
      axios
        .delete(
          "/contractor/" +
            this.contractorID +
            "/vehicle/" +
            this.vehicleToBeDeleted.rego,
          this.vehicleToBeDeleted
        )
        .then((res) => {
          if (res.status === 201) {
            this.snackbar.color = "success";
            this.snackbar.text =
              "Vehicle " + this.vehicleToBeDeleted.rego + " deleted";
            this.snackbar.visible = true;
            this.loadVehicles();
            this.selectedVehicle.rego = "";
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.snackbar.color = "error";
          this.snackbar.text = this.errors.pop();
          this.snackbar.visible = true;
        });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
.theme--dark.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.active {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.active {
  background: #a1caff;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #a1caff;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}
</style>
