<template>
    <v-flex xs12 sm6 md4 lg3>
        <v-toolbar color="blue accent-1">
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-list four-line dense>
            <template v-for="contractor in availableContractors">
                <v-list-item :key="contractor.ContractorID" ripple class="available_contractor my-1">
                    <v-list-item-content>
                        <v-list-item-title>{{ contractor.ContractorID }}
                            {{ contractor.PreferedName || contractor.FirstName }} {{  contractor.LastName }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn dark text v-bind="attrs" @click="snackbar.visible = false">Close</v-btn>
            </template>
        </v-snackbar>
    </v-flex>
</template>

<script>
import axios from "axios";
export default {
    props: {
        client: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            availableContractors: [],
            errors: [],
            snackbar: {
                visible: false,
                color: "success",
                timeout: 3000,
                text: "",
            },
            interval: 0,
            loadAvailableContractorsRequest: 0
        };
    },

    watch: {
        title: function () {
            this.loadAvailableContractors();
        },
        client: function () {
            this.loadAvailableContractors();
        },
    },

    created() {

        this.loadAvailableContractors();
    },

    mounted() {
        this.loadAvailableContractors();
        this.interval = setInterval(function () {
            this.loadAvailableContractors()
        }.bind(this), 30000);
    },
    destroyed() {
        clearInterval(this.interval)
    },

    methods: {
        loadAvailableContractors() {
            this.cancelLoadAvailableContractorsRequest();
            const startDate = this.sqlDate(this.title)
            let axiosSource = axios.CancelToken.source();
            this.loadAvailableContractorsRequest = { cancel: axiosSource.cancel };
            axios
                .get(
                    "/contractor/available/" +
                    this.client +
                    "/" +
                    startDate, { cancelToken: axiosSource.token, }
                )
                .then((response) => {
                    this.availableContractors = response.data;

                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        cancelLoadAvailableContractorsRequest() {
            if (this.loadAvailableContractorsRequest) {
                this.loadAvailableContractorsRequest.cancel();
            }
        },


        times(job) {
            let start_time = this.chopSeconds(job.Start_Time);
            let finish_time = this.chopSeconds(job.finish_time);
            if (job.rate_type === "flat") {
                let percentage_day =
                    Math.round(((job.client_subtotal / job.client_rate) * 100) / 25) * 25;
                return "Flat: " + percentage_day + "%";
            } else if (job.rate_type === "piece") {
                let pieces = Math.round(job.client_subtotal / job.client_rate);
                return "Pieces: " + pieces;
            } else if (job.rate_type === "stop") {
                let stops = Math.round(job.client_subtotal / job.client_rate);
                return "Stops: " + stops;
            } else {
                return "Hourly: " + start_time + " to " + finish_time;
            }
        },
        chopSeconds(time) {
            return time.split(":", 2).join(":");
        },
        modifyJob(JobID) {
            this.$router.push({ path: `/job/${JobID}` });
        },
        sqlDate(dateString) {
            let date = new Date(dateString);
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            return year + "-" + month + "-" + day;
        },
    },
};
</script>

<style scoped>
.available_contractor {
    background-color: #e8f5e9;
}
</style>
