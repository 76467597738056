<template>
  <v-container id="Payslip" grid-list-md fluid>
    <!-- <v-dialog v-model="payslipDialog" max-width="960">
      <payslip-pdf :JobEntries="JobEntries" :selectedContractor="selectedContractor"></payslip-pdf>
    </v-dialog>-->

    <v-flex xs12 sm12 md12>
      <v-card>
        <v-card-title class="headline" title--text
          >Generate Payslip</v-card-title
        >
        <v-card-text>
          <v-form>
            <!--v-layout raw wrap align-center justify-center>
                          <v-subheader>Payslip's Date</v-subheader>
            </v-layout-->
            <v-layout raw wrap align-center justify-center>
              <!--v-layout align-start justify-center row reverse fill-height-->
              <!--v-subheader>Payslip's Date: </v-subheader-->
              <!--v-spacer></v-spacer-->
              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startDateFormatted"
                      label="Start Date"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    @input="startDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDateFormatted"
                      label="End Date"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    no-title
                    @input="endDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <!--v-subheader>Payment Date: </v-subheader-->
              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="paymentDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="paymentDateFormatted"
                      label="Payment Date"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="paymentDate"
                    no-title
                    @input="paymentDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <!--v-subheader>Contractor Name: </v-subheader-->
              <v-flex xs12 sm6 md3>
                <v-select
                  :loading="contractorNamesLoading"
                  prepend-icon="person"
                  v-model="contractorName"
                  label="Contractor Name"
                  :items="contractorNames"
                ></v-select>
                <!--v-alert
                  :value="true"
                  type="error"
                  v-if="!computedIsSameFinancialYear"
                >Start and End Date shall be within the same financial year !</v-alert-->
              </v-flex>
              <!--v-subheader>Send Date: </v-subheader-->
              <v-flex xs12 sm6 md2>
                <v-menu
                  v-model="sendDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="sendDateFormatted"
                      label="To Be Sent On"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="sendDate"
                    no-title
                    @input="sendDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <!--v-flex xs12 sm6 md2>
                <v-switch inset v-model="showUnpaidOnly" @change="loadClients" label="Show Unpaid Only"></v-switch>
                <v-switch inset v-model="showUnpaidOnly" label="Show Unpaid Only"></v-switch>
              </v-flex-->
            </v-layout>
          </v-form>

          <!--This is for Jobs Entries -->
          <v-subheader>Job(s)</v-subheader>
          <v-data-table
            :headers="jobEntriesHeader"
            sortBy="DepotName"
            :items="JobEntries"
            style="width: 100%"
          >
            <template v-slot:item="props">
              <!--tr
                :active="!!selectedJobEntries.DepotName && selectedJobEntries.DepotName == props.item.DepotName && selectedJobEntries.StartDate == props.item.StartDate"
                @click="jobEntriesSelected(props.item)"
              -->
              <tr>
                <td class="body-2" width="1%">
                  {{ props.item.contractor_ContractorID }}
                </td>
                <td class="body-2" width="1%">{{ props.item.DepotName }}</td>
                <td class="body-2" width="18%">{{ props.item.niceStart }}</td>
                <td class="body-2" width="1%">
                  {{ props.item.niceStartTime }}
                </td>
                <td class="body-2" width="1%">{{ props.item.niceEndTime }}</td>
                <td class="body-2" width="5%">
                  {{ props.item.contractor_vehicle_type }}
                </td>
                <td class="body-2" width="12%">
                  $
                  {{
                    formatCurrency(
                      parseFloat(props.item.contractor_rate).toFixed(2)
                    )
                  }}
                </td>
                <td class="body-2" width="8%">
                  $
                  {{
                    formatCurrency(
                      parseFloat(props.item.contractor_fuel_levy).toFixed(2)
                    )
                  }}
                </td>
                <td class="body-2" width="8%">
                  $
                  {{
                    formatCurrency(
                      parseFloat(props.item.toll_charge).toFixed(2)
                    )
                  }}
                </td>
                <td class="body-2" width="11%">
                  $
                  {{
                    formatCurrency(
                      parseFloat(props.item.contractor_subtotal).toFixed(2)
                    )
                  }}
                </td>
                <td class="body-2" width="15%">
                  $
                  {{
                    formatCurrency(
                      parseFloat(props.item.contractor_gst).toFixed(2)
                    )
                  }}
                </td>
                <!--td class="body-2">$ {{ formatCurrency(parseFloat(props.item.contractor_total).toFixed(2) }} </td-->
                <td
                  class="body-2"
                  width="15%"
                  v-if="!selectedContractor.retain_gst"
                >
                  $
                  {{
                    formatCurrency(
                      parseFloat(
                        props.item.contractor_fuel_levy +
                          props.item.toll_charge +
                          props.item.contractor_subtotal +
                          props.item.contractor_gst
                      ).toFixed(2)
                    )
                  }}
                </td>
                <td
                  class="body-2"
                  width="15%"
                  v-if="selectedContractor.retain_gst"
                >
                  $
                  {{
                    formatCurrency(
                      parseFloat(
                        props.item.contractor_fuel_levy +
                          props.item.toll_charge +
                          props.item.contractor_subtotal
                      ).toFixed(2)
                    )
                  }}
                </td>
              </tr>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning"
                >Job(s) Entries for "{{ this.ContractorName }}" is not
                found.</v-alert
              >
            </template>
          </v-data-table>

          <!--This is for Misc Entries -->
          <v-subheader>Miscellaneous</v-subheader>
          <v-data-table
            :headers="miscEntriesHeader"
            :items-per-page="5"
            sortBy="MiscEntryDate"
            :items="MiscEntries"
          >
            <template v-slot:item="props">
              <tr>
                <td class="body-2">{{ props.item.contractor_id }}</td>
                <td class="body-2">{{ props.item.formattedDate }}</td>
                <td class="body-2">{{ props.item.description }}</td>
                <td class="body-2">
                  $
                  {{
                    formatCurrency(parseFloat(props.item.sub_total).toFixed(2))
                  }}
                </td>
                <td class="body-2">
                  $ {{ formatCurrency(parseFloat(props.item.gst).toFixed(2)) }}
                </td>
                <!--td class="body-2">$ {{ parseFloat(props.item.total).toFixed(2) }}</td-->
                <td class="body-2" v-if="!selectedContractor.retain_gst">
                  $
                  {{
                    formatCurrency(
                      parseFloat(props.item.sub_total + props.item.gst).toFixed(
                        2
                      )
                    )
                  }}
                </td>
                <td class="body-2" v-if="selectedContractor.retain_gst">
                  $
                  {{
                    formatCurrency(parseFloat(props.item.sub_total).toFixed(2))
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-divider></v-divider>

          <v-layout raw wrap align-center justify-center>
            <v-subheader>Payment Summary</v-subheader>
          </v-layout>

          <v-layout raw wrap align-end justify-space-around>
            <v-flex md2>
              <v-layout column align-space-around justify-end>
                <v-text-field
                  v-if="!selectedContractor.retain_gst"
                  readonly
                  prefix="$"
                  :value="formatCurrency(parseFloat(computeGST).toFixed(2))"
                  label="GST"
                ></v-text-field>
                <v-text-field
                  v-if="selectedContractor.retain_gst"
                  readonly
                  prefix="$"
                  :value="formatCurrency(parseFloat(0).toFixed(2))"
                  label="GST"
                ></v-text-field>
                <v-text-field
                  v-if="!selectedContractor.retain_gst"
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(
                      parseFloat(YTDAmount.totalGST || 0).toFixed(2)
                    )
                  "
                  label="YTD GST"
                ></v-text-field>
                <v-text-field
                  v-if="selectedContractor.retain_gst"
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(
                      parseFloat(YTDAmount.totalGST || 0).toFixed(2)
                    )
                  "
                  label="YTD GST"
                ></v-text-field>
              </v-layout>
            </v-flex>
            <v-flex md2>
              <v-layout column align-space-around justify-end>
                <v-text-field
                  readonly
                  prefix="$"
                  :value="formatCurrency(parseFloat(computeToll).toFixed(2))"
                  label="Toll"
                ></v-text-field>
                <v-text-field
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(
                      parseFloat(YTDAmount.totalToll || 0).toFixed(2)
                    )
                  "
                  label="YTD Toll"
                ></v-text-field>
              </v-layout>
            </v-flex>
            <v-flex md2>
              <v-layout column align-space-around justify-end>
                <v-text-field
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(parseFloat(computeFuelLevy).toFixed(2))
                  "
                  label="Fuel Levy"
                ></v-text-field>
                <v-text-field
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(
                      parseFloat(YTDAmount.totalFuelLevy || 0).toFixed(2)
                    )
                  "
                  label="YTD Fuel Levy"
                ></v-text-field>
              </v-layout>
            </v-flex>
            <v-flex md2>
              <v-layout column align-space-around justify-end>
                <v-text-field
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(parseFloat(computeSubTotal).toFixed(2))
                  "
                  label="Service"
                ></v-text-field>
                <v-text-field
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(
                      parseFloat(YTDAmount.totalService || 0).toFixed(2)
                    )
                  "
                  label="YTD Service"
                ></v-text-field>
              </v-layout>
            </v-flex>
            <v-flex md2>
              <v-layout column align-space-around justify-end>
                <v-text-field
                  v-if="!selectedContractor.retain_gst"
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(
                      parseFloat(
                        computeToll +
                          computeFuelLevy +
                          computeSubTotal +
                          computeGST
                      ).toFixed(2)
                    )
                  "
                  label="Total"
                ></v-text-field>
                <v-text-field
                  v-if="selectedContractor.retain_gst"
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(
                      parseFloat(
                        computeToll + computeFuelLevy + computeSubTotal
                      ).toFixed(2)
                    )
                  "
                  label="Total"
                ></v-text-field>
                <v-text-field
                  v-if="!selectedContractor.retain_gst"
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(
                      formatCurrency(
                        parseFloat(YTDAmount.grandTotal || 0).toFixed(2)
                      )
                    )
                  "
                  label="YTD Total"
                ></v-text-field>
                <v-text-field
                  v-if="selectedContractor.retain_gst"
                  readonly
                  prefix="$"
                  :value="
                    formatCurrency(
                      formatCurrency(
                        parseFloat(YTDAmount.grandTotal || 0).toFixed(2)
                      )
                    )
                  "
                  label="YTD Total"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn @click="payslipDialog = true" right dark color="blue">Preview Invoice</v-btn> -->
            <!--v-btn @click="downloadPdf" right dark color="blue">Payslip-Test</v-btn-->
            <v-btn
              :disabled="beInProgress || contractorName === ''"
              :dark="!beInProgress && contractorName !== ''"
              @click="commitPayslip(true)"
              right
              color="green"
              >Preview Payslip</v-btn
            >
            <v-btn
              :disabled="payslipId === 0"
              :dark="payslipId !== 0"
              @click="downloadPdf()"
              right
              color="green"
              >Download PDF</v-btn
            >
            <v-btn
              :disabled="beInProgress || contractorName === ''"
              :dark="!beInProgress && contractorName !== ''"
              @click="commitPayslip()"
              right
              color="blue"
              >Generate Payslip</v-btn
            >
            <!--{{selectedContractor}}-->
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import router from "../../router";
import PayslipView from "./PayslipView.vue";
//import moment from "moment";

export default {
  // components: {
  //   "payslip-pdf": PayslipView
  // },

  data() {
    return {
      error: [],
      Pay: {},
      //startDate: new Date().toISOString().substr(0, 10),
      startDate: this.parseDate(new Date().toLocaleDateString("en-AU")),
      /*startDateFormatted: this.formatDate(
        new Date().toISOString().substr(0, 10)
      ),*/
      startDateFormatted: new Date().toLocaleDateString("en-AU"),
      //endDate: new Date().toISOString().substr(0, 10),
      //endDateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      endDate: this.parseDate(new Date().toLocaleDateString("en-AU")),
      endDateFormatted: new Date().toLocaleDateString("en-AU"),
      //paymentDate: new Date().toISOString().substr(0, 10),
      /*paymentDateFormatted: this.formatDate(
        new Date().toISOString().substr(0, 10)
      ),*/
      paymentDate: this.parseDate(new Date().toLocaleDateString("en-AU")),
      paymentDateFormatted: new Date().toLocaleDateString("en-AU"),
      /*sendDate: new Date().toISOString().substr(0, 10),
      sendDateFormatted: this.formatDate(
        new Date().toISOString().substr(0, 10)
      ),*/
      sendDate: this.parseDate(new Date().toLocaleDateString("en-AU")),
      sendDateFormatted: new Date().toLocaleDateString("en-AU"),
      startDateMenu: false,
      endDateMenu: false,
      paymentDateMenu: false,
      sendDateMenu: false,
      contractorNamesLoading: false,
      Contractors: [],
      contractorNames: [],
      contractorName: "",
      ContractorID: [],
      selectedContractor: {},
      selectedContractorID: "",
      payslipDialog: false,
      YTDAmount: {},
      payslipId: 0,
      previewPayslipId: 0,

      JobEntries: [],
      selectedJobEntries: {},

      //search: "",
      showUnpaidOnly: false,
      jobEntriesHeader: [
        {
          value: "ContractorID",
          align: "left",
          sortable: false,
          text: "Contractor ID",
        },
        { value: "DepotName", align: "left", sortable: true, text: "Depot" },
        { value: "StartDate", align: "left", sortable: true, text: "Date" },
        { value: "StartTime", align: "left", sortable: true, text: "Start" },
        { value: "EndTime", align: "left", sortable: true, text: "End" },
        { value: "VT", align: "left", sortable: true, text: "VT" },
        { value: "Rate", align: "left", sortable: true, text: "Rate" },
        { value: "FuelLevy", align: "left", sortable: true, text: "Fuel Levy" },
        { value: "Toll", align: "left", sortable: true, text: "Toll" },
        { value: "Subtotal", align: "left", sortable: true, text: "Subtotal" },
        { value: "GST", align: "left", sortable: true, text: "GST" },
        { value: "Total", align: "left", sortable: true, text: "Total" },
      ],
      MiscEntries: [],
      miscEntriesHeader: [
        {
          value: "ContractorID",
          align: "left",
          sortable: false,
          text: "Contractor ID",
        },
        { value: "MiscEntryDate", align: "left", sortable: true, text: "Date" },
        {
          value: "MiscDesc",
          align: "left",
          sortable: true,
          text: "Description",
        },
        {
          value: "MiscSubtotal",
          align: "left",
          sortable: true,
          text: "Subtotal",
        },
        { value: "MiscGST", align: "left", sortable: true, text: "GST" },
        { value: "MiscTotal", align: "left", sortable: true, text: "Total" },
      ],
      beInProgress: false,
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
      errors: [],
    };
  },

  created() {
    this.loadContractors();
    this.contractorName = "";
  },

  computed: {
    computedStartDateFormatted() {
      return this.formatDate(this.startDate);
    },
    computedEndDateFormatted() {
      return this.formatDate(this.endDate);
    },
    computedPaymentDateFormatted() {
      return this.formatDate(this.paymentDate);
    },
    computedSendDateFormatted() {
      return this.formatDate(this.sendDate);
    },
    computeGST() {
      return (
        this.JobEntries.reduce((acc, item) => acc + item.contractor_gst, 0) +
        this.MiscEntries.reduce((acc, item) => acc + item.gst, 0)
      );
    },
    computeToll() {
      return this.JobEntries.reduce((acc, item) => acc + item.toll_charge, 0);
    },
    computeFuelLevy() {
      return this.JobEntries.reduce(
        (acc, item) => acc + item.contractor_fuel_levy,
        0
      );
    },
    computeSubTotal() {
      return (
        this.JobEntries.reduce(
          (acc, item) => acc + item.contractor_subtotal,
          0
        ) + this.MiscEntries.reduce((acc, item) => acc + item.sub_total, 0)
      );
    },
  },

  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
      this.contractorName = "";
      this.loadContractors();
      this.JobEntries = [];
      this.MiscEntries = [];
      this.YTDAmount = {};
    },
    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
      this.contractorName = "";
      this.loadContractors();
      this.JobEntries = [];
      this.MiscEntries = [];
      this.YTDAmount = {};
    },
    paymentDate(val) {
      this.paymentDateFormatted = this.formatDate(this.paymentDate);
    },
    sendDate(val) {
      this.sendDateFormatted = this.formatDate(this.sendDate);
    },
    contractorName() {
      this.contractorSelected();
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    formatCurrency(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    checkStartEndFinancialYear(startDate, endDate) {
      var start = startDate.split("-");
      var end = endDate.split("-");
      var startNext = (parseInt(start[0]) + 1).toString();
      var endNext = (parseInt(end[0]) + 1).toString();
      var startPrev = (parseInt(start[0]) - 1).toString();
      var endPrev = (parseInt(end[0]) - 1).toString();
      var s_date = 0;
      var e_date = 0;
      var resultArray = new Array(4);

      if (
        startDate >= start[0].concat("-07-01") &&
        startDate <= startNext.concat("-06-30")
      ) {
        resultArray[0] = start[0].concat("-07-01");
        resultArray[2] = start[0];
        s_date = 2;
      } else if (
        startDate >= startPrev.concat("-07-01") &&
        startDate <= start[0].concat("-06-30")
      ) {
        resultArray[0] = startPrev.concat("-07-01");
        resultArray[2] = startPrev;
        s_date = 1;
      }

      if (
        endDate >= end[0].concat("-07-01") &&
        endDate <= endNext.concat("-06-30")
      ) {
        resultArray[1] = endNext.concat("-06-30");
        resultArray[3] = endNext;
        e_date = 4;
      } else if (
        endDate >= endPrev.concat("-07-01") &&
        endDate <= end[0].concat("-06-30")
      ) {
        resultArray[1] = end[0].concat("-06-30");
        resultArray[3] = end[0];
        e_date = 3;
      }
      //Ensure date range does not cross 2 FY.
      if (e_date - s_date > 2) {
        resultArray[1] = end[0].concat("-06-30");
        resultArray[3] = end[0];
      }
      return resultArray;
    },

    loadContractors() {
      this.contractorNamesLoading = true;
      //this.contractorNames = [];
      this.contractorNames.splice(0, this.contractorNames.length);

      let dateRange = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      axios
        .get(
          "/payslip/retrieveContractor?startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate
        )
        .then((response) => {
          response.data.forEach((contractor) => {
            this.contractorNames.push(
              contractor.FirstName + " (" + contractor.ContractorID + ")"
            );
            this.contractorNames.sort();
            this.Contractors.push({
              contractorName: contractor.ContractorID,
            });

            this.contractorNamesLoading = false;
          });
        })
        .catch((e) => {
          this.error.push(e);
          this.contractorNamesLoading = false;
        });
    },

    loadContractor() {
      axios
        .get("/contractor/" + this.selectedContractorID)
        .then((response) => {
          this.selectedContractor = response.data[0];
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    contractorSelected() {
      if (this.contractorName) {
        var tempID1 = this.contractorName;
        var tempID2 = tempID1.split("(");
        var conID = tempID2[1].split(")");
        this.selectedContractorID = conID[0];
        this.loadContractor();
        this.loadJobsEntries();
        this.loadMiscEntries();
        this.loadYTDAmount();
      } else {
        this.selectedContractorID = "";
      }
    },

    //Methods for Job Entries
    loadJobsEntries() {
      this.jobsLoading = true;
      //console.log("Load Job Entries");
      this.JobEntries = [];

      axios
        .get(
          "/payslip/" +
            this.selectedContractorID +
            "/" +
            this.startDate +
            "/" +
            this.endDate +
            "/jobEntries"
        )
        .then((response) => {
          this.JobEntries = response.data;

          this.sDate.sort();
          this.sTime.sort();
          this.eTime.sort();
          this.jobsLoading = false;
        })
        .catch((e) => {
          this.error.push(e);
          this.jobsLoading = false;
        });
    },

    //Methods for Misc Entries
    loadMiscEntries() {
      this.miscLoading = true;
      //console.log("Load Misc Entries");
      this.MiscEntries = [];

      axios
        .get(
          "/payslip/" +
            this.selectedContractorID +
            "/" +
            this.startDate +
            "/" +
            this.endDate +
            "/miscEntries"
        )
        .then((response) => {
          this.MiscEntries = response.data;
          this.miscLoading = false;
        })
        .catch((e) => {
          this.error.push(e);
          this.miscLoading = false;
        });
    },

    loadYTDAmount() {
      this.ytdLoading = true;
      //console.log("Load YTD Amount");
      var FYArray = this.checkStartEndFinancialYear(
        this.startDate,
        this.endDate
      );

      if (this.endDate < FYArray[1]) {
        FYArray[1] = this.endDate;
      }

      axios
        .get(
          "/payslip/" +
            this.selectedContractorID +
            "/" +
            FYArray[0] +
            "/" +
            FYArray[1] +
            "/YTDAmount"
        )
        .then((response) => {
          this.YTDAmount = response.data[0];
          isNaN(this.YTDAmount) ? 0 : this.YTDAmount;
          this.ytdLoading = false;
        })
        .catch((e) => {
          this.error.push(e);
          this.ytdLoading = false;
        });
      //console.log(response.data[0]);
    },
    /*
    submitPayslip() {
      console.log("Fire Submit Payslip");

      axios
          .post("/makepdf")
          .then(res => {
            console.log(res);
            if (res.status === 201) {
              this.snackbar.color = "success";
              this.snackbar.text = "Invoice ID: Generated"; // + res.data.jobId + " added";
              this.snackbar.visible = true;
            }
          })
          .catch(e => {
            this.errors.push(e);
            this.snackbar.color = "error";
            this.snackbar.text = this.errors.pop();
            this.snackbar.visible = true;
          });
    },
*/
    downloadPdf(isPreview = false) {
      this.beInProgress = true;
      var payslipNum = this.payslipId;

      if (isPreview) {
        payslipNum = "Preview_" + this.previewPayslipId;
        //invoiceNum = "Preview_" + this.invoiceId;
      }
      //this.payslipId = 18909; //18489
      var FYArray = this.checkStartEndFinancialYear(
        this.startDate,
        this.endDate
      );

      this.loadingPdf = true;
      axios({
        method: "get",
        url: "pdf/payslip/" + payslipNum,
        responseType: "blob",
      })
        .then((response) => {
          this.forceFileDownload(response, isPreview);
          this.loadingPdf = false;
          this.beInProgress = false;
        })
        .catch((err) => {
          console.log(err + " occured");
          this.loadingPdf = false;
          this.beInProgress = false;
        });
    },

    forceFileDownload(response, isPreview = false) {
      this.beInProgress = true;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      if (!isPreview) {
        link.setAttribute("download", "Payslip_" + this.payslipId + ".pdf"); //or any other extension
      } else {
        link.setAttribute(
          "download",
          "Preview_Payslip_" + this.previewPayslipId + ".pdf"
        ); //or any other extension
      }

      document.body.appendChild(link);
      link.click();
      this.beInProgress = false;
      if (!isPreview) {
        this.JobEntries = [];
        this.MiscEntries = [];
        this.YTDAmount = {};
        this.payslipId = 0;
      }
    },

    commitPayslip(isPreview = false) {
      this.beInProgress = true;
      let payslipPreview = isPreview;
      var actualGST = 0;
      var actualTotal = 0;

      if (this.selectedContractor.retain_gst) {
        actualGST = 0;
        actualTotal =
          parseFloat(this.computeFuelLevy) +
          parseFloat(this.computeToll) +
          parseFloat(this.computeSubTotal);
      } else {
        actualGST = parseFloat(this.computeGST);
        actualTotal =
          parseFloat(this.computeFuelLevy) +
          parseFloat(this.computeToll) +
          parseFloat(this.computeGST) +
          parseFloat(this.computeSubTotal);
      }

      var FYArray = this.checkStartEndFinancialYear(
        this.startDate,
        this.endDate
      );

      let payslip = {
        //create_date: this.upToDate, //Get from server
        Contractor_ID: this.selectedContractorID,
        fuel_levy: parseFloat(this.computeFuelLevy),
        toll_charge: parseFloat(this.computeToll),
        gst: actualGST,
        tax_income: parseFloat(this.computeSubTotal),
        total: actualTotal,
        Pay_Period:
          this.formatDate(this.startDate) +
          " - " +
          this.formatDate(this.endDate),
        Payment_Date: this.paymentDate,
        financial_year: FYArray[2] + "-" + FYArray[3],
        startDate: this.startDate,
        endDate: this.endDate,
        send_date: this.sendDate,
        preview: payslipPreview,
      };

      axios
        .post("/payslip", payslip)
        .then((res) => {
          if (res.status === 201) {
            if (!isPreview) {
              this.payslipId = res.data.payslipId;
              this.snackbar.color = "success";
              this.snackbar.text =
                "Payslip ID: " + this.payslipId + " Generated";
              this.snackbar.visible = true;
              this.contractorName = ""; // Disable Generate Payslip Button
              this.loadContractors(); // reload contractor list
            } else {
              // Preview payslip
              this.previewPayslipId = res.data.payslipId;
              this.snackbar.color = "success";
              this.downloadPdf(isPreview);
              this.snackbar.text = "Retriving Payslip for Preview...";
              this.snackbar.visible = true;
            }
          }
          this.beInProgress = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.snackbar.color = "error";
          this.snackbar.text = this.errors.pop();
          this.snackbar.visible = true;
          this.beInProgress = false;
        });
    },
  },
};
</script>
