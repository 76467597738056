<template>
  <v-layout>
    <v-flex sm12 md12>
      <v-card>
        <v-card-title class="headline" title--text> Profile </v-card-title>
        <v-card-text>
          <v-form ref="contractor">
            <v-layout>
              <v-flex xs12 md4>
                <v-text-field
                  label="First Name"
                  v-model="contractor.FirstName"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  label="Prefered Name"
                  v-model="contractor.PreferedName"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  label="Last Name"
                  v-model="contractor.LastName"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-text-field
              label="Address 1"
              v-model="contractor.Address1"
            ></v-text-field>
            <v-text-field
              label="Address 2"
              v-model="contractor.Address2"
            ></v-text-field>
            <v-layout>
              <v-flex xs12 md4>
                <v-text-field
                  label="Suburb"
                  v-model="contractor.Suburb"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-select
                  v-model="contractor.State"
                  :items="states"
                  label="State"
                ></v-select>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  label="Postcode"
                  v-model="contractor.Postcode"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center justify-space-between>
              <v-flex xs12 md3>
                <v-text-field
                  label="Phone"
                  v-model="contractor.HomePhone"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  label="Mobile"
                  v-model="contractor.Mobile"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Email"
                  v-model="contractor.Email"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center justify-space-between>
              <v-flex xs12 md6>
                <v-text-field
                  label="Licence¹"
                  v-model="contractor.Licence"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Raca¹"
                  v-model="contractor.Raca"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center>
              <v-flex xs12 md6>
                <v-text-field
                  label="ABN¹"
                  v-model="contractor.ABN"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Company Name"
                  v-model="contractor.CompanyName"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center raw fill-height>
              <v-flex xs12 md6>
                <v-text-field
                  label="Bank Name"
                  v-model="contractor.BankName"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Account Name"
                  v-model="contractor.AccountName"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout align-center raw fill-height>
              <v-flex xs12 md6>
                <v-text-field
                  label="BSB"
                  v-model="contractor.BSB"
                  :rules="[rules.bsb]"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Account No"
                  v-model="contractor.AccountNo"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center justify-space-between>
              <v-flex xs12 md4>
                <span>
                  ¹ Contact administrator to change ABN, Licence or Raca</span
                >
              </v-flex>

              <v-flex xs12 md4>
                <v-menu
                  v-model="DOBDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="DOBFormatted"
                      label="Date of Birth"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="contractor.DOB"
                    no-title
                    @input="DOBDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 md4>
                <v-menu
                  v-model="DOCDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="DOCFormatted"
                      label="Date of Commencement"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="contractor.DOC"
                    no-title
                    @input="DOCDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <!-- <v-flex xs12 md4>
            <v-btn
              @click="resetPassword"
              color="deep-orange"
              dark
            >
              Password Reset
              <v-icon right dark>lock_reset</v-icon>
            </v-btn>
          </v-flex>
          <v-spacer></v-spacer> -->
          <v-layout align-center>
            <v-flex xs12 md5>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-if="contractor.retain_gst" v-on="on">
                    <v-icon color="green darken-3">done</v-icon>
                    GST Retained
                  </span>
                  <span v-if="!contractor.retain_gst" v-on="on">
                    <v-icon color="red darken-3">close</v-icon>
                    GST Not Retained
                  </span>
                </template>
                <span> Contact adminstrator to change GST status</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
          <v-btn @click="updateContractor" color="success" dark>
            Update
            <v-icon right dark>save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-layout>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      contractor: {},
      states: ["VIC", "NSW", "ACT", "TAS", "QLD", "SA", "WA", "NT"],
      DOBDateMenu: false,
      DOCDateMenu: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        password: (value) => value === this.Password || "Password not matching",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        bsb: (bsb) => {
          let patt = new RegExp("^\\d{3}-\\d{3}$");
          if (this.contractor.BSB) {
            this.contractor.BSB = this.contractor.BSB.replace(/[^0-9-]/g, "");
            this.contractor.BSB = this.contractor.BSB.replace(
              /(\d{3})(\d{3})/,
              "$1-$2"
            );
          }
          return (
            !this.contractor.BSB ||
            patt.test(this.contractor.BSB) ||
            "Invalid BSB"
          );
        },
      },
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },

      errors: [],
    };
  },

  watch: {
    dialog() {
      this.$refs.contractor.resetValidation();
    },
  },
  mounted() {
    this.loadContractor();
  },
  computed: {
    DOBFormatted() {
      return this.formatDate(this.contractor.DOB);
    },
    DOCFormatted() {
      return this.formatDate(this.contractor.DOC);
    },
  },

  methods: {
    loadContractor() {
      axios
        .get("/contractor_routes/contractor")
        .then((response) => {
          this.contractor = response.data[0];
          // SQL name is different to variable name :(
          this.contractor.Licence = response.data[0].License;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    updateContractor() {
      if (this.$refs.contractor.validate()) {
        let contractor = {
          FirstName: this.contractor.FirstName,
          PreferedName: this.contractor.PreferedName || "",
          LastName: this.contractor.LastName,
          Address1: this.contractor.Address1 || "",
          Address2: this.contractor.Address2 || "",
          Suburb: this.contractor.Suburb || "",
          State: this.contractor.State || "",
          Postcode: this.contractor.Postcode || "",
          HomePhone: this.contractor.HomePhone || "",
          Mobile: this.contractor.Mobile || "",
          Email: this.contractor.Email || "",
          Licence: this.contractor.Licence || "",
          Raca: this.contractor.Raca || "",
          ABN: this.contractor.ABN || "",
          CompanyName: this.contractor.CompanyName || "",
          BankName: this.contractor.BankName || "",
          AccountName: this.contractor.AccountName || "",
          BSB: this.contractor.BSB || "",
          AccountNo: this.contractor.AccountNo || "",
          DOB: this.contractor.DOB || "1970-01-01",
          DOC: this.contractor.DOC || "1970-01-01",
        };

        axios
          .put(
            "/contractor_routes/contractor/" + this.contractor.ContractorID,
            contractor
          )
          .then((res) => {
            if (res.status === 201) {
              this.snackbar.color = "success";
              this.snackbar.text = "Profile Updated";
              this.snackbar.visible = true;
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.snackbar.color = "error";
            this.snackbar.text = this.errors.pop();
            this.snackbar.visible = true;
          });
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
