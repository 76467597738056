<template>
  <v-flex xs12 sm12 md12>
    <v-card>
      <v-dialog v-model="ratesDialog" max-width="900px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="rate">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      :loading="DepotNamesLoading"
                      prepend-icon="business"
                      v-model="editedRate.DepotName"
                      label="Depot"
                      :readonly="this.editedRateIndex > -1"
                      :items="filteredDepotNams"
                      :rules="[rules.required, rules.notBlank]"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      prefix="$"
                      v-model="editedRate.rate"
                      @change="formatRate"
                      label="Rate"
                      :rules="[rules.required, rules.validFloat]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      prefix="%"
                      v-model="editedRate.fuel_levy"
                      @change="formatFuelLevy"
                      label="Fuel Levy"
                      :rules="[rules.required, rules.validFloat]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      outlined
                      label="Rate Type"
                      :items="rateTypes"
                      v-model="editedRate.rate_type"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeRate" class="error" text>
              Cancel
              <v-icon right dark>cancel</v-icon>
            </v-btn>
            <v-btn @click="saveRate" class="success" text>
              Save
              <v-icon right dark>save</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="rateDeleteDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Confirm Delete</v-card-title>
          <v-card-text
            >Confirm deletion of {{ rateToBeDeleted.rego }}'s'
            {{ rateToBeDeleted.DepotName }} rates</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="success" text @click="rateDeleteDialog = false">
              Cancel
              <v-icon right dark>cancel</v-icon>
            </v-btn>
            <v-btn class="error" text @click="deleteRate">
              Delete
              <v-icon right dark>delete_forever</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-title class="headline" title--text>
        {{ rego }} Rates
        <v-spacer></v-spacer>

        <v-btn fab dark small color="indigo" @click="addRate">
          <v-icon dark>add</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table v-if="rates[0]" :headers="rateHeaders" :items="rates">
          <template v-slot:item="props">
            <tr
              :class="{
                active:
                  !!selectedRate &&
                  selectedRate.DepotName == props.item.DepotName,
              }"
              @click="rateSelected(props.item)"
            >
              <td align="right">{{ props.item.DepotName }}</td>
              <td align="right">
                $ {{ parseFloat(props.item.rate).toFixed(2) }}
              </td>
              <td align="right">
                % {{ parseFloat(props.item.fuel_levy).toFixed(2) }}
              </td>
              <td align="right">
                {{
                  rateTypes.find((el) => el.value === props.item.rate_type).text
                }}
              </td>
              <td>
                <v-icon class="mr-2" @click="editRate(props.item)">edit</v-icon>
                <v-icon @click="prepareRateDelete(props.item)">delete</v-icon>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning"
              >No rates found for : {{ rego }}</v-alert
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-flex>
</template>

<script>
import axios from "axios";

export default {
  props: ["contractorID", "rego"],
  data() {
    return {
      ratesPagination: {
        rowsPerPage: 10,
        sortBy: "DepotName",
      },
      ratesDialog: false,
      rates: [],
      selectedRate: {},

      DepotNames: [],
      DepotNamesLoading: false,
      DepotName: "",

      rateDeleteDialog: false,
      editedRateIndex: -1,
      rateToBeDeleted: {},
      editedRate: {
        rego: this.rego,
        ContractorID: this.contractorID,
        DepotName: "",
        rate: 0.0,
        fuel_levy: 0.0,
        rate_type: "hourly",
      },

      defaultRate: {
        rego: this.rego,
        ContractorID: this.contractorID,
        DepotName: "",
        rate: 0.0,
        fuel_levy: 0.0,
        rate_type: "hourly",
      },
      rateTypes: [
        { text: "Hourly", value: "hourly" },
        { text: "Flat Rate", value: "flat" },
        { text: "Piece Rate", value: "piece" },
        { text: "Stop Rate", value: "stop" },
      ],
      rateHeaders: [
        {
          value: "DepotName",
          align: "end",
          sortable: true,
          text: "Deport",
        },
        {
          value: "rate",
          align: "end",
          sortable: false,
          text: "Rate",
        },
        {
          value: "fuel_levy",
          align: "end",
          sortable: false,
          text: "Fuel Levy",
        },
        {
          value: "rate_type",
          align: "end",
          sortable: false,
          text: "Rate Type",
        },
        {
          value: "name",
          align: "start",
          sortable: false,
          text: "Action",
        },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        notBlank: (value) => value !== "" || "Can't be blank",

        validInt: (int) => {
          return parseInt(int) > 0 || "Not valid";
        },
        validFloat: (float) => {
          return parseFloat(float) >= 0.0 || "Not valid";
        },
      },

      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
      errors: [],
    };
  },

  created() {
    this.loadClients();
    this.defaultRate.rego = this.rego;
    this.editedRate.rego = this.rego;
  },

  computed: {
    formTitle() {
      return this.editedRateIndex === -1
        ? "Add Rate for " + this.rego
        : "Edit Rate of " + this.rego;
    },
    filteredDepotNams() {
      return this.DepotNames.filter((DepotName) => {
        let selectable = true;
        this.rates.forEach((rate) => {
          if (this.editedRateIndex === -1 && rate.DepotName === DepotName) {
            selectable = false;
          }
        });
        return selectable;
      });
    },
  },
  watch: {
    rego(newVal, oldVal) {
      if (newVal) {
        this.loadRates();
      } else {
        this.rates = [];
      }
    },
    ratesDialog(newVal, oldVal) {
      if (this.editedRateIndex === -1 && newVal === true) {
        this.editedRate = Object.assign({}, this.defaultRate);
        this.editedRateIndex = -1;
        this.formatRate();
        this.formatFuelLevy();
        if (this.$refs.rate) {
          this.$refs.rate.resetValidation();
        }
      }
    },
  },
  methods: {
    loadRates() {
      axios
        .get(`/contractor/${this.contractorID}/${this.rego}/rates`)
        .then((response) => {
          this.rates = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    loadClients() {
      this.DepotNamesLoading = true;
      axios
        .get("/client/active")
        .then((response) => {
          response.data.forEach((client) => {
            this.DepotNames.push(client.DepotName);
            this.DepotNames.sort();
            this.DepotNamesLoading = false;
          });
        })
        .catch((e) => {
          this.errors.push(e);
          this.DepotNamesLoading = false;
        });
    },
    rateSelected(selctedRate) {
      this.selectedRate = selctedRate;
    },
    addRate() {
      if (this.rego === undefined || this.rego === "") {
        this.snackbar.color = "warning";
        this.snackbar.text = "Please select vehicle to add rate against";
        this.snackbar.visible = true;
      } else {
        this.editedRateIndex = -1;
        this.formatRate();
        this.formatFuelLevy();
        this.ratesDialog = true;
      }
    },
    editRate(rate) {
      this.editedRateIndex = this.rates.indexOf(rate);
      this.editedRate = Object.assign({}, rate);
      this.formatRate();
      this.formatFuelLevy();
      this.ratesDialog = true;
    },
    formatRate() {
      const rate_str = parseFloat(this.editedRate.rate).toFixed(2);
      if (rate_str != "NaN") {
        this.editedRate.rate = rate_str;
      } else {
        this.editedRate.rate = "0.00";
      }
    },
    formatFuelLevy() {
      const levy_str = parseFloat(this.editedRate.fuel_levy).toFixed(2);
      if (levy_str != "NaN") {
        this.editedRate.fuel_levy = levy_str;
      } else {
        this.editedRate.fuel_levy = "0.00";
      }
    },
    closeRate() {
      this.ratesDialog = false;
    },
    saveRate() {
      if (this.$refs.rate.validate()) {
        if (this.editedRateIndex > -1) {
          axios
            .put(
              "/contractor/" +
                this.contractorID +
                "/rate/" +
                this.rego +
                "/" +
                this.editedRate.DepotName,
              this.editedRate
            )
            .then((res) => {
              if (res.status === 201) {
                this.snackbar.color = "success";
                this.snackbar.text =
                  this.editedRate.rego +
                  " : " +
                  this.editedRate.DepotName +
                  " rate updated";
                this.snackbar.visible = true;
                this.loadRates();
                this.selectedRate = {};
                this.closeRate();
              }
            })
            .catch((e) => {
              this.errors.push(e);
              this.snackbar.color = "error";
              this.snackbar.text = this.errors.pop();
              this.snackbar.visible = true;
            });
        } else {
          axios
            .post(
              "/contractor/" +
                this.contractorID +
                "/rate/" +
                this.rego +
                "/" +
                this.editedRate.DepotName,
              this.editedRate
            )
            .then((res) => {
              if (res.status === 201) {
                this.snackbar.color = "success";
                this.snackbar.text =
                  this.editedRate.rego +
                  " : " +
                  this.editedRate.DepotName +
                  " rate added";

                this.snackbar.visible = true;
                this.loadRates();
                this.closeRate();
              }
            })
            .catch((e) => {
              this.errors.push(e);
              this.snackbar.color = "error";
              this.snackbar.text = this.errors.pop();
              this.snackbar.visible = true;
            });
        }
      }
    },

    prepareRateDelete(rate) {
      this.rateToBeDeleted = rate;
      this.rateDeleteDialog = true;
    },
    deleteRate() {
      this.rateDeleteDialog = false;
      console.log(this.rateToBeDeleted);
      axios
        .delete(
          "/contractor/" +
            this.contractorID +
            "/rate/" +
            this.rego +
            "/" +
            this.rateToBeDeleted.DepotName
        )
        .then((res) => {
          if (res.status === 201) {
            this.snackbar.color = "success";
            this.snackbar.text =
              "Deleted " +
              this.rateToBeDeleted.rego +
              "'s " +
              this.rateToBeDeleted.DepotName +
              " rates";
            this.snackbar.visible = true;
            this.loadRates();
            this.selectedRate = {};
            this.closeRate();
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.snackbar.color = "error";
          this.snackbar.text = this.errors.pop();
          this.snackbar.visible = true;
        });
    },
  },
};
</script>

<style scoped>
.theme--dark.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.active {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.active {
  background: #a1caff;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #a1caff;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}
</style>
