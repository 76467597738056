<template>
  <v-container id="clients" grid-list-md fluid>
    <v-dialog v-model="clientDialog" max-width="920">
      <client-form
        :dialog="clientDialog"
        :newClient="true"
        @onCloseDialog="clientAdded"
      ></client-form>
    </v-dialog>

    <v-card>
      <v-card-title class="headline" title--text>
        Clients
        <v-spacer></v-spacer>
        <v-switch
          v-model="showInactive"
          @change="loadClients"
          label="Show Inactive"
          inset
        ></v-switch>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn @click="clientDialog = true" fab dark small color="indigo">
          <v-icon dark>add</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="clients"
        :search="search"
        :footer-props="{
          'items-per-page-options': [15, 30, 60, 100, -1],
        }"
      >
        <template v-slot:item="props">
          <tr
            class="pa-0"
            :active="!!selected && selected.DepotName == props.item.DepotName"
            @click="clientSelected(props.item)"
          >
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.DepotName }}
            </td>
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.Name }}
            </td>
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.ContactPerson }}
            </td>
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.ABN }}
            </td>
            <td
              class="body-2"
              v-bind:class="{ 'grey--text': props.item.Inactive }"
            >
              {{ props.item.Address1 }} {{ props.item.Address2 }},
              {{ props.item.Suburb }}, {{ props.item.State }}
              {{ props.item.Postcode }}
            </td>
          </tr>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import cForm from "./ClientForm.vue";

export default {
  components: {
    "client-form": cForm,
  },
  data() {
    return {
      pagination: {
        rowsPerPage: 15,
        sortBy: "DepotName",
      },
      selected: {},
      search: "",
      showInactive: false,
      clientDialog: false,
      headers: [
        {
          value: "DepotName",
          align: "left",
          sortable: true,
          text: "Depot Name",
        },
        {
          value: "Name",
          align: "left",
          sortable: true,
          text: "Name",
        },
        {
          value: "ContactPerson",
          align: "left",
          sortable: true,
          text: "Contact Person",
        },
        { value: "ABN", align: "left", sortable: true, text: "ABN" },
        { value: "Address1", align: "left", sortable: false, text: "Address" },
      ],
      clients: [],

      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
    };
  },

  created() {
    this.loadClients(false);
  },

  methods: {
    loadClients(showInactive) {
      axios
        .get(`/client`)
        .then((response) => {
          if (showInactive == false) {
            this.clients = response.data.filter(
              (client) => client.Inactive == 0
            );
          } else {
            this.clients = response.data;
          }
          this.totalRows = this.clients.length;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    clientAdded(client) {
      if (client) {
        this.clientDialog = false;
        this.snackbar.color = "success";
        this.snackbar.text = "Client: " + client + " added";
        this.snackbar.visible = true;
      }

      this.loadClients(this.showInactive);
    },
    clientSelected(selctedClient) {
      this.selected = selctedClient;
      this.$router.push({ path: `/clients/${this.selected.DepotName}` });
    },
  },
};
</script>

<style></style>
