<template>
  <v-container id="PayslipView" grid-list-md fluid>
    <v-card>
      <v-card-title class="headline" title--text
        >Contractor3 : {{ selectedContractor.ContractorID }}</v-card-title
      >

      <!--v-card-title v-if="payslipPDFView" class="headline" title--text><h1>{{ JobEntries }}</h1></v-card-title-->
      <div style="center" class="child">
        <table style="width: 100%" border="true">
          <tr>
            <th>
              <h1><p align="left">Pay Slip</p></h1>
            </th>
            <th><br /><br /><b>Private & Confidential</b></th>
            <th>
              <p align="right">{{ JobEntries }}</p>
            </th>
          </tr>
        </table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Child",
  props: {
    JobEntries: Array,
    selectedContractor: Object,
    payslipPDFView: Boolean,
  },

  data() {
    return {
      contractor: {},

      errors: [],
    };
  },
};
</script>
