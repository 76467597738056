<template>
  <v-flex xs12 sm12 md12>
    <v-card>
      <v-card-title class="headline" title--text>
        {{ rego }} Rates
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-data-table v-if="rates[0]" :headers="rateHeaders" :items="rates">
          <template v-slot:item="props">
            <tr
              :class="{
                active:
                  !!selectedRate &&
                  selectedRate.DepotName == props.item.DepotName,
              }"
              @click="rateSelected(props.item)"
            >
              <td align="right">{{ props.item.DepotName }}</td>
              <td align="right">
                $ {{ parseFloat(props.item.rate).toFixed(2) }}
              </td>
              <td align="right">
                % {{ parseFloat(props.item.fuel_levy).toFixed(2) }}
              </td>
              <td align="right">
                {{
                  rateTypes.find((el) => el.value === props.item.rate_type).text
                }}
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning"
              >No rates found for : {{ rego }}</v-alert
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import axios from "axios";

export default {
  props: ["contractorID", "rego"],
  data() {
    return {
      ratesPagination: {
        rowsPerPage: 10,
        sortBy: "DepotName",
      },
      rates: [],
      selectedRate: {},

      DepotNames: [],
      DepotNamesLoading: false,
      DepotName: "",

      rateDeleteDialog: false,
      editedRateIndex: -1,
      rateToBeDeleted: {},

      rateHeaders: [
        {
          value: "DepotName",
          align: "end",
          sortable: true,
          text: "Deport",
        },
        {
          value: "rate",
          align: "end",
          sortable: false,
          text: "Rate",
        },
        {
          value: "fuel_levy",
          align: "end",
          sortable: false,
          text: "Fuel Levy",
        },
        {
          value: "rate_type",
          align: "end",
          sortable: false,
          text: "Rate Type",
        },
      ],
      rateTypes: [
        { text: "Hourly Rate", value: "hourly" },
        { text: "Flat Rate", value: "flat" },
        { text: "Piece Rate", value: "piece" },
        { text: "Stop Rate", value: "stop" },
      ],
      errors: [],
    };
  },

  created() {
    this.loadClients();
  },
  watch: {
    rego(newVal, oldVal) {
      if (newVal) {
        this.loadRates();
      } else {
        this.rates = [];
      }
    },
  },
  methods: {
    loadRates() {
      axios
        .get(`/contractor/${this.contractorID}/${this.rego}/rates`)
        .then((response) => {
          this.rates = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    loadClients() {
      this.DepotNamesLoading = true;
      axios
        .get("/client/active")
        .then((response) => {
          response.data.forEach((client) => {
            this.DepotNames.push(client.DepotName);
            this.DepotNames.sort();
            this.DepotNamesLoading = false;
          });
        })
        .catch((e) => {
          this.errors.push(e);
          this.DepotNamesLoading = false;
        });
    },
    rateSelected(selctedRate) {
      this.selectedRate = selctedRate;
    },
    formatRate() {
      const rate_str = parseFloat(this.editedRate.rate).toFixed(2);
      if (rate_str != "NaN") {
        this.editedRate.rate = rate_str;
      } else {
        this.editedRate.rate = "0.00";
      }
    },
    formatFuelLevy() {
      const levy_str = parseFloat(this.editedRate.fuel_levy).toFixed(2);
      if (levy_str != "NaN") {
        this.editedRate.fuel_levy = levy_str;
      } else {
        this.editedRate.fuel_levy = "0.00";
      }
    },
    closeRate() {
      this.ratesDialog = false;
    },
  },
};
</script>

<style scoped>
.theme--dark.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table tbody tr.active {
  background: #a1caff;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.active {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.active {
  background: #a1caff;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #a1caff;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr.hover {
  background: #a1caff;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #a1caff;
}
</style>
