<template>
  <v-app class="blue-grey lighten-5">
    <!-- <NavBar v-if="role=='user' || role=='admin'/> -->
    <NavBarClient v-if="role == 'client'" />
    <NavBarContractor v-if="role == 'contractor'" />
    <NavBar v-if="role != 'client' && role != 'contractor'" />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import NavBarClient from "@/components/NavBarClient";
import NavBarContractor from "@/components/NavBarContractor";

export default {
  name: "App",
  components: {
    NavBar,
    NavBarClient,
    NavBarContractor,
  },
  data() {
    return {
      //
    };
  },

  computed: {
    role() {
      return !this.$store.getters.user
        ? "NOROLE"
        : this.$store.getters.user.role;
    },
  },
};
</script>

<style>
table.v-table tbody td,
table.v-table tbody th {
  height: 42px;
}
.headline {
  padding-bottom: 0px;
}
div.v-input {
  height: 58px;
}
</style>
