<template>
  <v-app id="inspire">
    <v-main router>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-form @submit="onSubmit" @reset="onReset">
              <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>VHT Login</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-alert :value="loginError" type="error"
                    >Invalid username or password!</v-alert
                  >
                  <v-alert :value="accountDisabled" type="error"
                    >Account disabled contact Administrator</v-alert
                  >
                  <v-text-field
                    v-model="login.username"
                    prepend-icon="person"
                    name="username"
                    label="Username"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    v-model="login.password"
                    id="password"
                    prepend-icon="lock"
                    name="password"
                    label="Password"
                    type="password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn type="reset" to="/" color="error">
                    Cancel
                    <v-icon dark right>block</v-icon>
                  </v-btn>
                  <v-btn type="submit" color="primary">
                    Login
                    <v-icon dark right>check_circle</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <div class="pa-10" />

    <v-layout raw wrap align-start justify-center>
          <h2> Contractor Apps</h2>
        </v-layout>
        <v-layout raw wrap align-start justify-center>
          <v-flex md3>
            <a href="https://apps.apple.com/us/app/vht-courier/id6478838829">
              <v-img width="40%" src="appstore.webp"></v-img>
            </a>
          </v-flex>
          <v-flex md3>
            <a href="https://play.google.com/store/apps/details?id=au.net.vht.courier">
              <v-img width="40%" src="playstore.png"></v-img>
            </a>
            <p class="text-caption"><b>Please note:</b> Android app is still undergoing google review.
              However, it is available for users on trial list
            We'd love you to join the trial. Send us the email id you
              use on Google Play Store to <a href="mailto:support@vht.net.au">support@vht.net.au</a> and we will
              be able to add you to trial list
            Once you have been added to trial list, you can download the app from Play Store</p>
          </v-flex>
        </v-layout>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    login: {
      username: "",
      password: "",
    },
    loginError: false,
    accountDisabled: false,
    token: "",
  }),

  mounted() {
    this.loginError = false;
    this.accountDisabled = false;
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      axios
        .post("/login", this.login)
        .then((response) => {
          if (response.status === 200) {
            this.loginError = false;
            this.token = response.headers["vht-auth"];
            this.$store.dispatch("login", { token: this.token });
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 403) {
            this.accountDisabled = true;
          } else {
            this.loginError = true;
          }
        });
    },
    onReset(evt) {
      evt.preventDefault();
      /* Reset our form values */
      this.login.username = "";
      this.login.password = "";
    },
  },
  props: {
    source: String,
  },
};
</script>
