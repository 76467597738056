<template>
  <v-layout>
    <v-flex sm12 md12>
      <v-card>
        <v-card-title v-if="!newContractor" class="headline" title--text
          >Contractor: {{ contractorID }}</v-card-title
        >
        <v-card-text>
          <v-form :readonly="true" ref="contractor">
            <v-text-field
              v-if="newContractor"
              label="ID"
              v-model="contractor.ContractorID"
              @input="idChanged"
            ></v-text-field>
            <v-layout>
              <v-flex xs12 md4>
                <v-text-field
                  label="First Name"
                  v-model="contractor.FirstName"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  label="Prefered Name"
                  v-model="contractor.PreferedName"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  label="Last Name"
                  v-model="contractor.LastName"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-text-field
              label="Address 1"
              v-model="contractor.Address1"
            ></v-text-field>
            <v-text-field
              label="Address 2"
              v-model="contractor.Address2"
            ></v-text-field>
            <v-layout>
              <v-flex xs12 md4>
                <v-text-field
                  label="Suburb"
                  v-model="contractor.Suburb"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-select
                  v-model="contractor.State"
                  :items="states"
                  label="State"
                ></v-select>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  label="Postcode"
                  v-model="contractor.Postcode"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center justify-space-between>
              <v-flex xs12 md3>
                <v-text-field
                  label="Phone"
                  v-model="contractor.HomePhone"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  label="Mobile"
                  v-model="contractor.Mobile"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Email"
                  v-model="contractor.Email"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center justify-space-between>
              <v-flex xs12 md6>
                <v-text-field
                  label="Licence"
                  v-model="contractor.Licence"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Raca"
                  v-model="contractor.Raca"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center>
              <v-flex xs12 md6>
                <v-text-field
                  label="ABN"
                  v-model="contractor.ABN"
                  read-only
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Company Name"
                  v-model="contractor.CompanyName"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center raw fill-height>
              <v-flex xs12 md6>
                <v-text-field
                  label="Bank Name"
                  v-model="contractor.BankName"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Account Name"
                  v-model="contractor.AccountName"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout align-center raw fill-height>
              <v-flex xs12 md6>
                <v-text-field
                  label="BSB"
                  v-model="contractor.BSB"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Account No"
                  v-model="contractor.AccountNo"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout align-center justify-space-between>
              <v-flex xs12 md4>
                <v-text-field
                  v-model="DOBFormatted"
                  label="Date of Birth"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="event"
                  :readonly="true"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md4>
                <v-text-field
                  v-model="DOCFormatted"
                  label="Date of Commencement"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="event"
                  :readonly="true"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-flex xs12 md4> </v-flex>
          <v-spacer></v-spacer>
          <v-layout align-center>
            <v-flex xs12 md4>
              <v-switch
                inset
                v-model="contractor.retain_gst"
                label="Retain GST"
                :readonly="true"
              ></v-switch>
            </v-flex>
            <v-flex xs12 md4>
              <v-switch
                inset
                v-model="contractor.Inactive"
                label="Inactive"
                :readonly="true"
              ></v-switch>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";

export default {
  props: {
    dialog: Boolean,
    contractorID: String,
    newContractor: Boolean,
  },

  data() {
    return {
      contractor: {},
      states: ["VIC", "NSW", "ACT", "TAS", "QLD", "SA", "WA", "NT"],
      DOBDateMenu: false,
      DOCDateMenu: false,
      errors: [],
    };
  },

  mounted() {
    if (this.contractorID) {
      this.loadContractor();
    }
  },
  computed: {
    DOBFormatted() {
      return this.formatDate(this.contractor.DOB);
    },
    DOCFormatted() {
      return this.formatDate(this.contractor.DOC);
    },
  },

  methods: {
    loadContractor() {
      axios
        .get("/contractor/" + this.contractorID)
        .then((response) => {
          this.contractor = response.data[0];
          // SQL name is different to variable name :(
          this.contractor.Licence = response.data[0].License;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    zeroPad(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    },
  },
};
</script>
