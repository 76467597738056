<template>

  <v-flex xs12 sm6 md4 lg3>
    <v-toolbar color="blue accent-1">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-progress-linear
        :active="loadJobsInProgress"
        indeterminate
        absolute
        bottom
        color="blue accent-4"
      ></v-progress-linear>

    </v-toolbar>

    <v-list two-line >
      <template v-for="job in jobs">
        <v-list-item
          :key="job.title"
          ripple
          :class="jobColor(job)"
          class="my-1"
        >
          <v-list-item-content>
            <v-list-item-title
              >{{ job.contractor_ContractorID || "UNASSIGNED" }}</v-list-item-title
            >
            <v-list-item-subtitle class="body-2 text--primary"
              >{{ times(job) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="body-2 text--primary">
              {{ job.client_vehicle_type }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>

              <v-chip
                v-if="job.client_invoiceID"
                small
                outlined
                color="teal darken-2"
                ><v-icon small left>receipt</v-icon>
                {{ job.client_invoiceID }}</v-chip
              >
              <v-btn outlined small v-if="job.status > 110" @click="deleteJobClicked(job.JobID)" color="red">
              <v-icon left >delete</v-icon>
              Delete
            </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-if="job.status % 10 === 2" color="red darken-3">local_shipping</v-icon>
            <v-icon v-if="job.status % 10 === 1" color="orange darken-3">local_shipping</v-icon>
            <v-icon v-if="job.status % 10 === 0" color="green darken-3">local_shipping</v-icon>
            <v-icon v-if="job.status===0" color="green darken-3">done_all</v-icon>
            <v-icon v-if="job.status > 0" color="orange darken-3">schedule</v-icon>

          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>

    <v-dialog
            v-model="deleteJobDialog"
            persistent
            max-width="450"
          >
            <v-card>
              <v-card-title class="headline">Delete Job?</v-card-title>
              <v-card-text
                >Are you sure you want to delete Job
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="error" text @click="deleteJob(jobIdToDelete)">
                  Yes
                  <v-icon right dark>delete_forever</v-icon>
                </v-btn>
                <v-btn class="success" text @click="deleteJobDialog = false">
                  No
                  <v-icon right dark>cancel</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


  </v-flex>
</template>

<script>
import axios from "axios";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      jobs: [],
      deleteJobDialog: 0,
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
      interval: 0,
      loadJobsInProgress: false,
      loadJobsRequest: 0,
      jobIdToDelete: 0,
      errors: []
    };
  },

  watch: {
    title: function () {
      this.loadJobs();
    },
  },

  created() {
    this.loadJobs();
  },

  mounted() {
            this.loadJobs();
            this.interval = setInterval(function () {
                this.loadJobs();
            }.bind(this), 30000);
        },
  destroyed() {
                clearInterval(this.interval)
    },



  methods: {
    loadJobs() {
      this.cancelLoadJobs();
      const startDate = this.sqlDate(this.title)
      const endDate = this.sqlDate(this.title)

      let axiosSource = axios.CancelToken.source();
      this.loadJobsRequest = { cancel: axiosSource.cancel };

      this.loadJobsInProgress = true;
      axios
        .get(
          "/client_routes/job" +
            "?startDate=" +
            startDate +
            "&endDate=" +
            endDate, { cancelToken: axiosSource.token, }
        )
        .then((response) => {
          this.jobs = response.data;
          this.loadJobsInProgress = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loadJobsInProgress = false;
        });
    },
    cancelLoadJobs() {
      if (this.loadJobsRequest) {
        this.loadJobsRequest.cancel();
      }
    },

    times(job) {
      let start_time = this.chopSeconds(job.Start_Time);
      let finish_time = this.chopSeconds(job.finish_time);
      if (job.rate_type === "flat") {
        let percentage_day =
          Math.round(((job.client_subtotal / job.client_rate) * 100) / 25) * 25;
        return "Flat: " + percentage_day + "%";
      } else if (job.rate_type === "piece") {
        let pieces = Math.round(job.client_subtotal / job.client_rate);
        return "Pieces: " + pieces;
      } else if (job.rate_type === "stop") {
        let stops = Math.round(job.client_subtotal / job.client_rate);
        return "Stops: " + stops;
      } else {
        return "Hourly: " + start_time + " to " + finish_time;
      }
    },
    chopSeconds(time) {
      return time.split(":", 2).join(":");
    },
    jobColor(job) {
      if (job.rate_type === "flat") {
        return "flat_rate";
      } else if (job.rate_type === "piece") {
        return "piece_rate";
      } else if (job.rate_type === "stop") {
        return "stop_rate";
      } else {
        return "hourly_rate";
      }
    },
    sqlDate(dateString) {
      let date = new Date(dateString);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      return year + "-" + month + "-" + day;
    },
    deleteJobClicked(JobID) {
      this.jobIdToDelete = JobID;
      this.deleteJobDialog = true;
    },
    deleteJob(JobID) {
      axios
      .delete(`/client_routes/job/${JobID}`)
      .then((res) => {
        if (res.status === 201) {
              this.snackbar.color = "success";
              this.snackbar.text = "Job deleted";
              this.snackbar.visible = true;
              this.deleteJobDialog = false
              this.loadJobs()
        }
      })
      .catch((e) => {
        this.errors.push(e);
      });
    }
  },
};
</script>

<style scoped>
.flat_rate {
  background-color: #e8f5e9;
}
.piece_rate {
  background-color: #d5c2f2;
}
.stop_rate {
  background-color: #dafbdc;
}
.hourly_rate {
  background-color: #e3f2fd;
}
</style>
