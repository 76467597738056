<template>
  <v-container grid-list-md id="planner" fluid>
    <v-card>
      <v-card-title class="headline pa-3" title--text>
        <v-row class="pa-3" justify-space-around>
          <v-col sm="12" md="12" lg="4" xl="1"> Planner </v-col>
          <v-col sm="12" md="12" lg="6" xl="6">
            <v-row class="pa-3">
              <v-btn color="blue" icon @click="weekDown">
                <v-icon>arrow_back_ios</v-icon>
              </v-btn>
                {{ startDate }} - {{ endDate }}
              <v-btn color="blue" icon @click="weekUp">
                <v-icon>arrow_forward_ios</v-icon>
              </v-btn>
            </v-row>
          </v-col>
          <v-col sm="6" md="6" lg="2" xl="1">
            <v-btn large @click="resetDay" color="light-blue accent-1">
              This Week
              <v-icon right>insert_invitation</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <job-list
            v-for="day in days"
            :key="day"
            :title="day"
          ></job-list>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import JobList from "./JobList.vue";

export default {
  components: {
    jobList: JobList,
  },

  data() {
    return {
      today: null,
      day: 0,
      selectedDay: new Date(),
      jobs: [],
      clients: [],
      errors: [],
      text: "center",
    };
  },

  created() {
    if (this.$store.getters.plannerDate) {
      this.selectedDay = this.$store.getters.plannerDate;
      this.today = this.$store.getters.plannerDate;
    } else {
      this.today = new Date();
      this.selectedDay = new Date();
    }

    this.day = this.selectedDay.getDay();
  },

  computed: {
    startDate: function () {
      let first = this.today.getDate() - this.today.getDay() + 1; // First day is the day of the month - the day of the week
      let startDate = new Date(this.today);
      startDate.setDate(first);

      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      var day = startDate.getDate();
      var monthIndex = startDate.getMonth();
      var year = startDate.getFullYear();

      return day + " " + monthNames[monthIndex] + " " + year;
    },

    endDate: function () {
      let last = this.today.getDate() - this.today.getDay() + 7; // First day is the day of the month - the day of the week
      let endDate = new Date(this.today);
      endDate.setDate(last);
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      var day = endDate.getDate();
      var monthIndex = endDate.getMonth();
      var year = endDate.getFullYear();

      return day + " " + monthNames[monthIndex] + " " + year;
    },

    days: function () {
      let days = [];
      for (let i = 1; i < 8; i++) {
        //i = i == 7 ? 0 : i;
        let first = this.today.getDate() - this.today.getDay(); // First day is the day of the month - the day of the week
        let date = new Date(this.today);
        date.setDate(first + i);

        var weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
        var weekday = date.getDay();
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        days.push(
          weekdays[weekday] + ", " + this.pad(day, 2) + " " + monthNames[monthIndex] + " " + year
        );
      }
      return days;
    },
  },

  methods: {
    resetDay() {
      this.clients = [];
      this.today = new Date();
      this.selectedDay = new Date();
      this.day = new Date().getDay();
      this.$store
        .dispatch("setPlannerDate", { plannerDate: this.today });
    },
    weekUp() {
      this.today = new Date(this.today.setDate(this.today.getDate() + 7));
      this.setDay(this.day);
    },

    weekDown() {
      this.today = new Date(this.today.setDate(this.today.getDate() - 7));
      this.setDay(this.day);
    },
    setDay(index) {
      let date = new Date(this.startDate);
      this.selectedDay = new Date(date.setDate(date.getDate() + index));
      this.$store
        .dispatch("setPlannerDate", { plannerDate: this.selectedDay });
    },
    pad(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
  },
};

Date.prototype.getWeekDay = function () {
  var weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return weekday[this.getDay()];
};

Date.prototype.getMonthName = function () {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[this.getMonth()];
};
</script>

<style scoped></style>
