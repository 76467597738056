<template>
  <v-card outlined raised class="ma-4" :color="color">
    <v-list-item>
      <v-list-item-avatar v-if="iconSize > 0">
        <v-icon :size="iconSize">{{ icon }}</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <div
          class="text-right text-sm-caption text-lg-caption text-xl-overline"
        >
          {{ title }}
        </div>
        <v-list-item-title
          class="text-right text-sm-h8 text-md-body-1 text-lg-h6 text-xl-h5"
          >{{ amount }}</v-list-item-title
        >
        <!-- <v-list-item-subtitle class="text-sm-caption">{{sub_title}}</v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: ["title", "color", "amount", "icon", "sub_title"],
  data: () => ({}),
  computed: {
    iconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "0";
        case "sm":
          return "0";
        case "md":
          return "0";
        case "lg":
          return "0";
        case "xl":
          return "40";
        default:
          return "30";
      }
    },
  },
};
</script>

<style></style>
