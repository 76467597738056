<template>
  <v-card>
    <v-card-title>Assign Leave</v-card-title>
    <v-card-text>
      <v-layout raw wrap align-center justify-center>
        <v-flex xs12 sm6 md5>
          <v-autocomplete
            v-model="selectedContractor"
            :items="contractors"
            :filter="customFilter"
            item-text="text"
            item-value="value"
            label="Contractor"
          >
          </v-autocomplete>
        </v-flex>
        <v-flex xs12 sm6 md2>
          <v-menu
            v-model="startDateMenu"
            :close-on-content-click="false"
            :nudge-right="0"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="startDateFormatted"
                label="From"
                persistent-hint
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              no-title
              @input="startDateMenu = false"
              :allowed-dates="fromAllowedDates"
            ></v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm6 md2>
          <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            :nudge-right="0"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="endDateFormatted"
                label="To"
                persistent-hint
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              no-title
              @input="endDateMenu = false"
              :allowed-dates="toAllowedDates"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs1 sm1 md1> </v-flex>
        <v-flex xs12 sm6 md2>
          <v-btn
            :disabled="!selectedContractor || selectedContractor === ''"
            @click="submitLeave"
            color="success"
            >Submit</v-btn
          >
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      contractors: [],
      selectedContractor: "",
      //date picker
      endDateDT: new Date(new Date().setDate(new Date().getDate() + 14)),
      startDateDT: new Date(),
      startDate: "",
      endDate: "",
      startDateFormatted: "",
      endDateFormatted: "",
      startDateMenu: false,
      endDateMenu: false,
    };
  },
  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },
    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },

  mounted() {
    this.loadContractors();
    this.startDate = this.parseDate(this.startDateDT);
    this.endDate = this.parseDate(this.endDateDT);
    this.startDateFormatted = this.formatDate(this.startDate);
    this.endDateFormatted = this.formatDate(this.endDate);
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      date = date.toLocaleDateString("en-AU");
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    // load all contractors ID to the list
    loadContractors() {
      axios
        .get("/leave/contractor_list")
        .then((response) => {
          if (response.status === 200) {
            response.data.forEach((response) => {
              let contractorListItem = {
                text:
                  response.ContractorID +
                  ": " +
                  (response.PreferedName || response.FirstName) +
                  " " +
                  response.LastName,
                value: response.ContractorID,
              };
              this.contractors.push(contractorListItem);
            });
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    //filter from the dropdown list to select contractor
    customFilter(item, queryText, itemText) {
      const textOne = item.text.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    //add selected contractor leave
    submitLeave() {
      axios
        .post(
          `/leave/addLeave/${this.selectedContractor}` +
            "?startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate
        )
        .then((response) => {
          if (response.status === 200) {
            this.$emit("onCloseDialog");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fromAllowedDates(date) {
      return date <= this.endDate;
    },
    toAllowedDates(date) {
      return date >= this.startDate;
    },
  },
};
</script>

<style></style>
