<template>
  <div>
    <v-card
      outlined
      raised
      width="100%"
      class="a-10"
      color="green darken-1"
      elevation="10"
    >
      <p class="title text-center white--text">Client Summary</p>
      <v-row justify="space-between" no-gutters>
        <v-col cols="2">
          <stat-card
            title="Job Count"
            color="green accent-1"
            :amount="jobCount"
            icon="mdi-truck-delivery-outline"
            sub_title=""
          ></stat-card>
        </v-col>
        <v-col cols="2">
          <stat-card
            title="Misc Count"
            color="green accent-1"
            :amount="miscCount"
            icon="mdi-pencil-plus-outline"
            sub_title=""
          ></stat-card>
        </v-col>
        <v-col cols="2">
          <stat-card
            title="Toll"
            color="green accent-1"
            :amount="toll"
            icon="mdi-gauge"
            sub_title=""
          ></stat-card>
        </v-col>
        <v-col cols="2">
          <stat-card
            title="Fuel Levy"
            color="green accent-1"
            :amount="fuelLevy"
            icon="mdi-gas-station-outline"
            sub_title=""
          ></stat-card>
        </v-col>

        <v-col cols="2">
          <stat-card
            title="GST"
            color="green accent-1"
            :amount="gst"
            icon="mdi-office-building"
            sub_title=""
          ></stat-card>
        </v-col>

        <v-col cols="2">
          <stat-card
            title="Total"
            color="green accent-1"
            :amount="total"
            icon="group"
            sub_title=""
          ></stat-card>
        </v-col>
      </v-row>

      <v-data-table
        dense
        :headers="headers"
        :items="clientSummary"
        :loading="loadingClientSummary"
        class="ma-5"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, -1],
        }"
      >
        <template v-slot:item="props">
          <tr class="pa-0">
            <td class="body-2">{{ props.item.DepotName }}</td>
            <td class="body-2">{{ props.item.Name }}</td>
            <td align="right" class="body-2">{{ props.item.Jobs }}</td>
            <td align="right" class="body-2">{{ props.item.Misc }}</td>
            <td align="right" class="body-2">
              $ {{ formatSum(props.item.SubTotal) }}
            </td>
            <td align="right" class="body-2">
              $ {{ formatSum(props.item.Toll) }}
            </td>
            <td align="right" class="body-2">
              $ {{ formatSum(props.item.FuelLevy) }}
            </td>
            <td align="right" class="body-2">
              $ {{ formatSum(props.item.GST) }}
            </td>
            <td align="right" class="body-2">
              $ {{ formatSum(props.item.Total) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import StatCard from "./StatCard.vue";

export default {
  components: {
    StatCard,
  },
  data: () => ({
    clientSummary: [],
    loadingClientSummary: false,
    errors: [],
    headers: [
      {
        value: "DepotName",
        align: "left",
        sortable: true,
        text: "DepotName",
      },
      {
        value: "Name",
        align: "left",
        sortable: true,
        text: "Client Name",
      },
      {
        value: "Jobs",
        align: "right",
        sortable: true,
        text: "No of Jobs",
      },
      {
        value: "Misc",
        align: "right",
        sortable: true,
        text: "No of Misc",
      },
      {
        value: "SubTotal",
        align: "right",
        sortable: true,
        text: "Subtotal",
      },
      {
        value: "Toll",
        align: "right",
        sortable: true,
        text: "Toll",
      },
      {
        value: "FuelLevy",
        align: "right",
        sortable: true,
        text: "Fuel Levy",
      },
      {
        value: "GST",
        align: "right",
        sortable: true,
        text: "GST",
      },
      {
        value: "Total",
        align: "right",
        sortable: true,
        text: "Total",
      },
    ],
  }),
  props: ["startDate", "endDate"],

  watch: {
    startDate() {
      this.loadClientSummary();
    },
    endDate() {
      this.loadClientSummary();
    },
  },
  created() {
    this.loadClientSummary();
  },

  computed: {
    jobCount() {
      let jobCount = 0;
      this.clientSummary.forEach((client) => {
        jobCount += client.Jobs;
      });
      return this.formatSum(jobCount, "", "0");
    },
    miscCount() {
      let miscCount = 0;
      this.clientSummary.forEach((client) => {
        miscCount += client.Misc;
      });
      return this.formatSum(miscCount, "", 0);
    },
    toll() {
      let toll = 0;
      this.clientSummary.forEach((client) => {
        toll += client.Toll;
      });
      return this.formatSum(toll, "$");
    },
    fuelLevy() {
      let fuelLevy = 0;
      this.clientSummary.forEach((client) => {
        fuelLevy += client.FuelLevy;
      });
      return this.formatSum(fuelLevy, "$");
    },
    gst() {
      let gst = 0;
      this.clientSummary.forEach((client) => {
        gst += client.GST;
      });
      return this.formatSum(gst, "$");
    },
    total() {
      let total = 0;
      this.clientSummary.forEach((client) => {
        total += client.Total;
      });
      return this.formatSum(total, "$");
    },
  },

  methods: {
    loadClientSummary() {
      this.loadingClientSummary = true;
      axios
        .get(
          `/summary/clientsummary?startDate=${this.startDate}&endDate=${this.endDate}`
        )
        .then((response) => {
          this.clientSummary = response.data;
          this.loadingClientSummary = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loadingClientSummary = false;
        });
    },
    formatSum(sum, prefix = "", decimal = 2) {
      sum = sum.toFixed(decimal);
      return (
        prefix + " " + sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
  },
};
</script>

<style></style>
