<template>
  <div>
  <client-planner/>
  </div>
</template>

<script>
import axios from "axios";
import ClientPlanner from "./Planner.vue";

export default {

  components: {
    "client-planner": ClientPlanner
  },

  data() {
    return {
      client: {},
    }
  },

  mounted() {
    this.loadClient();
  },
  methods: {
    loadClient() {
      axios
        .get("/client_routes/client")
        .then((response) => {
          this.client = response.data[0];
        })
        .catch((e) => {
          console.log("Error loading client " + e)
        });
    },
  },

  computed: {
    DepotName() {
        return !this.$store.getters.user
          ? false
          : this.$store.getters.user.DepotName;
      },
  }
};
</script>

<style></style>
