<template>
  <v-container id="contractor-leave" grid-list-md fluid>
    <v-flex xs12 sm12 md12>
      <v-card>
        <v-card-title class="headline" title--text> Submit Leave </v-card-title>
        <v-card-text>
          <v-flex xs12 sm12 md12>
            <v-layout raw wrap align-center justify-center>
              <v-flex xs12 sm6 md3>
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startDateFormatted"
                      label="From"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    @input="startDateMenu = false"
                    :allowed-dates="formFromAllowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md3>
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDateFormatted"
                      label="To"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    no-title
                    @input="endDateMenu = false"
                    :allowed-dates="formToAllowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-btn @click="submitLeave" color="success">Submit</v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex class="pt-1" flex-column xs12 sm12 md12>
      <!--show applied leave-->
      <v-card>
        <v-card-title class="headline" title--text>Booked Leave</v-card-title>
        <v-card-text>
          <v-flex xs12 sm12 md12>
            <v-layout raw wrap align-center justify-center>
              <v-flex xs12 sm6 md3>
                <v-menu
                  v-model="appliedstartDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="appliedstartDateFormatted"
                      label="From"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="appliedstartDate"
                    no-title
                    @input="appliedstartDateMenu = false"
                    :allowed-dates="fromAllowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md3>
                <v-menu
                  v-model="appliedendDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="appliedendDateFormatted"
                      label="To"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="appliedendDate"
                    no-title
                    @input="appliedendDateMenu = false"
                    :allowed-dates="toAllowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-data-table
            :items="leaveList"
            :headers="LeaveHeaders"
            :items-per-page="10"
          >
            <template v-slot:item="props">
              <tr :key="props.index">
                <td class="body-2">{{ props.item.formattedStartDate }}</td>
                <td class="body-2">{{ props.item.formattedEndDate }}</td>
                <td class="body-2">
                  <v-icon color="green" v-if="props.item.approved">done_all</v-icon>
                  <v-icon color="orange" v-else>timer</v-icon>
                </td>
                <td>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="deleteLeave(props.item.leaveID)"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        v-on="on"
                      >
                        <v-icon dark>delete_forever</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete: {{ props.item.leaveID }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info"
                >No Leave Found</v-alert
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      contractor: {},

      //date picker
      endDateDT: new Date(new Date().setDate(new Date().getDate() + 14)),
      startDateDT: new Date(),
      startDate: "",
      endDate: "",
      startDateFormatted: "",
      endDateFormatted: "",
      startDateMenu: false,
      endDateMenu: false,
      //show applied leave
      appliedendDateDT: new Date(new Date().setDate(new Date().getDate() + 364)),
      appliedstartDateDT: new Date(
        new Date().setDate(new Date().getDate() - 60)
      ),
      appliedstartDate: "",
      appliedendDate: "",
      appliedstartDateFormatted: "",
      appliedendDateFormatted: "",
      appliedstartDateMenu: false,
      appliedendDateMenu: false,
      leaveList: [],

      //list of applied leave
      LeaveHeaders: [
        {
          value: "formattedStartDate",
          align: "left",
          sortable: false,
          text: "Start Date",
        },
        {
          value: "formattedEndDate",
          align: "left",
          sortable: false,
          text: "End Date",
        },
        {
          value: "approved",
          align: "left",
          sortable: false,
          text: "Approved",
        },
        {
          value: "delete",
          align: "left",
          sortable: false,
          text: "Delete",
        },
      ],
      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },
    };
  },
  components: {
    //"contractor-payslips": ContractorPayslips,
  },
  create() {
    this.startDate = this.parseDate(this.startDateDT);
    this.endDate = this.parseDate(this.endDateDT);
    this.startDateFormatted = this.formatDate(this.startDate);
    this.endDateFormatted = this.formatDate(this.endDate);
  },
  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },
    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
    appliedstartDate(val) {
      this.appliedstartDateFormatted = this.formatDate(this.appliedstartDate);
      //load applied leave
      this.loadLeave();
    },
    appliedendDate(val) {
      this.appliedendDateFormatted = this.formatDate(this.appliedendDate);
      //load applied leave
      this.loadLeave();
    },
  },

  mounted() {
    this.loadContractor();
    this.startDate = this.parseDate(this.startDateDT);
    this.endDate = this.parseDate(this.endDateDT);
    this.startDateFormatted = this.formatDate(this.startDate);
    this.endDateFormatted = this.formatDate(this.endDate);
    //applied leave date
    this.appliedstartDate = this.parseDate(this.appliedstartDateDT);
    this.appliedendDate = this.parseDate(this.appliedendDateDT);
    this.appliedstartDateFormatted = this.formatDate(this.appliedstartDate);
    this.appliedendDateFormatted = this.formatDate(this.appliedendDate);
    this.loadLeave();
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      date = date.toLocaleDateString("en-AU");
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    loadContractor() {
      axios
        .get("/contractor_routes/contractor")
        .then((response) => {
          this.contractor = response.data[0];
          // SQL name is different to variable name :(
          this.contractor.Licence = response.data[0].License;
          //console.log(this.contractor);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    //get leave
    loadLeave() {
      this.leaveList = [];
      //load applied leave from database
      axios
        .get(
          "/contractor_routes/leave?startDate=" +
            this.appliedstartDate +
            "&endDate=" +
            this.appliedendDate
        )
        .then((response) => {
          this.leaveList = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //submit leave
    submitLeave() {
      //console.log("come to submit leave/add leave");
      axios
        .post(
          "/contractor_routes/leave/addLeave?startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate
        )
        .then((response) => {
          if (response.status === 200) {
            //console.log(response.data);
            this.loadLeave();
            this.snackbar.color = "success";
            this.snackbar.text = "Leave added";
            this.snackbar.visible = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //delete selected leave
    deleteLeave(leaveID) {
      console.log(leaveID);
      axios
        .post("/contractor_routes/leave/deleteLeave?leaveID=" + leaveID)
        .then((response) => {
          if (response.status === 200) {
            this.loadLeave();
            this.snackbar.color = "success";
            this.snackbar.text = "Leave deleted";
            this.snackbar.visible = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    formFromAllowedDates(date) {
      return date <= this.endDate;
    },
    formToAllowedDates(date) {
      return date >= this.startDate;
    },
    fromAllowedDates(date) {
      return date <= this.appliedendDate;
    },
    toAllowedDates(date) {
      return date >= this.appliedstartDate;
    },
  },
};
</script>

<style></style>
