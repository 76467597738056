<template>
  <v-container id="contractor" grid-list-md fluid>
    <v-layout row wrap>
      <v-flex d-flex align-start sm12 md6>
        <!-- Contractor Form -->
        <contractor-form :contractorID="contractorID"></contractor-form>
      </v-flex>
      <v-flex d-flex align-start sm12 md6>
        <!-- vehicles table also dynamically loads rates table -->
        <vehicles :contractorID="contractorID"></vehicles>
        <!-- </v-layout> -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Form from "./Form.vue";
import Vehicles from "./Vehicles.vue";

export default {
  components: {
    "contractor-form": Form,
    vehicles: Vehicles,
  },

  data() {
    return {
      contractorID: "",
      errors: [],
    };
  },

  created() {
    this.contractorID = this.$route.params.id;
  },
};
</script>
