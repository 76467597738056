<template>
  <v-layout>
    <v-dialog v-model="userDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-flex xs12 sm5 md5>
            <v-switch
              v-if="this.editedUserIndex > -1"
              v-model="resetPassword"
              label="Reset Password"
              inset
            ></v-switch>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <v-form ref="user">
            <v-container grid-list-md>
              <v-layout wrap justify-space-around>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Name"
                    v-model="editedUser.name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-text-field
                    label="Username"
                    v-model="editedUser.username"
                    :readonly="this.editedUserIndex > -1"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-select
                    label="Role"
                    v-model="editedUser.role"
                    :items="roles"
                    :rules="[rules.required]"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex v-if="resetPassword" xs12 sm5 md5>
                  <v-text-field
                    label="Password"
                    v-model="editedUser.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.password]"
                    :type="showPassword ? 'text' : 'password'"
                    counter
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout v-if="resetPassword">
                <v-flex>
                  Password Conditions
                  <ul>
                    <li>Mininum 8 characters</li>
                    <li>At least one capital letter</li>
                    <li>At least one number</li>
                    <li>At least one special character [!@#$%^&*]</li>
                  </ul>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="userDialog = false">
            Cancel
            <v-icon right dark>cancel</v-icon>
          </v-btn>
          <v-btn class="success" text @click="saveUser">
            Save
            <v-icon right dark>save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userDeleteDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text
          >Confirm deletion of {{ userTobeDeleted.name }} ({{
            userTobeDeleted.username
          }})</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="success" text @click="userDeleteDialog = false">
            Cancel
            <v-icon right dark>cancel</v-icon>
          </v-btn>
          <v-btn class="error" text @click="deleteUser">
            Delete
            <v-icon right dark>delete_forever</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-flex sm12 md12>
      <v-card>
        <v-card-title class="headline" title--text>
          User Accounts
          <v-spacer></v-spacer>
          <v-btn fab dark small color="indigo" @click="addUser">
            <v-icon dark>add</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table v-if="users[0]" :headers="userHeaders" :items="users">
            <template v-slot:item="props">
              <tr>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.username }}</td>
                <td>{{ props.item.role }}</td>
                <td>
                  <v-icon class="mr-2" @click="editUser(props.item)"
                    >edit</v-icon
                  >
                  <v-icon @click="prepareUserDelete(props.item)">delete</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning"
                >No users found</v-alert
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-layout>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      userHeaders: [
        {
          value: "name",
          align: "start",
          text: "Name",
        },

        {
          value: "username",
          align: "start",
          sortable: true,
          text: "Username",
        },
        {
          value: "role",
          align: "start",
          sortable: true,
          text: "Role",
        },
        {
          //value: "name",
          align: "left",
          sortable: false,
          text: "Action",
        },
      ],
      users: [],
      userDialog: false,
      roles: ["admin", "user"],
      editedUserIndex: -1,
      showPassword: false,
      resetPassword: false,
      name: "",
      editedUser: {
        name: "",
        username: "",
        role: "",
        password: "",
      },

      defaultUser: {
        name: "",
        username: "",
        role: "",
        password: "",
      },
      userTobeDeleted: {},
      userDeleteDialog: false,
      rules: {
        required: (value) => !!value || "Required.",
        password: (value) => {
          const pattern =
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
          return pattern.test(value) || "Weak password";
        },
      },

      snackbar: {
        visible: false,
        color: "success",
        timeout: 3000,
        text: "",
      },

      errors: [],
    };
  },

  watch: {
    userDialog(newVal, oldVal) {
      if (this.editedUserIndex === -1 && newVal === true) {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedUserIndex = -1;
        if (this.$refs.user) {
          this.$refs.user.resetValidation();
        }
      }
    },
  },

  computed: {
    formTitle() {
      return this.editedUserIndex === -1 ? "Add User" : "Edit User";
    },
    noOfAdmins() {
      let admins = 0;
      this.users.forEach((user) => {
        if (user.role === "admin") admins++;
      });
      return admins;
    },
  },
  created() {
    this.loadUsers();
  },

  methods: {
    loadUsers: function () {
      //Get Users
      axios
        .get("/user")
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    addUser() {
      this.editedUserIndex = -1;
      this.resetPassword = true;
      this.userDialog = true;
    },
    editUser(user) {
      this.editedUserIndex = this.users.indexOf(user);
      this.editedUser = Object.assign({}, user);
      this.resetPassword = false;
      this.userDialog = true;
    },
    saveUser() {
      if (this.$refs.user.validate()) {
        if (this.editedUserIndex > -1) {
          axios
            .put("/signup/", this.editedUser)
            .then((res) => {
              if (res.status === 201) {
                this.snackbar.color = "success";
                this.snackbar.text =
                  "User " + this.editedUser.username + " updated";
                this.snackbar.visible = true;
                this.loadUsers();
                this.editedUser = Object.assign({}, this.defaultUser);
                this.closeUser();
              }
            })
            .catch((e) => {
              this.errors.push(e);
              this.snackbar.color = "error";
              this.snackbar.text = this.errors.pop();
              this.snackbar.visible = true;
            });
        } else {
          axios
            .post("/signup/", this.editedUser)
            .then((res) => {
              if (res.status === 201) {
                this.snackbar.color = "success";
                this.snackbar.text =
                  "User " + this.editedUser.username + " added";
                this.snackbar.visible = true;
                this.loadUsers();
                this.closeUser();
              }
            })
            .catch((e) => {
              this.errors.push(e);
              this.snackbar.color = "error";
              this.snackbar.text = this.errors.pop();
              this.snackbar.visible = true;
            });
        }
      }
    },
    closeUser() {
      this.userDialog = false;
    },
    prepareUserDelete(user) {
      this.userTobeDeleted = user;
      if (this.noOfAdmins < 2 && this.userTobeDeleted.role === "admin") {
        this.snackbar.color = "error";
        this.snackbar.text = "Last admin cannot be deleted";
        this.snackbar.visible = true;
      } else {
        this.userDeleteDialog = true;
      }
    },
    deleteUser() {
      console.log(this.userTobeDeleted.username);
      axios
        .delete("/user/" + this.userTobeDeleted.username, this.userToBeDeleted)
        .then((res) => {
          if (res.status === 201) {
            this.snackbar.color = "success";
            this.snackbar.text =
              "User " + this.userTobeDeleted.username + " deleted";
            this.snackbar.visible = true;
            this.loadUsers();
            this.userDeleteDialog = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.snackbar.color = "error";
          this.snackbar.text = this.errors.pop();
          this.snackbar.visible = true;
          this.userDeleteDialog = false;
        });
    },
  },
};
</script>

<style></style>
*/
