<template>
  <v-layout>
    <v-dialog v-model="rateDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline"> {{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="rate">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 md4>
                  <v-text-field
                    label="Rate Description"
                    v-model="editedRate.rate_description"
                    :rules="[rules.notDuplicateRate]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Vehicle Type"
                    v-model="editedRate.vehicle_type"
                    suffix="TV"
                    :rules="[
                      rules.required,
                      rules.vehicleType,
                      rules.notDuplicateRate,
                    ]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md2>
                  <v-text-field
                    label="Rate"
                    prefix="$"
                    @change="formatRate"
                    v-model="editedRate.rate"
                    :rules="[rules.required, rules.vehicleRate]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-select
                    outlined
                    label="Rate Type"
                    :items="rateTypes"
                    v-model="editedRate.rate_type"
                  >
                  </v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeRate">
            Cancel
            <v-icon right dark>cancel</v-icon>
          </v-btn>
          <v-btn class="success" text @click="saveRate">
            Save
            <v-icon right dark>save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="rateDeleteDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text
          >Confirm deletion of VT: {{ rateToBeDeleted.vehicle_type }} rate: $
          {{ rateToBeDeleted.rate }}
          Description: {{ rateToBeDeleted.rate_description }} from
          {{ depotName }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="success" text @click="rateDeleteDialog = false">
            Cancel
            <v-icon right dark>cancel</v-icon>
          </v-btn>
          <v-btn class="error" text @click="deleteRate">
            Delete
            <v-icon right dark>delete_forever</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-flex xs12 sm12 md12>
      <v-card>
        <v-card-title class="headline" title--text>
          {{ depotName }} Rates
          <v-spacer></v-spacer>
          <v-btn fab dark small color="indigo" @click="newRate">
            <v-icon dark>add</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="rateHeaders" :items="rates">
            <template v-slot:item="props">
              <tr>
                <td align="right">{{ props.item.rate_description }}</td>
                <td align="right">{{ props.item.vehicle_type }} TV</td>
                <td align="right">
                  {{
                    rateTypes.find((el) => el.value === props.item.rate_type)
                      .text
                  }}
                </td>
                <td align="right">$ {{ props.item.rate.toFixed(2) }}</td>
                <td>
                  <v-icon class="mr-2" @click="editRate(props.item)"
                    >edit</v-icon
                  >
                  <v-icon @click="confirmDeleteRate(props.item)">delete</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning"
                >No rates found for Client: {{ $route.params.id }}</v-alert
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";

export default {
  props: {
    depotName: String,
  },
  data() {
    return {
      ratesPagination: {
        rowsPerPage: 10,
        sortBy: "DepotName",
      },
      rates: [],
      rate: {},
      selectedRate: {},
      addRateDialog: false,
      rateDialog: false,
      rateDeleteDialog: false,
      editedRateIndex: -1,
      rateToBeDeleted: {},
      editedRate: {
        depotName: this.depotName,
        rate_description: "",
        rate: 0.0,
        vehicle_type: 1,
        rate_type: "hourly",
      },

      defaultRate: {
        depotName: this.depotName,
        rate_description: "",
        rate: 0.0,
        vehicle_type: 1,
        rate_type: "hourly",
      },
      rateTypes: [
        { text: "Hourly Rate", value: "hourly" },
        { text: "Flat Rate", value: "flat" },
        { text: "Piece Rate", value: "piece" },
        { text: "Stop Rate", value: "stop" },
      ],
      rateHeaders: [
        {
          value: "rate_description",
          align: "end",
          sortable: false,
          text: "Rate Description",
        },
        {
          value: "vehicle_type",
          align: "end",
          sortable: true,
          text: "Vehicle Type",
        },
        {
          value: "flat_rate",
          align: "end",
          sortable: true,
          text: "Rate Type",
        },
        {
          value: "rate",
          sortable: false,
          align: "end",
          text: "Rate",
        },

        {
          align: "start",
          sortable: false,
          text: "Action",
        },
      ],
      errors: [],
      rules: {
        required: (value) => !!value || "Required",
        vehicleType: (value) => {
          const pattern = /^([0-9]*)$/;
          return pattern.test(value) || "Vehicle type is in whole number";
        },
        vehicleRate: (value) => {
          const pattern = /^([0-9]*\.?[0-9]*)$/;
          return pattern.test(value) || "Rate is decimal number only";
        },
        notDuplicateRate: (value) => {
          return this.duplicateRate() !== true || "Rate already exists";
        },
      },
    };
  },
  created() {
    if (this.depotName) {
      this.loadRates();
    }
  },

  computed: {
    formTitle() {
      return this.editedRateIndex === -1
        ? "Add new Rate for " + this.depotName
        : "Edit new Rate of " + this.depotName;
    },
  },

  watch: {
    rateDialog(newVal, oldVal) {
      if (this.editedRateIndex === -1 && newVal === true) {
        this.editedRate = Object.assign({}, this.defaultRate);
        this.editedRateIndex = -1;
        this.formatRate();
        if (this.$refs.rate) {
          this.$refs.rate.resetValidation();
        }
      }
    },
  },

  methods: {
    loadRates() {
      axios
        .get(`/client/${this.depotName}/rates`)
        .then((response) => {
          if (response.data != "No rates found") this.rates = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    newRate() {
      this.editedRateIndex = -1;
      this.rateDialog = true;
    },
    closeRate() {
      this.rateDialog = false;
      this.addRateDialog = false;
      this.rate = this.selectedRate;
    },

    formatRate() {
      const rate_str = parseFloat(this.editedRate.rate).toFixed(2);
      if (rate_str != "NaN") {
        this.editedRate.rate = rate_str;
      } else {
        this.editedRate.rate = "0.00";
      }
    },

    duplicateRate() {
      let foundDuplicateRate = false;
      this.rates.forEach((rate) => {
        if (
          rate.rate_description === this.editedRate.rate_description &&
          rate.vehicle_type == this.editedRate.vehicle_type
        ) {
          if (rate.rate_id && rate.rate_id !== this.editedRate.rate_id) {
            foundDuplicateRate = true;
          }
        }
      });
      return foundDuplicateRate;
    },
    saveRate() {
      if (this.$refs.rate.validate()) {
        var payload = { data: this.editedRate };
        payload.data.depotName = this.depotName;
        if (this.editedRateIndex > -1) {
          axios
            .post(`/client/updateRate`, payload)
            .then((res) => {
              if (res.status === 200) {
                this.loadRates();
                this.rateDialog = false;
              }
            })
            .catch((e) => {
              this.errors.push(e);
              this.snackbar.color = "error";
              this.snackbar.text = this.errors.pop();
              this.snackbar.visible = true;
            });
        } else {
          console.log(payload);
          axios
            .post(`/client/addRate`, payload)
            .then((res) => {
              if (res.status === 200) {
                this.rate.rate_id = res.insertId;
                this.loadRates();
                this.rateDialog = false;
              }
            })
            .catch((e) => {
              this.errors.push(e);
              this.snackbar.color = "error";
              this.snackbar.text = this.errors.pop();
              this.snackbar.visible = true;
            });
        }
      }
    },
    confirmDeleteRate(item) {
      this.rateDeleteDialog = true;
      this.rateToBeDeleted = item;
    },

    deleteRate() {
      this.rateDeleteDialog = false;
      var payload = { data: this.rateToBeDeleted.rate_id };
      axios
        .post(`/client/deleteRate`, payload)
        .then((res) => {
          if (res.status === 200) {
            this.loadRates();
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.snackbar.color = "error";
          this.snackbar.text = this.errors.pop();
          this.snackbar.visible = true;
        });
    },

    editRate(editingRate) {
      this.editedRateIndex = this.rates.indexOf(editingRate);
      this.editedRate = Object.assign({}, editingRate);
      this.formatRate();
      this.rateDialog = true;
    },
  },
};
</script>
