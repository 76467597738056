<template>
  <v-card class="mb-3" color="cyan darken-2" elevation="10">
    <v-sheet width="100%" class="pa-5" color="cyan darken-2" elevation="10">
      <p class="title text-center white--text">
        Client and Contractor Totals in last {{ numberOfDays }} days
      </p>
      <line-chart
        :height="80"
        v-if="dataReady"
        :data="datacollection"
        :options="options"
      ></line-chart>
    </v-sheet>
  </v-card>
</template>

<script>
import axios from "axios";
import LineChart from "./LineChart.js";

export default {
  components: {
    LineChart,
  },
  props: ["startDate", "endDate"],
  watch: {
    startDate() {
      this.fillData();
    },
    endDate() {
      this.fillData();
    },
  },

  data() {
    return {
      datacollection: null,
      options: null,
      dataReady: false,
    };
  },
  mounted() {
    this.fillData();
  },

  created() {
    if (!this.today) {
      this.today = new Date();
    }
  },

  computed: {
    days: function () {
      let days = [];
      let i = 0;
      let date = new Date(this.endDate);
      for (i = 0; i < this.numberOfDays; i++) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        days.unshift(this.pad(day, 2) + "/" + this.pad(month, 2));
        date.setDate(date.getDate() - 1);
      }
      return days;
    },
    numberOfDays: function () {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      return (
        Math.round(
          Math.abs((new Date(this.endDate) - new Date(this.startDate)) / oneDay)
        ) + 1
      );
    },
  },

  methods: {
    fillData() {
      this.dataReady = false;
      (this.datacollection = {
        labels: this.days,
        datasets: [
          {
            label: "Contractor",
            borderColor: "#18FFFF",
            borderWidth: 1,
            fill: false,
            backgroundColor: "#18FFFF",
            data: [],
          },
          {
            label: "Client",
            borderColor: "#F4FF81",
            borderWidth: 1,
            fill: false,
            backgroundColor: "#F4FF81",
            data: [],
          },
        ],
      }),
        (this.options = {
          elements: {
            line: {
              tension: 0.4,
            },
          },
          tooltips: {
            titleFontSize: 16,
            bodyFontSize: 18,
            callbacks: {
              label: function (tooltipItems, data) {
                let total =
                  data.datasets[tooltipItems.datasetIndex].data[
                    tooltipItems.index
                  ];
                total = total.toFixed(2);
                total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                let label = data.datasets[tooltipItems.datasetIndex].label;
                console.log(tooltipItems);
                return label + ": $" + total;
              },
            },
          },
          plugins: {
            legend: {
              display: true,
              position: "left",
              labels: { boxWidth: 20, color: "white" },
            },
          },
          scales: {
            y: {
              stacked: false,
              ticks: {
                color: "white",
              },
              grid: {
                color: "#26C6DA",
                zeroLineColor: "#26C6DA",
              },
            },

            x: {
              ticks: {
                color: "white",
              },
              grid: {
                color: "#26C6DA",
                zeroLineColor: "#26C6DA",
              },
            },
          },
        });
      axios
        .get(
          `/summary/client?startDate=${this.startDate}&endDate=${this.endDate}`
        )
        .then((response) => {
          this.datacollection.labels.forEach((day) => {
            let total = 0;
            response.data.forEach((record) => {
              if (record.Start_Date === day) {
                total = record.total;
              }
            });
            this.datacollection.datasets[1].data.push(total);
          });

          axios
            .get(
              `/summary/contractor?startDate=${this.startDate}&endDate=${this.endDate}`
            )
            .then((response) => {
              this.datacollection.labels.forEach((day) => {
                let total = 0;
                response.data.forEach((record) => {
                  if (record.Start_Date === day) {
                    total = record.total;
                  }
                });
                this.datacollection.datasets[0].data.push(total);
              });

              this.dataReady = true;
            })
            .catch((e) => {
              this.errors.push(e);
            });
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    pad(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
    resetData() {
      this.datacollection = null;
    },
  },
};
</script>

<style scoped></style>
